<template>
  <section>
    <h1 class="large-title-text my-5">{{ $t('wallet.balance') }}</h1>
    <template v-if="isInfluencer">
      <v-alert v-if="hasEasterPendingWithdraw" dismissible prominent type="warning" dense>
        {{
          $t('withdraws.state.holiday', {
            startDate: $date(startDate),
            endDate: $date(endDate)
          })
        }}
      </v-alert>
      <v-alert dense dismissible type="info">
        {{ $t('withdraws.withdraw_info', {amount: getMinAmount}) }}
      </v-alert>
      <v-alert dense dismissible type="info" v-if="showInfoPayplume">
        {{ $t('alerts.requiredPayPlume.text') }}
        <router-link class="cursor-pointer no-decoration text-white font-semibold ml-1"
                     :to="{ name: 'settings', query: { tab: 'service' } }">
          {{ $t('alerts.requiredPayPlume.action') }}
        </router-link>
      </v-alert>

      <v-alert
        v-if="!currentUser.signedDocument"
        dense
        dismissible
        type="error"
        color="orange lighten-2"
      >
        {{ $t('withdraws.error_sign_contract') }}
        <router-link
          class="pointer no-decoration ml-1"
          to="/contract"
        >
          {{ $t('actions.sign') }}
        </router-link>
      </v-alert>

      <div v-for="(item, index) in withdraw" :key="index">
        <v-alert
          dense
          dismissible
          type="error"
          color="red"
          v-model="rejectNotified"
          v-if="item.status === 'rejected'">
          {{
            $t('withdraws.state.rejected', {
              reason: item.details,
              amount: $currency(item.amount, item.currency)
            })
          }}
          <template #close>
            <v-btn icon @click="updatedRejectNotified(item.id, item.rejectNotified)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" height="26px" width="26px">
                <path
                  d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/>
              </svg>
            </v-btn>
          </template>
        </v-alert>
        <span v-if="item.status === 'pending'">
           <v-alert dismissible dense type="info" v-if="isNewPayout">
          {{
               $t('withdraws.state.pending', {
                 amount: $currency(item.amount, item.currency),
                 period: getDaysPayout(item.payoutDate)
               })
             }}
        </v-alert>
        <v-alert dismissible dense type="info" v-else>
          {{
            $t('withdraws.state.pending_old', {
              amount: $currency(item.amount, item.currency)
            })
          }}
        </v-alert>
        </span>
      </div>
      <v-alert
        v-if="mustCompleteData"
        dense
        dismissible
        type="error"
        color="orange lighten-2"
      >
        {{ $t(`withdraws.no_config`) }}
        <router-link
          class="pointer no-decoration ml-1"
          :to="{ name: 'settings', query: { tab: 'service' } }"
        >
          {{ $t('withdraws.go_to_config') }}
        </router-link>
      </v-alert>
    </template>
    <WalletInfo/>
    <router-view/>
  </section>
</template>
<script>
import {mapState} from 'vuex'
import {ProfileActions, WithdrawActions} from '@/store'
import WalletInfo from '@/components/wallet/WalletInfo'
import WalletEventList from '@/components/wallet/WalletEventList'
import PaymentButton from '@/components/payments/PaymentButton'
import WithdrawApi from '@/api/WithdrawApi'
import dateInput from '@/components/custom/DateInput.vue'
import Btn from "@/design/btn/Btn.vue";

const USACountry = ['US', 'USA']

export default {
  name: 'WalletView',
  components: {
    Btn,
    WalletInfo,
    WalletEventList,
    PaymentButton
  },
  data() {
    return {
      startDate: '2022-04-15',
      endDate: '2022-04-18',
      rejectNotified: true
    }
  },
  computed: {
    ...mapState('withdraws', ['payplume','withdrawData', 'withdrawMethods']),
    ...mapState('preferences', ['darkTheme']),
    ...mapState('profile', [
      'currentUser',
      'isInfluencer',
      'eurWallet',
      'usdWallet',
      'withdraw',
      'payout'
    ]),

    mustCompleteData() {
      return !this.withdrawData.isCompleted || !this.withdrawMethods.length
    },
    isNewPayout() {
      return this.payout.newPayout
    },
    getMinAmount() {
      return this.withdrawData.country === 'CO' ? 20 : 100
    },
    hasPendingWithdraw() {
      return !!this.eurWallet.blockedAmount || !!this.usdWallet.blockedAmount
    },
    hasEasterPendingWithdraw() {
      return this.hasPendingWithdraw && this.isEasterHolidays
    },

    isEasterHolidays() {
      const today = new Date()
      const start = new Date(2022, 3, 12)
      const end = new Date(2022, 3, 18)
      return (
        start.getTime() < today.getTime() && today.getTime() < end.getTime()
      )
    },

    isPayplumeConnected() {
      return this.currentUser.withdrawConfig && this.currentUser.withdrawConfig.payplume.configured
    },
    isInUSA() {
      const method = this.withdrawMethods.length && this.withdrawMethods[0]
      if (!this.withdrawData || !method) return false
      if (method.type !== 'bank') return false
      if (USACountry.includes(this.withdrawData.country)) return true
      if (USACountry.includes(method.info.bankCountry)) return true
      return false
    },
    showInfoPayplume() {
      return  this.isInfluencer && this.isInUSA
    }
  },
  methods: {
    getDaysPayout(date) {
      const dateIn = new Date(date)
      const dayIn = dateIn.getDate()
      const months = Object.values(this.$t('month'))
      const monthIn = months[dateIn.getMonth()]
      if (dayIn === 1) {
        if (this.$i18n.locale === 'es') return `${dayIn} de ${monthIn}`
        return `${dayIn} ${monthIn}.`
      }
      const dayBefore = dayIn - 1
      if (this.$i18n.locale === 'es') return `periodo del ${dayBefore} y ${dayIn} de ${monthIn}`
      return `${dayBefore} - ${dayIn} ${monthIn}.`
    },
    async updatedRejectNotified(id) {
      const config = {
        withdrawId: id,
        rejectNotified: true
      }
      const {
        error
      } = await WithdrawApi.updateRejectNotified(config)
      if (!error) {
        this.$store.dispatch(ProfileActions.Fetch)
      }
    }
  },
  beforeMount() {
    this.$store.dispatch(ProfileActions.Fetch)
    this.$store.dispatch(WithdrawActions.FetchMethods)
    this.$store.dispatch(WithdrawActions.FetchData)
  }
}
</script>
