<template>
  <v-form ref="form" v-model="validForm">
    <p class="mb-2 text-md font-bold">{{ $t('withdraws.withdraw_methods.types.payplume_ach') }}</p>
    <p class="mb-2">{{ $t('withdraws.withdraw_methods.bank.form_description') }}</p>
    <span >
      <v-text-field outlined
                    :label="$t('models.withdraw_bank_info.bank_name')"
                    type="text"
                    v-model="bankName"
                    :rules="[$vRequired]"/>
      <v-text-field outlined
                    :label="accountLabel"
                    type="text"
                    v-model="bankAccount"
                    :rules="[$vRequired]"/>
      <v-text-field v-if="isFromUSA"
                    outlined
                    :label="$t('models.withdraw_bank_info.routing_number')"
                    type="text"
                    v-model="bankRoutingNumber"/>
      <v-text-field v-if="isFromCA"
                    outlined
                    :label="$t('models.withdraw_bank_info.transit_number')"
                    type="text"
                    v-model="swiftAccount"/>
      <v-text-field outlined v-if="isFromCA"
                    :label="$t('models.withdraw_bank_info.institution_code')"
                    type="text"
                    v-model="bankRoutingNumber"
                    :rules="[$vRequired]"/>
      <v-text-field outlined v-else
                    :label="$t('models.withdraw_bank_info.swift_number')"
                    type="text"
                    v-model="swiftAccount"
                    :rules="[$vRequired]"/>
      <v-text-field outlined
                    :label="$t('withdraws.withdraw_methods.payplume_ach.form.label')"
                    type="text"
                    v-model="accountId"
                    />
    </span>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'
import validatable from '@/components/mixins/validatable'

export default {
  name: 'BankMethodForm',
  mixins: [validatable('form')],
  props: {
    method: {
      type: Object,
      default: null
    },
    dark: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
      validForm: false,
      bankName: null,
      bankCurrency: 'USD',
      bankCountry: 'USA',
      bankAccount: null,
      swiftAccount: null,
      bankRoutingNumber: null,
      accountId:null
    }
  },
  computed: {
    ...mapState('withdraws', ['withdrawData']),
    countries () {
      return this.$t('countries_v2')
    },
    countryCurrencies () {
      return this.bankCountry ? this.bankCountry.currencies : []
    },
    liveCountry () {
      return this.countries.find(c => c.value === this.withdrawData.country)
    },
    isFromUSA () {
      return this.bankCountry && this.bankCountry.value === 'US'
    },
    isFromCA () {
      return this.bankCountry && this.bankCountry.value === 'CA'
    },
    methodAttrs () {
      return {
        type: 'payplume_ach',
        accountId: this.accountId,
        withdrawMethodPath: '',
        withdrawMethodApproved: true,
        bankInfo: {
          bankName: this.bankName,
          bankCountry: this.bankCountry ? this.bankCountry.value : null,
          bankCurrency: this.bankCurrency,
          bankAccount: this.bankAccount,
          bankRoutingNumber: this.bankRoutingNumber || null,
          swiftAccount: this.swiftAccount
        }
      }
    },
    accountLabel () {
      return this.isEurope ? this.$t('models.withdraw_bank_info.iban_number') : this.$t('models.withdraw_bank_info.bank_account')
    }
  },
  watch: {
    methodAttrs () {
      this.$emit('methodAttrs', this.methodAttrs)
    },
    validForm () {
      this.$emit('validForm', this.validForm)
    }
  },

  mounted () {
    if (!this.method) {
      return
    }
    this.bankName = this.method.info.bankName
    this.bankCountry = this.countries.find(c => c.value === this.method.info.bankCountry)
    this.bankCurrency = this.bankCountry.currencies.find(c => c.code === this.method.info.bankCurrency)
    if (this.bankCurrency) this.bankCurrency = this.bankCurrency.code
    this.bankAccount = this.method.info.bankAccount
    this.bankRoutingNumber = this.method.info.bankRoutingNumber
    this.swiftAccount = this.method.info.swiftAccount
  }
}
</script>
