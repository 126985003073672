var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":_vm.isMobile ? 11 : 8}},[_c('p',{staticClass:"campo-label"},[_vm._v(" Tu proceso de alta como creador de contenido ha finalizado y, si todo está correcto, recibirás en breve un email de confirmación confirmado la verificación de tu cuenta. ")])]),_c('v-col',{attrs:{"cols":_vm.isMobile ? 11 : 8}},[_c('p',{staticClass:"campo-label"},[_vm._v(" Somos muy rápidos :-) pero si crees que está tardando más de lo habitual (máximo de 12 horas) por favor escríbenos a "),_c('a',{staticStyle:{"mso-line-height-rule":"exactly","-ms-text-size-adjust":"100%","-webkit-text-size-adjust":"100%","font-weight":"normal","text-decoration":"none"},style:({
            color: _vm.isDark ? '#FFFFFF' : '#000000',
            borderBottom: _vm.isDark ? '2px solid white' : '2px solid black'
          }),attrs:{"href":`mailto:stars@${_vm.project.project}.com`,"target":"_blank"}},[_vm._v("stars@"+_vm._s(_vm.project.project)+".com")]),_vm._v(" por si hubiera algún problema. ")])])],1),_c('v-row',{staticStyle:{"margin-bottom":"90px","margin-top":"40px"},attrs:{"justify":"center"}},[_c('v-btn',{style:({
        backgroundColor: _vm.isDark ? '#B0601E' : '#f71953',
        color: '#FFFFFF'
      }),on:{"click":function($event){return _vm.goTo('/')}}},[_vm._v(_vm._s(_vm.$t('actions.go_to_item', { item: _vm.$t('generic.home') }))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }