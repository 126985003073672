module.exports = {
    posts: {
        title: 'Title',
        content: 'Description',
        teaser: 'Teaser',
        documents: {
            person_title: 'Person {index}',
            no_person_title: 'There are no people added yet.',
            no_person_text: 'Do click in the button to upload documents for people appearing in your videos and who do\'nt have @:project account',
            name: 'Name',
            file_hint: 'Select or drop a file here. Valid Documents: ID or drive license. Max. size 500MB.',
            dni_front: 'Side 1 of ID Document',
            dni_back: 'Side 1 of ID Document',
            selfie: 'Selfie with ID Document',
            agreement: 'Agreement contract with this person',
        },
        visibility: {
            title: 'Visibility',
            open: 'Free',
            followers: 'Fans',
            premium: 'Premium'
        },
        price: 'Price',
        form: {
            uploading_file: 'There are files being uploaded',
            mentions_question: 'Are there more people on your post?',
            loverfans_users: 'Other creator on @:project',
            other_users: 'Other people without @:project',
            publish_at: 'Program publication date',
            pinned: 'Pinned Post',
            selected_hint: 'Only selected users will be able to buy the content',
            files_hint: 'You can upload up to 20 images or one video. Video size must not exceed 6GB.',
            add_document: 'Add new person',
            tiktak: 'Publish on Tiktak too'
        },
        stories: {
            following: 'View stories I follow',
            all: 'View all stories'
        },
        display: {
            video: 'Do you want to see the full video?',
            image: 'Do you want to see all the photos?',
            draft: {
                title: 'All right!',
                description_1: 'Your post is being reviewed.',
                description_2: 'The time of the process depends on the size of the original file you have uploaded and in no case should it take more than one hour.'
            }
        },
        status: {
            pending: 'Pending',
            blocked: 'Blocked',
            rejected: 'Rejected',
            requested: 'Requested',
            approved: 'Approved',
            whitelisted: 'Whitelisted',
            programmed: 'Programmed to: ',
            processing: 'Processing the upload of your content...',
            recodec: 'Recodec',
            error: 'There was an error uploading your post'
        },
        firstLoad: 'We are customizing the best content for you.',
        no_comment: {
            followers: 'If you want to write to your favorite creator, subscribe it and enjoy the best content.',
            premium: 'If you want to comment on this post, buy it and write to your favorite creator'
        },
        add_people: 'Add People',
        payment: {
            status: {
                pending: 'Pending Payment',
            }
        }
    },
    post_purchase: {
        title: 'Buy publication',
        price: '{price} Publication',
        total_price: '{price} Total',
        pay_action: 'Pay',
        conditions: 'I accept {terms_link} and I confirm that I\'ll be billed {price} + {vat_price}. For claims, you must vivist {claim_link} or send an email to {email_link}.',
        conditions_title: 'Contract conditions',
        toast_success: 'Post purchased correctly',
        toast_error: 'Error when purchasing the Post'
    },


}
