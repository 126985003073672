<template>
  <div class="relative flex flex-col items-center justify-center">
    <div :class="classSizeDiv" class="border-2 border-transparent border-t-primary rounded-full animate-spin"></div>
    <span :class="classPositionImage" class="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
      <Icon icon="lover-cash" :size="classSizeIcon"/>

    </span>
  </div>
</template>

<script >
import Icon from "@/design/Icon.vue";
import {computed} from "vue";

export default {
  name: 'SpinnerBase',
  components: {Icon},
  props:{
    size:{type:['sm', 'md'],default: 'md'},
  },
  setup(props) {
    const classSizeDiv = computed(() => props.size === 'md' ? 'w-20 h-20' : 'w-16 h-16')
    const classPositionImage = computed(() => props.size === 'md' ? 'top-10' : 'top-8')
    const classSizeIcon = computed(() => props.size === 'md' ? '10' : '7')
    return {
      props,
      classSizeDiv,
      classPositionImage,
      classSizeIcon,
    }
  }
}

</script>
