module.exports = {
    subscription: {
        one: 'Subscriber',
        more: 'Subscribers',
        title: 'Subscribe to {username} to get all these benefits:',
        title2: 'Subscribe to see all videos',
        conditions: 'I accept {terms_link} and I confirm that I will be billed {price} + {vat_price}. For claims, you must vivist {claim_link} or send an email to {email_link}.',
        info: {
            fans: 'Access to his/her private posts',
            cancel: 'Cancel whenever you want',
            chat: 'Chat to this Profile',
        },
        trials: {
            createdAt: 'Created At:',
            expiredAt: 'Expired At: ',
            creditCard: 'Credit/Debit Card',
            limit: 'Trials Limit: ',
            used: 'Trials used: '
        },
        payment: {
            check: {
                first: 'By clicking the button, I accept the ',
                second: 'and I confirm that I will be billed { amount } + {vatAmount}.',
                third: ' For claims, please visit ',
                fourth: 'or send an email to ',
            }
        },
        change: {
            title: 'Edit subscriptions',
            price: {},
            trials: {
                description: 'Free trial of (duration)',
                condition: 'The user will only have access to your profile during the trial (duration) and will not be renewed afterwards.',
                form: {
                    label_fans: 'Number of Subscribers',
                    label_expiration: 'Offer expiration',
                    label_duration: 'Trial duration'
                }
            }
        },
        cancel: {
            title: 'Cancel Subscription',
            confirm: 'CONFIRM UNSUBSCRIBE',
            desc: 'Do you really want to cancel your { username } subscription? The next time you want to sign up as a { username } subscriber, the current amount may not be maintained and will be updated according to the prices set by the content creator.',
            first_note: '* You\'ll continue to have access to your Followers content until the end of your subscription.',
            second_note: '* You\'ll continue to have access to premium content purchased by you.',
        },
        months_by: '<b>{months} month</b>  subscription',
        month_by: 'First month',
        unique: 'Unique payment {amount}',
        reactive: 'Reactivate subscription',
        expiredAt: 'Subscription to <span class="text-primary">{ username }</span> expired at { date }',
        subscribe: 'SUBSCRIBE ME FOR {amount}',
        others: 'See other subscriptions',
        expired: 'Subscription expired',
        message: 'Subscription message',
        subscribed_by: 'Subscribed by {price}/Month',
        error: {
            description_1: "We're sorry, there was an issue processing your payment.",
            description_2: "Please try again. If the problem persists, contact our support team at {email}"
        },
        discount: '{discount}% off · '
    }
}
