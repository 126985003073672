<template>
  <PaymentForm
    v-bind="$props"
    :provider="provider"
    :payment-form-url="paymentFormUrl"
    resource="subscriptions"
    :subscription="selectedOffer"
    :resource-id="resource.username"
    :error="error"
    :error-message="errorMessage"
    :disable-next-button="!isCreditCardEnabled"
    @payment="subscribe"
    @close="$listeners.close">
    <v-row>
      <v-col cols="4">
        <UserAvatar large :user="resource" />
      </v-col>
      <v-col cols="8">
        <div class="my-2">
          <v-icon>remove_red_eye</v-icon>
          {{ $t('payments.subscriptions.engagment_1') }}
        </div>
        <div class="my-2">
          <v-icon>chat</v-icon>
          {{ $t('payments.subscriptions.engagment_2') }}
        </div>
        <div class="my-2">
          <v-icon>lock_open</v-icon>
          {{ $t('payments.subscriptions.engagment_3') }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col  v-for="(offer, index) in reverseOffer"
        :key="`sub-${index}`"
        cols="12">
        <v-card flat
          class="bordered text-center pointer subscription-type justify-center"
          :class="{ selected: isOfferSelected(offer), best: isBestOffer(offer) }"
                style="min-height: 92px !important;"
          @click="selectOffer(offer)">
          <v-col cols="12" >
            <v-row>
              <v-col cols="8" style="text-align: left;margin: auto">
                <span class="sub-type" v-html="$tc(`subscriptions.descriptions.${offer.type}`, offer.discount)"></span>
                <span v-if="!isMonthly(offer)" class="unique-pay" v-html="$t('subscriptions.descriptions.unique', {price: isSpecialOfferActive ? specialOfferPrice(offer) : totalOffer(offer), months: offer.months})"></span>
                <span v-if="isMonthOffer(offer)" class="unique-pay" v-html="$t('subscriptions.descriptions.offer', {price: basePrice(offer), offer: offer.discount})"></span>
                <span class="unique-pay" v-if="specialOffer" v-html="$t('subscriptions.descriptions.specialOffer', {discount: config.specialOffer.discount, endDate: utils.formatDate(config.specialOffer.endDate)})"></span>
              </v-col>
              <v-col cols="4" style="margin: auto">
                <strong class="price primary--text" :style="{'font-size': isSpecialOfferActive ? '18px' : '24px'}"> <span :style="{'text-decoration': isSpecialOfferActive ? 'line-through' : ''}">{{ offerPrice(offer) }}</span><span class="text" :style="{'text-decoration': isSpecialOfferActive ? 'line-through' : ''}">/{{ $t('words.month')}}</span></strong><br>
                <strong class="price primary--text" style="font-size: 24px" v-if="isSpecialOfferActive"> {{ specialOfferBasePrice(offer) }}<span class="text">/{{ $t('words.month')}}</span></strong>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <VatExplain :price="vatPrice" />
  </PaymentForm>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { UserActions } from '@/store'
import PaymentForm from '@/components/payments/forms/PaymentForm'
import UserAvatar from '@/components/users/UserAvatar'
import SubscriptionApi from '@/api/SubscriptionApi'
import VatExplain from '../VatExplain.vue'
import payments from '@/components/mixins/payments'
import config from '@/project'
import utils from '@/utils'

export default {
  name: 'SubscriptionPayment',
  mixins: [payments],
  components: { VatExplain, PaymentForm, UserAvatar },
  props: {
    resource: Object
  },
  data () {
    return {
      loading: false,
      offers: [],
      selected: null,
      provider: '',
      paymentFormUrl: '',
      errorMessage: '',
      specialOffer: false,
      config,
      utils
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    ...mapState('application', ['affiliate', 'affiliateSource']),
    ...mapGetters('revolution', ['getSfsUser']),
    origin () {
      if (config.payments.darkfans_redirect) return `https://darkfans.com?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
      return `${window.location.origin}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
    },
    error () {
      return !this.selected
    },
    selectedOffer () {
      return this.offers.find(o => o.type === this.selected)
    },
    vatPrice () {
      if (!this.selectedOffer) {
        return
      }
      const offer = this.selectedOffer
      const vat = (this.currentUser.billing.vat || 22) / 100
      const exes = this.currentUser.billing.exes / 100
      const price = offer.basePrice - ((offer.basePrice * offer.discount) / 100)
      const totalAmount = price * offer.months
      const expenses = totalAmount * exes
      const vatAmount = (totalAmount + expenses) * vat
      return expenses + vatAmount
    },
    reverseOffer () {
      return [...this.offers].reverse()
    },
    isSpecialOfferActive () {
      return false
      const today = new Date()
      return config.specialOffer.active && config.specialOffer.startDate < today && config.specialOffer.endDate > today && config.specialOffer.discount > 0
    }
  },
  methods: {
    isMonthOffer (offer) {
      return offer.type === 'month' && offer.discount > 0
    },
    isMonthly (offer) {
      return offer.type === 'month'
    },
    isOfferSelected (offer) {
      return this.offers.length === 1 ? true : this.selected === offer.type
    },
    selectOffer (offer) {
      this.selected = offer.type
      document.getElementById('paymentNext').scrollIntoView({ behavior: 'smooth' })
    },

    basePrice (offer) {
      return this.$currency(offer.basePrice * offer.months)
    },

    offerPrice (offer) {
      const price = offer.basePrice - ((offer.basePrice * offer.discount) / 100)
      return this.$currency(price)
    },

    specialOfferBasePrice (offer) {
      const price = offer.basePrice - ((offer.basePrice * offer.discount) / 100)
      return this.$currency(price * (100 - config.specialOffer.discount) /100)
    },

    totalOffer (offer) {
      const price = offer.basePrice - ((offer.basePrice * offer.discount) / 100)
      return this.$currency(price * offer.months)
    },

    specialOfferPrice (offer) {
      const price = offer.basePrice - ((offer.basePrice * offer.discount) / 100)
      return this.$currency(price * ((100 - config.specialOffer.discount) / 100) * offer.months)
    },

    resetState () {
      this.paymentFormUrl = ''
      this.selected = null
      this.errorMessage = ''
    },

    isBestOffer (offer) {
      if (this.offers.length < 2) {
        return true
      }
      return offer === this.getBestOffer()
    },
    getBestOffer () {
      let bestOffer = this.offers[0]
      for (const offer of this.offers) {
        if (offer.discount > bestOffer.discount) {
          bestOffer = offer
        } else if (offer.discount === bestOffer.discount && offer.months > bestOffer.months) {
          bestOffer = offer
        }
      }
      return bestOffer
    },
    async subscribe ({ name }) {
      const affiliate = this.affiliate
      const affiliateSource = this.affiliateSource
      const spam4spam = this.getSfsUser(this.resource.username) || {}
      const { data, error } = await SubscriptionApi.create({
        name,
        influencer: this.resource.username,
        type: this.selected,
        affiliate,
        affiliateSource,
        sfsUsername: spam4spam.affiliate,
        sfsSource: spam4spam.source,
        origin: this.origin
      })
      if (error) {
        this.selected = null
        this.errorMessage = data.error
      } else if (data.redirectUrl) {
        const slash = data.redirectUrl.includes('?') ? '&' : '?'
        window.location = `${data.redirectUrl}${slash}provider=${data.project}`
        this.$gtm('subscription_step2')
      } else {
        this.provider = data.provider
        this.paymentFormUrl = data.url
        this.$gtm('subscription_step2')
      }
      return { data, error }
    },
    async load () {
      this.loading = true
      const user = await this.$store.dispatch(UserActions.Fetch, { username: this.resource.username, force: true })
      const enabled = user.subscriptionOffers.filter(o => o.type === 'month' || o.enabled)
      if (!enabled.length || enabled[0].type !== 'month') {
        enabled.splice(0, 0, { type: 'month', basePrice: user.subscriptionPrice, months: 1, discount: 0, enabled: true })
      }
      this.offers = enabled.map(o => ({ ...o, basePrice: user.subscriptionPrice }))
      this.selected = this.getBestOffer().type
      this.loading = false
    }
  },
  async mounted () {
    this.offers = [{ type: 'month', basePrice: this.resource.subscriptionPrice, months: 1, discount: 0, enabled: true }]
    this.selected = this.getBestOffer().type
    this.$gtm('subscription_step1')
    await this.load()
    this.specialOffer = this.isSpecialOfferActive
  }
}
</script>
<style lang="scss" scoped>
@use 'src/scss/colors';
.selected {
  background-color: #35cf95 !important;
  border-radius: 4px !important;
  border: solid 3px #2fa478 !important;
  color: #FFFFFF !important;

  &.dark{
    background-color: #35cf95 !important;
    border-radius: 4px !important;
    border: solid 3px #2fa478 !important;
  }
  .sub-type {
    color: #DF3856;
  }
  .unique-pay {
    color: #DF3856;
  }
  .price {
    color: #DF3856!important;
    .text {
      color: #DF3856;
    }
  }
}

.sub-type {
  font-size: 25px;
  font-family: Montserrat, sans-serif;
  line-height: 34px;
  text-align: left;
  color: #df3856;
}

.dark {
  .unique-pay {
    color: #FFFFFF !important;
  }
}

.unique-pay {
  margin-left: 15px;
  font-size: 13px;
  line-height: 16px;
  color: #545454;
}

.price {
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 29px;
  line-height: 24px;

  .text {
    font-weight: normal;
    font-size: 22px;
  }
}

::v-deep.subscription-type {
  &.selected {
    background: #35cf95 !important;

    .sub-type {
      color: #FFFFFF !important;
    }
    .price {
      color: #FFFFFF !important;
    }
    .text {
      color: #FFFFFF !important;
    }
    .unique-pay {
      color: #FFFFFF !important;
    }

    &.dark {
      background-color: #35cf95 !important;
      border-radius: 4px !important;
      border: solid 3px #2fa478 !important;
      .light-text {
        color: #FFFFFF !important;
      }
    }
  }
  .duration {
    font-size: 20px;
    line-height: 20px;
  }
  .price {
    .price {
      font-size: 30px;
      vertical-align: text-top;
    }
    .point {
      vertical-align: text-bottom;
    }
    .discount {
      font-size: 20px;
      vertical-align: text-top;
    }
  }
  .price-info {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
</style>
