<template>
  <div v-bind="attrs">
    <div class="relative ">
      <div v-bind="activatorProps" class="cursor-pointer">
        <slot name="activator" :props="{ onClick: activatorOnClick }"></slot>
      </div>
      <transition name="dropdown" v-if="!isMobile">
        <div v-if="isOpen" @click.stop="closeDropdown"
             class="absolute right-1 top-7
            bg-secondary-100 rounded shadow z-50">
          <slot>
            <div v-for="(item,index) in items" :key="index">
              <div class="p-4 w-72 text-sm text-secondary-500 hover:bg-secondary-200 rounded cursor-pointer"
                   @click.stop="selectDropdown(item,index)">
                {{ $t(item.label) }}
              </div>
            </div>
          </slot>
        </div>
      </transition>
    </div>

    <transition name="dropdown" v-if="isMobile">
      <div @click="closeDropdown" v-if="isOpen"
           class="fixed inset-0 flex items-center justify-center bg-secondary-900/80  z-50">
        <div class="bg-secondary-100 rounded shadow-lg">
          <slot>
            <div v-for="(item,index) in items" :key="index">
              <div class="p-4 w-72 text-sm text-secondary-500 hover:bg-secondary-200 rounded cursor-pointer"
                   @click.stop="selectDropdown(item,index)">
                {{ $t(item.label) }}
              </div>
            </div>
          </slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import {ref, useAttrs, watchEffect} from "vue";
import {storeToRefs} from "pinia";
import {useApplicationStore} from "@/store/application_2";

export default {
  name: "Dropdown",
  props: {
    activatorProps: {},
    items: []
  },
  setup(props, {emit,slot}) {
    const isOpen = ref(false)
    const {isMobile} = storeToRefs(useApplicationStore())
    const attrs = useAttrs()
    const activatorOnClick = (event) => {
      event.stopPropagation()
      toggleDropdown()
      /*isOpen.value = true
      emit('update:modelValue', true)*/
    }
    watchEffect(() => {
      if (!isOpen.value) return
      const closeMenuOnOutsideClick = (event) => {
        if (!event.target.closest('.relative')) {
          closeDropdown()
          document.removeEventListener('click', closeMenuOnOutsideClick)
        }
      }
      document.addEventListener('click', closeMenuOnOutsideClick)
    })

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value
      if (isOpen.value) {
        emit('close')
      }
    }
    const selectDropdown = (item, index) => {
      emit('select', item, index)
      closeDropdown()
    }
    const closeDropdown = () => {
      isOpen.value = false
    }
    return {props,slot, emit, isOpen, attrs, isMobile, activatorOnClick, toggleDropdown, selectDropdown, closeDropdown}
  }
}

</script>

<style scoped>
.dropdown-enter-active,
.dropdown-leave-active {
  transition: opacity 0.3s ease;
}

.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
}

.shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext.perfil {
  visibility: visible;
  opacity: 1;
}
</style>
