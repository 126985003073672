<template>
  <v-app :style="rootStyle">
    <v-content :class="cssClass">
      <router-view :key="$route.fullPath"/>
      <app-footer app></app-footer>
    </v-content>
  </v-app>
</template>

<script>
import project from '@/project'
import AppFooter from '@/components/layouts/AppFooter'
import {mapState} from "vuex";

export default {
  name: "FullLanding",
  components: {
    AppFooter,
  },
  data () {
    return {
      project
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isFollower']),
    ...mapState('application', ['isMobile', 'showNewNotification']),
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    cssClass () {
      return {
        isMobile: this.isMobile
      }
    },
    rootStyle () {
      return {
        background: this.$vuetify.theme.themes[this.theme].background
      }
    },
    showDisclaimer () {
      return !project.project.includes('loverfans')
    },
    isScatbook () {
      return this.project.project === 'scatbook'
    },
    isLoverfans () {
      return this.project.project === 'loverfans'
    },
    isDarkfans () {
      return this.project.project === 'darkfans'
    }
  },
  mounted () {
    this.$vuetify.theme.dark = project.theme === 'dark'
  }
}
</script>

<style scoped>

</style>
