<template>
  <div class="center" style="position: relative;">
    <img
      v-if="isAudio"
      :src="getIconAudio"
      class="img-responsive"
      :class="classImg"
      alt=""
    />
    <div v-else>
      <div class="position-middle">
        <IconButton disabled :size="40" type="padlock"/>
      </div>
      <video
        v-if="isVideo"
        ref="video"
        controlslist="nodownload"
        :src="src"
        class="video-responsive"
        :class="classImg"
        alt=""
      ></video>
      <img
        v-if="isImage"
        :src="src"
        class="img-responsive"
        :class="classImg"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import IconButton from "@/components/chat/component/IconButton.vue";
import {theme} from '@/configFactory'

export default {
  name: "Block",
  components: {IconButton},
  props: {
    primary: Boolean,
    src: String,
    type: String
  },
  computed: {
    getIconAudio() {
      return require(`${theme}/chat/img-block.svg`);
    },
    classImg() {
      return {
        "primary-video": this.primary,
        "secondary-video": !this.primary
      };
    },
    isImage() {
      return this.type === "image";
    },
    isVideo() {
      return this.type === "video";
    },
    isAudio() {
      return this.type === "audio";
    }
  }
};
</script>
<style lang="scss" scoped>
video {
  width: 100%;
  height: 100%;
}

.primary-video {
  max-width: 350px;
  max-height: 350px;
  min-height: 350px;
  width: 100%;
}

.secondary-video {
  max-width: 100px !important;
  max-height: 100px !important;
  min-height: 100px;
}

.video-responsive {
  /* height: auto; */
  object-fit: contain;
  background: #6e6c6c;
  border-radius: 4px;
  /* min-width: 40px  */
  /*operahack*/
}

.img-responsive {
  /* height: auto; */
  object-fit: cover;
  border-radius: 4px;
  /* min-width: 40px  */
  /*operahack*/
}

.gradient {
  background: linear-gradient(
      to bottom,
      rgba(188, 188, 188, 0.5),
      rgba(188, 188, 188, 0.5)
  );
}

.ajust {
  position: absolute;
  left: 55%;
  top: 70%;
}

.position-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.blur {
  background: linear-gradient(
      to bottom,
      rgba(188, 188, 188, 0.5),
      rgba(188, 188, 188, 0.5)
  );
}
</style>
