module.exports = {
  application: {
    artemisa: 'Artemisa 3000 Tech Solutions SL',
    cif: 'CIF: B88466743',
    address: 'Calle Goya, 47, floor 7, door 12 - 28001, Madrid, Spain',
    payments: 'CommerceGate: Payment Facilitator and Billing Support',
    frequent_questions: 'Frequenly asked questions',
    cookies: 'Cookies managment',
    terms: 'Terms & Conditions',
    privacy: 'Privacy policy',
    contact: 'Contact',
    usc_2257: 'USC 2257',
    copyright: '2024 © {site} · All right reserved',
  },
  locales: {
    en: 'English',
    es: 'Spanish',
    pt: 'Portuguese',
  },
  boolean: {
    true: 'Yes',
    false: 'No'
  },
  datetime: {
    format: 'MM/DD/YYYY HH:MM:SS',
    minutes: 'MM/DD/YYYY HH:MM',
    selectDate: 'Select a Date',
    selectTime: 'Select an Hour',
  },
  date: {
    format: 'MM/DD/YYYY',
    name: 'Date',
    date: 'Date',
    day: 'Day',
    days: 'Days',
    month: 'Month',
    months: 'Months',
    year: 'Year',
    hour: 'Hour',
    minute: 'Minute',
    hours: 'Hours',
    minutes: 'Minutes',
    second: 'Second',
    seconds: 'Seconds',
    invalid: 'Invalid date'
  },
  month: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'Dicember'
  },
  payment_methods: {
    label: 'Payment method',
    wallet: 'Wallet amount',
    card: 'Credit Card'
  },
  actions: {
    discover: 'Go To Discover',
    actions: 'Actions',
    accept: 'Accept',
    add: 'Add',
    add_more: 'Add more',
    contact: 'Contact',
    already_subscribed: 'Subscribed',
    back: 'Back',
    back_to_home: 'Back to Home',
    configure: 'Configure',
    confirm: 'Confirm',
    ok: 'Ok',
    ok_all: 'Accept all',
    ok_selected: 'Accept selected',
    cancel: 'Cancel',
    cancel_subscription: 'Cancel Subscription',
    send_subscription: 'Send Subscription',
    subs_send_message: 'Subscription message',
    edit: 'Edit',
    continue: 'Continue',
    select: 'Select',
    save: 'Save',
    update: 'Update',
    send: 'Send',
    send_tips: 'Send tip',
    pay: 'Pay',
    see: 'Show',
    show: 'Show',
    see_all: 'Show all',
    see_more: 'See more',
    see_less:'See less',
    more_info: '+Info',
    see_rank: 'See ranking',
    show_all: 'Show all',
    follow: 'Follow for free',
    simple_follow: 'Follow',
    subscribe: 'Subscribe',
    subscribe_by: 'Subscribe for {value}',
    change_subscribe_by: 'Change subscription price',
    signup: 'Free Sign up',
    free_access: 'Access w/o Registration',
    search: 'Search',
    search_users: 'Search Users',
    search_lists: 'Search Lists',
    login: 'Log in',
    logout: 'Logout',
    publish: 'Publish',
    enter: 'Enter',
    exit: 'Exit',
    request_email: 'Request email',
    copy_link: 'Copy link',
    copy_link_referral: 'Copy referral link',
    close: 'Close',
    buy: 'Buy',
    buy_by: 'Buy for',
    show_details: 'Show details',
    show_edit: 'Show or edit',
    delete: 'Delete',
    filter: 'Filter',
    upload: 'Upload',
    upload_image: 'Upload image',
    next: 'Next',
    previous: 'Previous',
    unfollow: 'Unfollow',
    reload: 'Reload',
    reload_wallet: 'Reload wallet',
    withdraw: 'Withdraw',
    request_witdraw: 'Request Withdrawal',
    other: 'Other',
    send_tip: 'Send tip',
    load_funds: 'Load Funds',
    upload_new: 'Upload new',
    upload_album: 'From Album',
    upload_vault: 'From Vault',
    vault: 'From Vault',
    private: 'Videocall',
    remove_micro: 'Remove micro',
    remove_cam: 'Remove camera',
    call: 'Call',
    calling: 'Calling',
    hang: 'Hang',
    hanging: 'Hanging',
    tip: 'Tip',
    like: 'Love',
    comment: 'Comment',
    repost: 'Repost',
    favorite: 'Favorite',
    finish: 'Finish',
    show_item: 'Show {item}',
    show_post: 'Show post',
    show_product: 'Show product',
    go_to_item: 'Go to {item}',
    connect: 'Connect',
    disconnect: 'Disconnect',
    bid: 'Bid',
    bid_again: 'Bid again',
    twitter_login: 'Sign up/Login with Twitter',
    discover_them: 'Discover them',
    more: 'More',
    bring_me_there: 'Bring me',
    block: 'Block',
    unblock: 'Unblock',
    reject: 'Reject',
    commit: 'Commited',
    chose: 'Chose',
    order_by: 'Order by',
    join: 'Join',
    start: 'Start',
    retry: 'Retry',
    change: 'Change',
    placeholder_select_file: 'Choose or drop a File',
    download: 'Download',
    downloadQR: 'Download QR',
    rotate_left: 'Rotate 90º to Left',
    rotate_right: 'Rotate 90º to Right',
    flipX: 'Flip horizontally',
    flipY: 'Flip Vertically',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    fly: 'Change fly position',
    record: 'Record',
    stop: 'Stop',
    fullVideo: 'SHOW FULL VIDEO',
    step: 'Go to step {count}',
    end: 'Finish',
    move: 'Move',
    reset_changes: 'Reset changes',
    set_price: 'Set price',
    attach: 'Attach file',
    buy_for: 'FOR: {price}',
    buy_for_multiple: '{number} FOR: {price}',
    translate: 'Translate',
    nottranslate: 'Don\'t Translate',
    actives_from: 'Active from',
    online: 'Online',
    add_followers: 'Add Followers',
    send_multiple: 'Send Message',
    send_message: 'Send Message',
    send_multiple_message: 'Send broadcast Message',
    criteria: 'Strict Criteria',
    status: 'Status',
    inclusive: 'Satisfy <strong>ALL</strong> criteria',
    exclusive: 'Satisfy <strong>ANY</strong> criteria',
    send_date: 'Send',
    name: 'Name',
    description: 'Description',
    amount: 'Amount',
    required_amount: 'Required Amount',
    start_date: 'Start Date',
    code: 'Code',
    expiration_date: 'Expiration Date',
    still_uploading: 'Loading video...',
    loading: 'Loading...',
    sign: 'Sign',
    signed: 'Signed',
    renew: 'Renew',
    show_more: 'Show More',
    show_less: 'Show Less',
    show_more_offers: 'Show more offers',
    show_less_offers: 'Show less offers',
    refreshing: "refreshing",
    share: {
      label: 'Share',
      network: 'Share in media',
      post: 'Share Post'
    },
    posts: {
      edit: 'Edit Post',
      copy: 'Copy link',
      share: 'Share Post',
      delete: 'Delete Post',
    },
    report: {
      label: 'Report',
      reason: 'Reason',
      post: 'Report Post',
      user: 'Report Post',
      story: 'Report Story'
    },
    participants: 'Who is in the story?',
    copy: 'Copy Link',
    see_profile: 'See profile',
    see_public_profile: 'See my public profile',
    remove_suggested: 'Remove from suggested accounts'
  },

  suggest_account: 'Suggested Accounts',
  no_content: 'There is no content in this section',
  no_data: 'There is no data available',
  send_private: 'Send him/her a private message and encourage him/her to upload content!',
  select_one: 'Select one',
  select_lang: 'Select a Language',
  legal_info: 'Legal Information',
  maintenance: {
    title: 'Maintenance Page',
    desc: 'We are working to improve the website, and it will be available again very soon',
  },
}
