<template>
  <section style="position: relative; height: -webkit-fill-available;">
    <component :is="layout" ></component>
  </section>
</template>

<script>
import { AppActions } from './store'
import config from '@/project'
import BottomNav from '@/components/footer/BottomNav.vue'

const configFactory = require('@/configFactory');
const newConfig = configFactory().getConfig();
function debounce (func, wait, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)

    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export default {
  name: 'Loverfans',
  components: { BottomNav },

  metaInfo () {
    const project = config.project
    return {
      title: this.$t(`meta.${project}.title`),
      meta: [
        { vmid: 'description', name: 'description', content: this.$t(`meta.${project}.description`) },
        { property: 'og:title', content: this.$t(`meta.${project}.title`) },
        { property: 'og:site_name', content: config.locales.site },
        { property: 'og:description', content: this.$t(`meta.${project}.description`) },
        { property: 'og:type', content: 'home' },
        { property: 'og:url', content: newConfig.baseUrl }
      ]
    }
  },

  computed: {
    layout () {
      let layout = this.$route.meta.layout
      if (!layout) {
        const childRouteLayout = this.$route.matched.find(m => m.meta.layout)
        if (childRouteLayout) {
          layout = childRouteLayout.meta.layout
        }
      }
      return layout
    }
  },
  methods: {
    onResize () {
      const dim = {
        width: window.innerWidth,
        height: window.innerHeight
      }
      this.$store.dispatch(AppActions.UpdateScreenSize, dim)
    }
  },
  created () {
    this.onResize()
  },
  mounted  () {
    window.addEventListener('resize', debounce(this.onResize, 100))
  },
  beforeDestroy  () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss">
@import 'src/scss/theme.scss';
@font-face {
  font-family: "AllertaStencil";
  src: local("AllertaStencil"),
  url(/fonts/AllertaStencil-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Cabin";
  src: local("Cabin"),
  url(/fonts/CabinCondensed-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "AllertaStencil";
  src: local("AllertaStencil"),
  url(/fonts/AllertaStencil-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "CabinCondensed";
  src: local("CabinCondensed"),
  url(/fonts/CabinCondensed-Regular.ttf) format("truetype");
  font-display: swap;
}
/* vietnamese */
@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anton/v15/1Ptgg87LROyAm3K8-C8QSw.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anton/v15/1Ptgg87LROyAm3K9-C8QSw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/anton/v15/1Ptgg87LROyAm3Kz-C8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* vietnamese */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v24/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v24/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v24/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v24/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v24/6xKtdSZaM9iE8KbpRA_hJFQNcOM.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v24/6xKtdSZaM9iE8KbpRA_hJVQNcOM.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QN.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "BenchNine";
  src: local("BenchNine"),
  url(/fonts/BenchNine-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "BenchNine-Light";
  src: local("BenchNine-Light"),
  url(/fonts/BenchNine-Light.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "BenchNine-Bold";
  src: local("BenchNine-Bold"),
  url(/fonts/BenchNine-Bold.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Satisfy-Regular";
  src: local("Satisfy-Regular"),
  url(/fonts/Satisfy-Regular.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Bodoni";
  src: local("SBodoni"),
  url(/fonts/BodoniModa.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
  url(/fonts/Montserrat.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat_SemiBold";
  src: local("Montserrat_SemiBold"),
  url(/fonts/Montserrat_SemiBold.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat_Bold";
  src: local("Montserrat_Bold"),
  url(/fonts/Montserrat_Bold.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "FilmoType_Keynote";
  src: local("FilmoType_Keynote"),
  url(/fonts/filmotypekeynote.otf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Black";
  src: local("Montserrat-Black"),
  url(/fonts/Montserrat-Black.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Mshtakan";
  src: local("Mshtakan"),
  url(/fonts/mshtakan.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Sacramento";
  src: local("Sacramento"),
  url(/fonts/Sacramento.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto-italic"),
  url(/fonts/Roboto-italic.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto-Medium"),
  url(/fonts/Roboto-Medium.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto-Mediumitalic"),
  url(/fonts/Roboto-Mediumitalic.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto-Regular"),
  url(/fonts/Roboto-Regular.ttf) format("truetype");
  font-display: swap;
}
</style>
