<template>
  <div class="h-96 w-full mt-4 flex flex-col">
    <div class="space-y-2 px-2  w-full">
      <SpinnerSkeleton w="76" h="24"/>
      <SpinnerSkeleton w="76" h="24"/>
      <SpinnerSkeleton w="76" h="24"/>
      <SpinnerSkeleton w="76" h="24"/>
    </div>
  </div>
</template>

<script>
import SpinnerSkeleton from "@/design/spinner/SpinnerSkeleton.vue";

export default {
  name: "RecommendedSkeleton",
  components: {SpinnerSkeleton}
}
</script>
