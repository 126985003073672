<template>
  <div class="p-[1px] rounded-full border" :class="addClassStory">
    <div class="flex justify-center bg-white items-center  w-auto h-auto rounded-full  border-white "
         :class="{'border-2':isStory}">
      <div class="relative rounded-full bg-cover"
           @click="doRequest"
           :class="classSize"
           :style="{ backgroundImage: 'url(' + src + ')' }">
        <div v-if="isOnline" class="absolute rounded-full bg-success" :class="classOnline"/>
        <div v-if="isFree" class="absolute bottom-[-10px] flex justify-center h-full w-full items-end">
          <span class="rounded px-0.5 bg-success text-[11px]">FREE</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {computed, getCurrentInstance} from "vue";
import {storeToRefs} from "pinia";
import {useSessionStore} from "@/store/session_2";
import profile from "@/store/profile";

export default {
  name: "UserAvatar",
  props: {
    size: {type: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl', default: 'xs',},
    noActions: {type: Boolean},
    isStory: {type: Boolean},
    includeFree: {type: Boolean},
    user: {}
  },
  setup(props, {emit}) {
    const currentUser = profile.state.currentUser
    const {isFollower, isInfluencer} = storeToRefs(useSessionStore())
    const {$router} = getCurrentInstance().proxy;
    const addClassStory = computed(() => {
      return {
        'cursor-pointer': !props.noActions,
        'inline_block_story ': props.isStory,
        'inline-block': !props.isStory || isViewedStory.value
      }
    })

    const src = computed(() => {
      if (props.user && props.user.avatarS3Route) {
        return props.user.avatarS3Route
      }
      return '/assets/images/avatar.svg'
    })

    const isOnline = computed(() => {
      return props.user?.onlineStatus === 'online'
    })

    const isViewedStory = computed(() => {
      return props.user.viewed
    })

    const classSize = computed(() => {
      const size = {
        '3xs': 'h-5 w-5 ',
        '2xs': 'h-10 w-10 ',
        xs: 'h-12 w-12 ',
        sm: 'h-16 w-16 ',
        md: 'h-20 w-20 ',
        lg: 'h-24 w-24 ',
        xl: 'h-28 w-28 ',
      }
      return size[props.size]
    })

    const classOnline = computed(() => {
      const size = {
        '3xs': 'h-[4px] w-[4px] top-0 left-0',
        '2xs': 'h-[6px] w-[6px] top-0 left-1',
        xs: 'h-2 w-2 left-0.5',
        sm: 'h-2.5 w-2.5 left-1',
        md: 'h-2.5 w-2.5 top-1 left-1',
        lg: 'h-3 w-3 top-1 left-2',
        xl: 'h-3 w-3 top-1 left-3',
      }
      return size[props.size]
    })

    const isFree = computed(() => props.includeFree && props.user.subscriptionPrice === 0)

    const doRequest = (event) => {
      if (!props.noActions) {
        if (currentUser && currentUser.value.username === props.user.username) {
          if (currentUser.value.role === 'follower') {
             $router.push(`/${currentUser.value.username}/subscriptions/expired`)
          } else {
            $router.push(`/${currentUser.value.username}`)
          }
        } else {
          $router.push(`/${props.user.username}`)
        }
      }
      emit('click')
    }
    return {
      props,
      emit,
      currentUser,
      isFollower,
      isInfluencer,
      addClassStory,
      src,
      isOnline,
      isViewedStory,
      classSize,
      classOnline,
      isFree,
      doRequest
    }
  }
}

</script>

<style scoped lang="scss">
/*.inline-block {
  display: inline-block;
  background: linear-gradient(45deg, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5);
  background-size: 1600% 1600%;
  animation: gradient-animation 10s ease infinite;
}*/
.inline_block_story {
  display: inline-block;
  background: linear-gradient(45deg, #E0173B, #DE7BDE, #E0173B);
  background-size: 1600% 1600%;
  animation: gradient-animation 10s ease infinite;
}

.inline-block {
  display: inline-block;
  background: linear-gradient(45deg, #ffffff, #ffffff);
  background-size: 1600% 1600%;
  animation: gradient-animation 10s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
