<template>
  <v-form ref="form" v-model="validForm">
    <p class="mb-2 text-md font-bold">{{ titleForm }}</p>
    <v-text-field
      outlined
      :label="labelForm"
      type="text"
      v-model="accountId"
      :rules="[$vRequired]"/>

  </v-form>
</template>

<script>
import validatable from "@/components/mixins/validatable";

export default {
  mixins: [validatable('form')],
  props: {
    method: {
      type: Object,
      default: null
    },
    selectedMethod: {
      type: String
    }
  },
  data() {
    return {
      accountId: ''
    }
  },
  computed: {
    titleForm() {
      return this.$t(`withdraws.withdraw_methods.types.${this.selectedMethod}`)
    },
    labelForm() {
      return this.$t(`withdraws.withdraw_methods.${this.selectedMethod}.form.label`)
    },
    methodAttrs() {
      return {
        type: this.selectedMethod,
        accountId: this.accountId,
        withdrawMethodApproved: true
      }
    }
  },
  watch: {
    validForm() {
      this.$emit('validForm', this.validForm)
    },
    methodAttrs() {
      this.$emit('methodAttrs', this.methodAttrs)
    }
  },

  mounted() {
    if (!this.method) {
      return
    }
    this.accountId = this.method.accountId
  }
}
</script>

<style scoped lang="scss">

</style>
