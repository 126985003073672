<template>
    <div :id="generateUniqueId"
         class="player-container"
         :data-token="resource.url"
         data-config='{"autoPlay":"true","startMuted":"true","loop":"false"}'/>
</template>

<script>
export default {
  name: 'FastEvoVideoVideo',
  components: {},
  props: {
    resource: Object
  },
  computed: {
    generateUniqueId() {
      return `id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    }
  }
}

</script>
<style scoped>
.fast-evo-video{
  border-radius: unset !important;
}

.player-container {
  width: 100%;
  margin: auto;
  background-color: #111111;
}
</style>
