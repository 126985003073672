var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.classActivate},[_c('div',{staticClass:"img-ico d-flex",class:_vm.cssClass,on:{"click":_vm.onClickIcon}},[(_vm.text && _vm.left)?_c('div',{staticStyle:{"text-decoration":"underline"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),(_vm.image)?_c('img',{staticClass:"img-ico",class:_vm.cssClass,style:(`
      width: ${_vm.size}px;
      height: ${_vm.size}px;
      margin-${_vm.left ? 'left' : 'right'}: 5px;
      ${
        _vm.white
          ? 'filter: brightness(0) saturate(100%) invert(98%) sepia(100%) saturate(7%) hue-rotate(186deg) brightness(101%) contrast(101%);'
          : ''
      }
      `),attrs:{"src":_vm.icon.src,"alt":""}}):_vm._e(),(_vm.text && !_vm.left)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.text))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }