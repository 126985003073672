<template>
  <div class="pl-3">
    <router-link :to="{ name: 'home' }" class="" v-if="isLinkable">
      <v-img
        contain
        :src="getSrc"
        :maxHeight="maxHeight"
        :width="width"
        class="img"
        :style="{ margin: !isLargeScreen ? 'auto' : '' }"
      ></v-img>
    </router-link>
    <v-img v-else
           contain
           :src="getSrc"
           :maxHeight="maxHeight"
           :width="width"
           class="img"
           :style="{ margin: !isLargeScreen ? 'auto' : '' }"
    ></v-img>
  </div>
</template>
<script>
import project from '@/project'
import { mapState } from 'vuex'

export default {
  name: 'Logo',
  props: {
    color: {
      type: String,
      default: 'white'
    },
    maxHeight: {
      type: String,
      default: '35px'
    },
    isLinkable: Boolean,
    width: Number
  },
  data () {
    return {
      project
    }
  },
  computed: {
    ...mapState('application', ['isMobile', 'isLargeScreen']),
    getSrc () {
      /* ${this.project.project === 'darkfans' ? 'png' : 'svg'} */
      return require(`@/views/landing/specialCampaign/resources/logo-${this.project.project}-${this.color}.svg`)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../_styles";

.img {
  width: 166px;
  height: 100%;
}

@media (max-width: 700px) {
  .img {
    width: 126px;
  }
}

.img :hover {
  cursor: pointer;
}
</style>
