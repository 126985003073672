<template>
  <v-row no-gutters align="center" class="mb-2">
    <v-col v-if="post.repostedBy"
      cols="12">
      <div class="small-text lightest-text mb-1">
        <v-icon small>cached</v-icon>
        {{ $t('posts.reposted_by', { name: `@${post.repostedBy}` })}}
      </div>
    </v-col>
    <v-row no-gutters align="center">
      <v-col class="flex-grow">
        <UserAvatar :user="post.author">
          <template #name>

            <span class="semibold mr-3 " style="align-items: center; display: flex;"
                  :class="isDarkTheme">{{ author.displayName.length > 50 ? (isMobile ? (author.displayName.substring(0,40) + '...') : (author.displayName.substring(0,46) + '...')) : author.displayName }}
            <img v-if="author.trustedProfile" src="/images/check5.svg" class="h-4 w-4 ml-2" alt=""/></span>
            <span class="roboto"
                  :class="isDarkTheme">{{ `@${author.username}` }}</span>

          </template>

          <template #username>
            <span v-if="isProgrammed"
                  :class="isDarkTheme">
              <strong>{{ $t('posts.programmed') }}</strong>
            </span>
            <span class="roboto small-text italic"
                  :class="isDarkTheme">
              {{ $datetime(post.publishedAt) }}
            </span>
            <span v-if="showApprovalStatus">
              <ApprovalStatus :post="post" />
            </span>
          </template>
        </UserAvatar>
      </v-col>
      <v-col cols="auto">
        <btn-video-call :user="post.author"/>
      </v-col>
      <v-col cols="auto">
        <PaymentButton v-if="!isAuthor && !author.subscribed"
          small subscription
          :resource="author">
        </PaymentButton>
      </v-col>
      <v-col cols="auto">
        <v-btn v-if="isAuthor"
          icon
          @click="$emit('edit')">
          <v-icon color="primary">edit</v-icon>
        </v-btn>
        <ReportMenu
          copy-link
          :id="post.id"
          resource="posts"
          :item-link="link"
          :remove-item="isAuthor"
          :report-item="!isAuthor"
          @remove-item="onRemoveItem">
          <v-list-item v-if="videoResources.length"
            :href="videoResources[0]"
            target="_blank">
            <v-list-item-content>
              {{ $t('actions.download') }}
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>cloud_download</v-icon>
            </v-list-item-action>
          </v-list-item>
        </ReportMenu>
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { PostActions } from '@/store'
import { saveAs } from 'file-saver'
import UserAvatar from '@/components/users/UserAvatar'
import ReportMenu from '@/components/custom/ReportMenu'
import PaymentButton from '@/components/payments/PaymentButton'
import ApprovalStatus from './ApprovalStatus.vue'

export default {
  name: 'PostAuthor',
  components: { ApprovalStatus, PaymentButton, UserAvatar, ReportMenu },
  props: {
    post: Object
  },

  computed: {
    ...mapState('preferences', ['darkTheme']),
    ...mapState('application', ['isMobile']),
    ...mapState('profile', ['currentUser', 'isAdmin', 'isAccountManager', 'isContentManager', 'isController']),
    author () {
      return this.post.author || {}
    },
    showApprovalStatus () {
      return this.isAuthor
    },
    isAuthor () {
      return this.author.username === this.currentUser.username
    },
    link () {
      const url = this.$router.resolve({ name: 'post', params: { uuid: this.post.id } })
      const relativePath = url.href
      const origin = window.location.origin
      return this.fullPath || `${origin}${relativePath}`
    },
    isDarkTheme () {
      return {
        'white-text': this.darkTheme && !this.post.recommended,
        'black--text': !this.darkTheme
      }
    },
    isProgrammed () {
      const date = new Date(this.post.publishedAt)
      const now = new Date()
      return now.getTime() < date.getTime()
    },
    videoResources () {
      if (!this.isAdmin && !this.isAccountManager && !this.isContentManager && !this.isController) {
        return []
      }
      const urls = this.post.resources
        .map(r => r.downloadUrl)
        .filter(url => !!url)
      return urls
    }
  },
  methods: {
    async onRemoveItem () {
      const { data, error } = await this.$store.dispatch(PostActions.Delete, this.post.id)
      this.showNotification(data, error)
    },

    showNotification (data, error) {
      const notification = {}
      if (error) {
        notification.message = this.$t(`remote_errors.${data.errorCode}`)
        notification.error = true
      } else {
        notification.message = this.$t('snackbar.delete_success')
      }
      this.$eventBus.publish('notification', notification)
    },
    downloadResources () {
      const [url] = this.videoResources
      saveAs(url, `${this.post.username}-post-${this.post.id}.mp4`)
    }
  },
  mounted() {
    //this.post.author.videochatEnabled = this.post.videochatEnabled
  }
}
</script>
