<template>
  <div>
    <InfluencerRecommendations/>
  </div>
</template>
<script>
import InfluencerRecommendations from '@/components/widgets/InfluencerRecommendations'

export default {
  name: 'Recommendations',
  components: {InfluencerRecommendations}
}
</script>
