<template>
  <div class="py-4">
    <div v-for="[key, value] in itemsInfo" :key="key">
      <div class="display-flex py-1">
        <v-icon color="success ">check_box</v-icon>
        <i18n v-if="value.includes('link')" class="pl-1" :path="`withdraws.withdraw_methods.${selectedMethod}.info.${key}`"
              tag="span">
          <a place="link" :href="linkBank" target="_blank">{{ nameBank }}</a>
        </i18n>
        <span v-else>
          {{ value }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
const methodsLink = {
  cosmo: {
    link: 'https://www.cosmopayment.com/register',
    name: 'Cosmopayment'
  },
  btc: {
    link: 'https://www.coinbase.com/signup',
    name: 'Coinbase'
  },
  paxum: {
    link: 'https://portal.paxum.com/register',
    name: 'Paxum'
  },
  payplume: {
    link: 'https://payplume.com/',
    name: 'PayPlume'
  },
  payplume_ach: {
    link: 'https://payplume.com/',
    name: 'PayPlume (ACH)'
  }
}
export default {
  props: {
    selectedMethod: {
      type: String
    }
  },
  computed: {
    linkBank() {
      return methodsLink[this.selectedMethod].link
    },
    nameBank() {
      return methodsLink[this.selectedMethod].name
    },
    itemsInfo() {
      return Object.entries(this.$t(`withdraws.withdraw_methods.${this.selectedMethod}.info`))
    }
  }
}
</script>
