<template>
  <v-app>
    <v-content>
      <router-view :username="username" :key="$route.fullPath"/>
    </v-content>
    <cookies-modal></cookies-modal>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FullPage',
  props: {
    username: String
  },
  computed: {
    ...mapState('preferences', ['darkTheme'])
  },
  mounted () {
    this.$vuetify.theme.dark = this.darkTheme
  }
}
</script>

<style scoped>

</style>
