const ProjectConfig = {
  loverfans: {
    project: 'loverfans',
    acronym: 'LF',
    primary_color: '#DF3856',
    background_color: '#2d3237',
    promotion: true,
    isDevelop: false,
    resources: {
      component: 'LoverfansBanner',
      downloadResourceComponent: 'LoverfansDownloadResourceView',
      promotion_resources: true,
      normal_resources: true,
      downloadableResource: true
    },
    enable_resources: true,
    show_footer: true,
    show_imprint: 'https://loverfans.com/loverfans_imprint',
    enable_themes: true,
    enable_scat_modal: true,
    twitter_enabled: true,
    twitter: 'loverfans',
    instagram: 'loverfans',
    multicurrency: false,
    payments: {
      provider: 'commercegate',
      backup: 'inetcash'
    },
    locales: {
      project: 'Loverfans',
      project_upper: 'LOVERFANS',
      site: 'loverfans.com',
      contact_email: 'soporte@loverfans.com',
      contact_email_creator: 'stars@loverfans.com',
      contact_email_financial: 'financial@artemisa3000.com',
      info_email: 'info@loverfans.com',
      payments_imprint: {
        company: 'Inet-Cash GmbH',
        address: 'Am Stadtbad 14',
        city: '59065 Hamm',
        country: 'Germany',
        contact: {
          phone: 'Tel: +49 (2381) 338-9860',
          fax: 'Fax: +49 (2381) 338-9861',
          email: 'Email: support@inet-cash.com'
        }
      }
    },
    yoti: {
      url: 'https://api.yoti.com/idverify/v1/web/index.html'
    },
    google: {
      oauth: {
        enabled: false,
        client_id:
          '6243420342-deqr1irso0eiou0t8jh39goctgaldceq.apps.googleusercontent.com'
      },
      analytics: {
        id: 'G-VRCGT887VF'
      }
    },
    gtm: 'GTM-KLTN4DF',
    analytics: {
      ga: 'G-VRCGT887VF'
    },
    showFooterImages: false
  },
  scatbook: {
    project: 'scatbook',
    acronym: 'DF',
    primary_color: '#B0601E',
    background_color: '#2d3237',
    promotion: false,
    isDevelop: false,
    resources: {
      component: 'ScatbookBanner',
      downloadResourceComponent: 'ScatbookDownloadResourceView',
      promotion_resources: true,
      normal_resources: true,
      downloadableResource: true
    },
    enable_resources: true,
    show_footer: false,
    show_imprint: 'https://scatbook.com/scatbook_imprint',
    enable_themes: false,
    twitter_enabled: true,
    twitter: 'SCATBOOKSOCIAL',
    instagram: 'scatbook_official',
    theme: 'dark',
    multicurrency: false,
    payments: {
      darkfans_redirect: true,
      provider: 'inetcash'
    },
    locales: {
      project: 'Scatbook',
      project_upper: 'SCATBOOK',
      site: 'scatbook.com',
      contact_email: 'soporte@scatbook.com',
      contact_email_creator: 'stars@scatbook.com',
      contact_email_financial: 'financial@artemisa3000.com',
      info_email: 'info@scatbook.com',
      payments_imprint: {
        company: 'Inet-Cash GmbH',
        address: 'Am Stadtbad 14',
        city: '59065 Hamm',
        country: 'Germany',
        contact: {
          phone: 'Tel: +49 (2381) 338-9860',
          fax: 'Fax: +49 (2381) 338-9861',
          email: 'Email: support@inet-cash.com'
        }
      }
    },
    yoti: {
      url: 'https://api.yoti.com/idverify/v1/web/index.html'
    },
    google: {
      oauth: {
        enabled: false,
        client_id:
          '929669038588-sirbfabk6gkorjoukmf47epuqca9ten6.apps.googleusercontent.com'
      },
      analytics: {
        id: 'G-NS8GX1QVXH'
      }
    },
    gtm: 'GTM-MMHXVCP',
    analytics: {
      ga: 'G-NS8GX1QVXH'
    },
    showFooterImages: true
  },
  darkfans: {
    project: 'darkfans',
    acronym: 'DF',
    primary_color: '#FF0036',
    background_color: '#292929',
    promotion: false,
    isDevelop: false,
    resources: {
      component: 'DarkfansBanner',
      downloadResourceComponent: 'DarkfansDownloadResourceView',
      promotion_resources: true,
      normal_resources: true,
      downloadableResource: true
    },
    enable_resources: true,
    show_footer: false,
    enable_themes: false,
    landing_widget: 'SlaveWidget',
    twitter_enabled: true,
    twitter: 'Darkfans_com',
    instagram: 'darkfans_official',
    theme: 'dark',
    multicurrency: false,
    payments: {
      provider: 'inetcash'
    },
    locales: {
      project: 'Darkfans',
      project_upper: 'DARKFANS',
      site: 'darkfans.com',
      contact_email: 'soporte@darkfans.com',
      contact_email_creator: 'stars@darkfans.com',
      contact_email_financial: 'financial@artemisa3000.com',
      info_email: 'info@darkfans.com'
    },
    yoti: {
      url: 'https://api.yoti.com/idverify/v1/web/index.html'
    },
    google: {
      oauth: {
        enabled: false,
        client_id:
          '929669038588-sirbfabk6gkorjoukmf47epuqca9ten6.apps.googleusercontent.com'
      },
      analytics: {
        id: 'G-M8PCQM5VV4'
      }
    },
    gtm: 'GTM-PL837WZ',
    analytics: {
      ga: 'G-M8PCQM5VV4'
    },
    showFooterImages: true
  },
}

if(!process.env.VUE_APP_FANS_PLATFORM || !process.env.VUE_APP_FANS_ENVIRONMENT) {
  console.error("SETEA EL ENTORNO CON LA VARIABLE VUE_APP_FANS_PLATFORM!! ES -> O B L I G A T O R I O")
  console.error("SETEA EL ENTORNO CON LA VARIABLE VUE_APP_FANS_ENVIRONMENT!! ES -> O B L I G A T O R I O")
  alert("SETEA EL ENTORNO CON LA VARIABLE VUE_APP_FANS_PLATFORM!! ES -> O B L I G A T O R I O")
  alert("SETEA EL ENTORNO CON LA VARIABLE VUE_APP_FANS_ENVIRONMENT!! ES -> O B L I G A T O R I O")
}
module.exports = ProjectConfig[process.env.VUE_APP_FANS_PLATFORM]
