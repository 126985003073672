<template>
  <VCheckbox
    v-bind="attrs"
    :label="label"
    :rules="rules"
    :color="color"
  >
    <template #label>
      <slot/>
    </template>
  </VCheckbox>
</template>
<script>
export default {
  name: "FormCheckbox",
  props: {
    label: {type: String},
    rules: [],
    color: {type: String, default: 'primary'},
  },
  setup(props, {attrs}) {
    return {props, attrs}
  }
}
</script>
