<template>
  <div class="flex w-full h-12  justify-between px-2 py-3 pb-1 items-center">
    <p class="text-md ">{{ $t('suggest_account') }}</p>
    <c-btn icon @click="newRecommended">
      <Icon icon="reload" size="4" class="fill-secondary-500 hover:fill-primary"/>
    </c-btn>
  </div>
</template>

<script>

import Icon from "@/design/Icon.vue";
import {storeToRefs} from "pinia";
import {useRecommendedStore} from "@/store/recommended";

export default {
  name: "RecommendedTitle",
  components: {Icon},
  setup(props, {emit, attrs, slots}) {

    const {existReload} = storeToRefs(useRecommendedStore())

    const newRecommended = async () => {
      await useRecommendedStore().reload()
      emit('reload')
    }
    return {props, emit, existReload, newRecommended}
  }
}


</script>
