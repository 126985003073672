<template>
  <component :is="isHeader" @register="register">
    <slot/>
  </component>
</template>
<script>
import AppBarMenu from '@/components/layouts/AppBarMenu'
import Logo from '@/views/landing/specialCampaign/components/Logo'
import { mapState } from 'vuex'
import PromoHeader from '@/components/custom/newDesign/headers/PromoHeader.vue'
import CampaignHeader from '@/components/custom/newDesign/headers/CampaignHeader.vue'
import config from '@/project'

export default {
  name: 'cd-app-header',
  components: {
    CampaignHeader,
    PromoHeader,
    Logo,
    AppBarMenu
  },

  props: {
    type: {
      type: String, // type: main,index,promo,campaign
      required: true,
      default: 'main'
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('preferences', ['darkTheme']),
    isHeader () {
      if (this.isPromo) {
        return PromoHeader
      }
      if (this.isCampaign) {
        return CampaignHeader
      }
      return ''
    },
    isFixed () {
      /* if (this.isMobile) {
        return this.$route.path !== '/special/nextDoor/stories'
      } */
      return true
    },
    isPromo () {
      return this.type === 'promo'
    },
    isCampaign () {
      return this.type === 'campaign'
    },
    isIndex () {
      return this.type === 'index'
    },
    logoClass () {
      return {
        center: !this.isMobile,
        'ml-12': this.isMobile
      }
    }
  },
  data () {
    return {}
  },
  methods: {
    register () {
      this.$emit('register')
    },
    goToHome () {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.special-header {
  border-bottom: solid 1px #C7C7C7;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 63px;

  &.dark {
    background-color: #2d3237 !important;
  }

  &.fixed {
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 100;
    width: -webkit-fill-available;
    width: -moz-available;
  }
}
</style>
