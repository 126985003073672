import { render, staticRenderFns } from "./ChatRow.vue?vue&type=template&id=0c07bfff"
import script from "./ChatRow.vue?vue&type=script&lang=js"
export * from "./ChatRow.vue?vue&type=script&lang=js"
import style0 from "./ChatRow.vue?vue&type=style&index=0&id=0c07bfff&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports