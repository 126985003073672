import { defineStore } from "pinia";

export const useUsersStore = defineStore('users', {
    state: () => ({
        user: {},
        promotion: {},
        wallet: {},
        eurWallet: {},
        usdWallet: {},
        tags: [],
        bannedCountries: [],
        blockedUsers: [],
        trials: [],
        featuredUsers: [],
        selectedTrial: null,
        isInternalProfile: false,
        isInfluencer: false,
        isFollower: false,
        isCompliance: false,
        isAccountManager: false,
        isContentManager: false,
        isAgent: false,
        isAdmin: false,
        isController: false,
        personalDataApproved: false,
        payout: {},
        withdraw: [],
        subscriptionPrice: 0,
        yotiSessionId: '',
        recommended: [],
        following: [],
        fans: []
    }),
    actions: {
        async list(query) {
            const {data, error} = await useApi().get('/users', query)
            const currentUser = useSessionStore().$state.currentUser
            const users = data.value.data.map(item => metaInfoProfile(item, currentUser))
            return {data: users, error: error.value, metadata: data.value.metadata}
        },

        async search(username, role) {
            const {data, error} = await useApi().get('/users/search', {username, role, limit: 20})
            return {data: data.value.data, error: error.value, metadata: data.value.metadata}
        },
        async fetch(username) {
            const {data, error} = await useApi().get(`/users/${username}`)
            if (!error.value) {
                this.setUser(data.value);
                //this.user = metaInfoProfile(data.value);
            }
        },
        setUser(user) {
            const currentUser = useSessionStore().$state.currentUser;
            const updatedUser = metaInfoProfile(user, currentUser);
            this.$state = metaInfoProfile(user, currentUser);
            this.user = updatedUser;
        },
        async followingBy(query) {
            const {data, error} = await useApi().get('/users/following', query)
            return {data: data.value.data, error: error.value, metadata: data.value.metadata}
        },
        async followingList(query) {
            const {data, error} = await useApi().get('/users', query)
            const currentUser = useSessionStore().$state.currentUser
            const following = data.value.data.map(item => metaInfoProfile(item, currentUser))
            return {data: following, error: error.value, metadata: data.value.metadata}
        },
        async follow(username) {
            const {error} = await useApi().post(`users/${username}/follow`)
            await useUsersStore().fetch(username)
            return {error: error.value}
        },
        async unFollow(username) {
            const {error} = await useApi().delete(`users/${username}/follow`)
            await useUsersStore().fetch(username)
            return {error: error.value}
        },
        async blocked(username) {
            const {error} = await useApi().post('/profile/blocked_users', {username: username})
            if (!error.value) {
                Toast.success(`Se ha bloqueado correctamente al usuario ${username} `)
            } else {
                Toast.error(`Error bloqueando al usuario  ${username} `)
            }
        },
        async unBlock(username) {
            const {error} = await useApi().delete(`/profile/blocked_users/${username}`)
            if (!error.value) {
                Toast.success(`Se ha desbloqueado correctamente al usuario ${username} `)
            } else {
                Toast.error(`Error desbloqueando al usuario  ${username}`)
            }
        },
        async fansList(query) {
            const {data, error} = await useApi().get('/users', query)
            if (!error) {
                this.fans = data.value.data
            }
            return {data: data.value.data, error: error.value, metadata: data.value.metadata}
        },
        async fansBlock(query) {
            const {data, error} = await useApi().get('/profile/blocked_users')
            if (!error) {
                this.fans = data.value.data
            }
            return {data: data.value.data, error: error.value, metadata: data.value.metadata}
        },
        async updateUser(profile) {
            const {data, error} = await useApi().put('/profile/account_data', profile)
            return {data: data, error: error};
        },

    }
})

export const metaInfoProfile = (user, currentUser) => {
    if (!user) return null;

    const roleFlags = {
        isInfluencer: user.role === 'influencer',
        isFollower: user.role === 'follower',
        isCompliance: user.role === 'compliance',
        isAccountManager: user.role === 'account_manager',
        isContentManager: user.role === 'content_manager',
        isAdmin: user.role === 'admin',
        isController: user.role === 'controller',
    };
    const enabledOffers = enabledOffersAndMonth(user)
    const offers = calculateOfferPrice(enabledOffers)

    return {
        ...user,
        ...roleFlags,
        isInternalProfile: currentUser.username === user.username,
        isAgent: !!user.isAgent,
        payout: user.payout || 0,
        withdraw: user.withdraw || 0,
        personalDataApproved: !!user.personalDataApproved,
        subscriptionOffers: offers,
        subscriptionBestOffer: getBestOffer(offers, user),
    };
};
const enabledOffersAndMonth = (user) => {
    const subscriptionOffers = user.subscriptionOffers?.filter(offer => offer?.enabled) ?? [];

    if (!subscriptionOffers.some(offer => offer.months === 1)) {
        subscriptionOffers.unshift({
            basePrice: user.subscriptionPrice,
            months: 1,
            finalPrice: user.subscriptionPrice,
            enabled: true,
            discount: 0,
            type: "month",
        });
    }
    return subscriptionOffers;
};

const calculateOfferPrice = (offers) => {
    return offers.map(offer => {
        const discountAmount = (offer.basePrice * offer.discount) / 100;
        const finalPrice = offer.basePrice - discountAmount;
        const uniquePayment = finalPrice * offer.months
        return {...offer, finalPrice, uniquePayment};
    });
}

const getBestOffer = (offers, user) => {
    if (offers.length === 0) {
        return {
            basePrice: user.subscriptionPrice,
            months: 1,
            finalPrice: user.subscriptionPrice,
            enabled: true,
            discount: 0,
            type: "month"
        };
    }
    return offers.reduce((bestOffer, currentOffer) => {
        return currentOffer.months < bestOffer.months ? currentOffer : bestOffer;
    });
}
