<template>
  <div v-if="load"
       class="fixed top-0 left-0 w-screen h-screen bg-white z-50 flex items-center justify-center overflow-hidden">
    <img src="/assets/images/logo-loverFans-light.svg" class="animate-pulse w-64 h-64" alt=""/>
  </div>
</template>

<script lang="ts">

export default {
  setups() {
    const load = true
    return {load}
  }

}
</script>
