<template>
  <div class="flex  flex-col gap-4 h-48 items-center justify-center w-full">
    <p class="text-center font-semibold"> {{ $t('subscription.error.description_1') }} </p>
    <p class="text-xs text-center text-secondary-500">
<!--      <i18n-t keypath="subscription.error.description_2">
        <template #email>
          <Links email to="soporte@loverfans.com"/>
        </template>
      </i18n-t>-->
    </p>
    <Btn @click="retry">
      {{ $t('actions.retry') }}
    </Btn>
  </div>
</template>

<script>

import {onMounted} from "vue";
import Btn from "@/design/btn/Btn.vue";
import Links from "@/design/Links.vue";

export default {
  name: 'PaymentModalError',
  components: {Links, Btn},
  props: {
    buyer: {type: String},
    seller: {type: String},
    codeError: {},
    type: {type: String}
  },
  setup(props, {emit}) {
    const retry = () => {
      emit('retry')
    }

    onMounted(() => {
      const log = {
        seller: props.seller,
        buyer: props.buyer,
        type: props.type,
        error: props.codeError,
      }
      console.log('*** Payment Modal Error**', log)
      /* $logger.error('*** Payment Modal Error**', log)*/
    })
    return {props, emit, retry}
  }

}


</script>

