<template>
  <v-menu v-bind="$attrs"
          v-model="openMenu">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>more_vert</v-icon>
      </v-btn>
      <LocaleDialog v-model="openLocaleDialog"/>
    </template>
    <v-list dense
            class="app-bar-menu">
      <template v-if="!authenticated">
      </template>
      <template v-else-if="isMobile">
        <v-list-item :to="{ name: 'notifications' }">
          <v-list-item-content>
            {{ $t('application.menu.notifications') }}
          </v-list-item-content>
          <v-list-item-action>
            <v-icon :color="notificationColor">{{ notificationIcon }}</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click.stop="showLocaleDialog">
          <v-list-item-content>
            {{ $t(`langs.names.${$i18n.locale}`) }}
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>language</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
      <v-list-item :to="{ name: 'faq' }">
        <v-list-item-content>
          {{ $t('application.menu.faq') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>help</v-icon>
        </v-list-item-action>
      </v-list-item>

      <a href="/campaigns/tiktak/index.html" v-if="showTiktak">
        <v-img :src="`/images/banner_TTF_${isMobile ? 'mobile' : 'desktop'}.svg`">
          <div class="promo-text">
            <p>
              PROMO:<br v-if="isMobile">
              <b style="margin-left: 8px;">{{ tikTakName }}</b>
            </p>
          </div>
        </v-img>
      </a>
      <!--<v-img v-if="project.project === 'loverfans'" class="promo-image" :src="`/images/banner_cnd_${isMobile ? 'mobile' : 'desktop'}.svg`" @click="goTo('promo')">
        <div class="promo-text">
          <p>
            PROMO:<br v-if="isMobile">
            <b style="margin-left: 8px;">TheChickNextDoor</b>
          </p>
        </div>
      </v-img>-->
      <v-list-item v-if="isInfluencer" :to="{ name: 'tutorials' }">
        <v-list-item-content>
          {{ $t('application.menu.tutorial') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>ondemand_video</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list-item :to="{ name: 'contact' }">
        <v-list-item-content>
          {{ $t('application.menu.contact') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>email</v-icon>
        </v-list-item-action>
      </v-list-item>

      <div style="max-width: 520px;" v-if="isOnGoing">
        <a :href="`${newConfig.baseUrl}/campaigns/promo/june/${!isInfluencer ? 'index' : 'academy'}.html`">
          <v-img height="50" :src="`/images/banner-${isMobile ? 'mobile' : 'desktop'}.jpg`">
          </v-img>
        </a>
      </div>
      <v-list-item v-if="isMobile && themesEnabled"
                   @click="changeTheme">
        <v-list-item-content>
          {{ themeLabel }}
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>compare</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list-item v-if="authenticated"
                   @click.stop="logout">
        <v-list-item-content>
          {{ $t('actions.logout') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="red">remove_circle_outline</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list-item v-else @click="login()">
        <v-list-item-content>
          {{ $t('actions.login') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="red">person</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list-item class="subtitle" :to="{ name: 'conditions' }">
        <v-list-item-subtitle>
          {{ $t('application.menu.terms_conditions') }}
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item class="subtitle" :to="{ name: 'contract-conditions' }">
        <v-list-item-subtitle>
          {{ $t('application.menu.contract_conditions') }}
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item class="subtitle" :to="{ name: 'privacy' }">
        <v-list-item-subtitle>
          {{ $t('application.menu.privacy') }}
        </v-list-item-subtitle>
      </v-list-item>

      <template v-if="!isMobile">
        <v-list-item class="subtitle" :to="{ name: 'cookies' }">
          <v-list-item-subtitle>
            {{ $t('application.menu.cookies') }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item
          class="subtitle" to="/complaint_2257">
          <v-list-item-subtitle>
            USC 2257
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item
          class="subtitle" to="/dmca">
          <v-list-item-subtitle>
            {{ `DMCA Notice & Takedown Policy and Procedures` }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item
          class="subtitle" to="/compliance-policy">
          <v-list-item-subtitle>
            {{ `Complaints & Content Removal` }}
          </v-list-item-subtitle>
        </v-list-item>
        <template v-if="showExtendedFooter">
          <v-list-item class="subtitle">
            <v-list-item-subtitle class="d-flex justify-space-between">
              <span>{{ $t('application.contact_email') }}</span>
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item class="subtitle">
            <v-list-item-subtitle class="d-flex justify-space-between">
              <img :src="`/images/artemisa_${darkTheme ? 'white' : 'black'}.png`"
                   style="margin-top: 2px; margin-right: 5px; width: 200px; height: 12px"
                   v-if="project.showFooterImages"/>
              <span v-else>Artemisa 3000 Tech Solutions SL</span>
              <span>CIF: </span><img :src="`/images/cif_${darkTheme ? 'white' : 'black'}.png`"
                                     style="margin-top: 3px; margin-left: 2px; width: 80px; height: 10px"
                                     v-if="project.showFooterImages"/>
              <span v-else>B88466743</span>
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item class="subtitle">
            <v-list-item-subtitle>
              <span>{{ $t('application.company_address') }}</span>
            </v-list-item-subtitle>
          </v-list-item>
        </template>
        <v-list-item class="subtitle">
          <v-list-item-subtitle>
            <span>&copy; {{ $t('application.title') + ' ' + currentYear }}</span>
          </v-list-item-subtitle>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { PreferenceActions, SessionActions } from '@/store'
import LocaleDialog from '@/components/custom/LocaleDialog'
import project from '@/project'

const configFactory = require('@/configFactory');
const newConfig = configFactory().getConfig();
export default {
  name: 'AppBarMenu',
  components: { LocaleDialog },
  data () {
    return {
      openMenu: false,
      openLocaleDialog: false,
      currentYear: new Date().getFullYear(),
      showExtendedFooter: project.show_footer,
      imprint: project.show_imprint,
      themesEnabled: project.enable_themes,
      showTiktak: true,
      ip: null,
      project
    }
  },

  computed: {
    ...mapState('preferences', ['darkTheme']),
    ...mapState('application', ['isMobile', 'hasNotifications', 'hasMessages']),
    ...mapState('session', ['authenticated']),
    ...mapState('profile', ['currentUser', 'isInfluencer']),
    notificationIcon () {
      return this.hasNotifications ? 'notification_important' : 'notifications'
    },
    notificationColor () {
      return this.hasNotifications ? 'primary' : ''
    },
    chatIcon () {
      return this.hasMessages ? 'sms_failed' : 'chat'
    },
    chatColor () {
      return this.hasMessages ? 'primary' : ''
    },
    themeLabel () {
      return this.darkTheme ? this.$t('application.menu.light_theme') : this.$t('application.menu.dark_theme')
    },
    tikTakName () {
      if (project.project === 'loverfans') {
        return 'TikTakFans'
      }
      if (project.project === 'darkfans') {
        return 'TikTakDark'
      }
      return 'TikTakScat'
    },
    isOnGoing () {
      return this.started(new Date()) && !this.ended(new Date())
    },
  },

  methods: {
    started (today) {
      const users = this.project.campaign.users
      let activeUser = true
      if (users.length > 0) {
        activeUser = false
        const index = users.findIndex(u => u === this.currentUser.username)
        activeUser = index !== -1
      }

      // LOS MESES SON DE 0 - 11)
      const eventStarted = this.project.campaign.started
      const activeDate = (today - eventStarted) > 0
      return activeDate && activeUser
    },
    ended (today) {
      // LOS MESES SON DE 0 - 11)
      var eventEnded = this.project.campaign.endDate
      return (eventEnded - today) < 0
    },
    /*currentCoordinates () {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => resolve(coords),
          // Reject if the user doesn't
          // allow accessing their location.
          error => reject(error)
        )
      })
    },
    async addressByCoordinates ({ latitude, longitude }) {
      const ENDPOINT = 'https://nominatim.openstreetmap.org/reverse'
      const FORMAT = 'jsonv2'
      const { data } = await axios.get(ENDPOINT, {
        params: {
          format: FORMAT,
          lat: latitude,
          lon: longitude
        }
      })
      this.showTiktak = data.address.country_code !== 'es'
    },
    async currentAddress () {
      const coordinates = await this.currentCoordinates()
      await this.addressByCoordinates(coordinates)
    },*/
    /*async getLocation () {
      fetch('https://api.ipify.org?format=json')
        .then(x => x.json())
        .then(({ ip }) => {
          this.ip = ip
          fetch(`http://ip-api.com/json/${this.ip}`)
            .then(response => response.json())
            .then((response) => {
              this.showTiktak = response.countryCode !== 'ES'
            })
        });
    },*/
    showLocaleDialog () {
      this.openLocaleDialog = true
      this.openMenu = false
    },
    changeTheme () {
      this.$vuetify.theme.dark = !this.darkTheme
      this.$store.dispatch(PreferenceActions.UpdateDarkTheme, this.$vuetify.theme.dark)
      /*       this.$store.dispatch(PreferenceActions.UpdateDarkTheme, !this.darkTheme) */
    },
    logout () {
      this.$store.dispatch(SessionActions.Logout)
    },
    login () {
      sessionStorage.setItem('origin', this.$router.currentRoute.fullPath)
      this.$router.push('/login')
    },
    goTo () {
      this.$router.push({ name: 'specialPromo' })
    }
  }
}
</script>
<style lang="scss" scoped>
a:hover {
  cursor: pointer;
}

.promo-image :hover {
  cursor: pointer;
}

.v-responsive__content {
  height: 100% !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.promo-text {
  min-height: 100%;
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
  color: white;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
}
</style>
