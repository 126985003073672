<template>
  <div class="rounded-md border shadow-md">
    <div class="relative clickable"
         @click="doRequest">
      <UserCardBanner cClass="rounded-b-md h-18" :user="user">
        <template #options>
          <Dropdown class="absolute top-1 right-2" :items="items" @select="select">
            <template #activator="{props}">
              <Icon icon="options" :size="5" v-bind="props"
                    class="cursor-pointer fill-secondary-100 hover:fill-primary-500"/>
            </template>
          </Dropdown>
        </template>
        <template #action>
<!--          <SubsBtnIcon :user="user"/>-->
        </template>
      </UserCardBanner>
    </div>
    <SharedModal type="user" :user="user" v-model="modalShared"/>
  </div>
</template>

<script>
import UserCardBanner from "@/2_0/components/user/card/UserCardBanner.vue";
import Dropdown from "@/design/Dropdown.vue";
import Icon from "@/design/Icon.vue";
import SubsBtnIcon from "@/2_0/components/subs/btn/SubsBtnIcon.vue";
import SharedModal from "@/2_0/components/shared/SharedModal.vue";
import {computed, getCurrentInstance, ref} from "vue";
import {useUsersStore} from "@/store/users";
import {useRecommendedStore} from "@/store/recommended";

export default {
  name: "RecommendedCardUser",
  components: {SharedModal, UserCardBanner, Dropdown, Icon, SubsBtnIcon},
  props: {
    user: {}
  },
  setup(props, {slot}) {
    const modalShared = ref(false)
    const {$i18n, $router} = getCurrentInstance().proxy;

    const items = computed(() => {
      let aux = [{
        label: $i18n.t('actions.share.label'),
        key: 'shared'
      },
        {
          label: $i18n.t('actions.remove_suggested'),
          key: 'remove'
        }]
      if (props.user?.username !== 'loverfans' && props.user?.username !== 'darkfans' && props.user?.username !== 'scatbook') {
        if (props.user?.following) {
          aux.push({
            label: $i18n.t('actions.unfollow'),
            key: 'unfollow'
          })
        } else {
          aux.push({
            label: $i18n.t('actions.follow'),
            key: 'follow'
          })
        }
      }
      return aux
    })

    const follow = () => {
      const {error} = useUsersStore().follow(props.user.username)
      if (!error) {
        props.user.following = true
      }
    }
    const unfollow = () => {
      const {error} = useUsersStore().unFollow(props.user.username)
      if (!error) {
        props.user.following = false
      }
    }
    const select = (item) => {
      if (item.key === 'shared') {
        modalShared.value = !modalShared.value
      }
      if (item.key === 'remove') {
        useRecommendedStore().remove(props.user.username)
      }
      if (item.key === 'follow') {
        unfollow()
      }
      if (item.key === 'unfollow') {
        follow()
      }
    }

    const doRequest = async (event) => {
      $router.push(`/${props.user.username}/media`)
    };
    return {props, slot, modalShared, items, follow, unfollow, select, doRequest}
  }
}


</script>
<style lang="scss" scoped>

.clickable:hover {
  cursor: pointer;
}

</style>
