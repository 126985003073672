<template>
  <SubsModal v-if="!isPending" :offer="bestOffer" :user="user">
    <template #activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <BtnIconCircle
          icon="subs"
          actions
          size="xs"/>
      </v-btn>
    </template>
  </SubsModal>
  <BtnIconCircle
    v-else
    @click.stop="()=>{}"
    icon="rotate"
    size="xs"/>
</template>

<script>

import BtnIconCircle from "@/design/btn/BtnIconCircle.vue";
import SubsModal from "@/2_0/components/subs/modal/SubsModal.vue";
import {computed} from "vue";
import {GTM_EVENTS} from "@/domain/gtm-event-types";

export default {
  name: "SubsBtnIcon",
  components: {BtnIconCircle, SubsModal},
  props: {
    user: {}
  },
  setup(props) {
    const bestOffer = computed(() => props.user.subscriptionBestOffer)

    const isPending = computed(() => {
      return props.user.paymentStatus === "processing"
    })
    const addGtm = () => {
      //addGtmEvent(GTM_EVENTS.SUBSCRIPTION_STEP1)
    }
    return {props, bestOffer, isPending, addGtm}
  }
}

</script>

<style lang="scss" scoped>

</style>
