<template>
  <section
    class="app-fullpage">
    <NotFoundCover v-if="isNotFound || isBlocked" :username="username"/>
    <BlockedCover v-else-if="isBlockedCountry" :user="user"/>
    <NormalCover v-else :user="user" :edittingMode.sync="edittingMode"/>
    <v-card-text v-if="isNotFound">
      <span class="title-text-2">{{ $t('users.not_found') }}</span>
    </v-card-text>
    <v-card-text v-else-if="isBlocked">
      <span class="title-text-2">{{ $t('users.blocked_description') }}</span>
    </v-card-text>
    <template v-else>
      <v-tabs v-if="showSections"
              grow
              background-color="transparent"
              class="profile-sections border-bottom"
              :show-arrows="true"
              v-model="selectedTab">
        <v-tab
          v-for="item in availableSections"
          :key="item.key"
          :to="{ name: 'profile_section', params: { username: username, tab: item.key }}"
          replace>
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <v-row v-if="isBlockedCountry"
             align="center" justify="center">
        <v-col cols="auto">
          <h1 class="title-text">{{ $t('blocked_countries.content_blocked') }}</h1>
        </v-col>
      </v-row>
      <keep-alive>
        <component v-if="!isBlockedCountry && selectedComponent && showSections"
                   :is="selectedComponent.component" :username="username" :user="user" :isProfile="false"></component>
      </keep-alive>
    </template>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { UserActions, PreferenceActions } from '@/store'
import NotFoundCover from '@/components/users/covers/NotFoundCover'
import BlockedCover from '@/components/users/covers/BlockedCover'
import NormalCover from '@/components/users/covers/NormalCover'
import UserPublications from '@/components/users/UserPublications'
import UserSubscribers from '@/components/users/UserSubscribers'
import UserFollowers from '@/components/users/UserFollowers'
import UserFollowing from '@/components/users/UserFollowing'
import UserMarket from '@/components/users/UserMarket'
import UserSubscriptions from '@/components/users/UserSubscriptions'
import UserPurchases from '@/components/users/UserPurchases'
import cookies from '@/cookies'
import dialog from '@/components/mixins/dialog'
import UserAvatar from '@/components/users/UserAvatar'
import UserApi from '@/api/UserApi'
import config from '@/project'

const configFactory = require('@/configFactory');
const newConfig = configFactory().getConfig();

export default {
  name: 'ProfileView',
  mixins: [dialog],
  components: {
    UserAvatar,
    NotFoundCover,
    BlockedCover,
    NormalCover,
    UserPublications,
    UserSubscribers,
    UserFollowers,
    UserFollowing,
    UserMarket,
    UserSubscriptions,
    UserPurchases
  },
  props: {
    username: String,
    tab: String
  },
  metaInfo () {
    const name = this.user ? this.user.displayName : this.username
    return {
      title: this.$t('meta.users.title', { name }),
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.user ? this.user.description : this.$t('meta.users.description', { name })
        },
        {
          property: 'og:title',
          content: this.$t('meta.users.title', { name })
        },
        {
          property: 'og:site_name',
          content: this.$t('meta.users.title', { name })
        },
        {
          property: 'og:description',
          content: this.user.description || this.$t('meta.users.description', { name })
        },
        {
          property: 'og:type',
          content: 'profile'
        },
        {
          property: 'og:url',
          content: newConfig.baseUrl + '/' + this.username
        }
      ]
    }
  },

  data () {
    return {
      lastFetch: null,
      selectedTab: null,
      loadingPage: false,
      user: {},
      edittingMode: false,
      notifyScat: !!config.enable_scat_modal
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isAdmin', 'isAccountManager', 'isInfluencer', 'isFollower']),
    ...mapState('application', ['isMobile', 'tags']),
    ...mapState('session', ['authenticated']),
    isNotFound () {
      return this.user ? this.user.errorCode === 'not_found' : true
    },
    isBlocked () {
      return !this.isNotFound && this.user.blocked
    },
    isPrivateView () {
      return this.isCurrentUser && (this.edittingMode || this.isFollower)
    },
    isCurrentUser () {
      return this.authenticated && this.username.toLowerCase() === this.currentUser.username.toLowerCase()
    },

    isBlockedCountry () {
      return !this.isAdmin && !this.isAccountManager && !this.isCurrentUser && this.user.blockedCountry
    },

    sections () {
      return [
        {
          name: this.$t('user_profile.sections.publications'),
          key: 'posts',
          component: 'UserPublications',
          show: true
        },
        {
          name: this.$t('user_profile.sections.fans'),
          key: 'fans',
          component: 'UserSubscribers',
          show: this.isPrivateView && this.isInfluencer
        },
        {
          name: this.$t('user_profile.sections.followers'),
          key: 'followers',
          component: 'UserFollowers',
          show: this.isPrivateView && this.isInfluencer
        },
        {
          name: this.$t('user_profile.sections.following'),
          key: 'following',
          component: 'UserFollowing',
          show: this.isPrivateView
        },
        {
          name: this.$t('user_profile.sections.market'),
          key: 'shop',
          component: 'UserMarket',
          show: this.isUserInfluencer
        },
        {
          name: this.$t('user_profile.sections.subscriptions'),
          key: 'subscriptions',
          component: 'UserSubscriptions',
          show: this.isPrivateView
        },
        {
          name: this.$t('user_profile.sections.purchases'),
          key: 'purchases',
          component: 'UserPurchases',
          show: this.isPrivateView
        }
      ]
    },
    isUserInfluencer () {
      return this.user.role === 'influencer'
    },
    showSections () {
      return this.isCurrentUser || this.isUserInfluencer
    },
    availableSections () {
      return this.sections.filter(item => !this.loadingPage && item.show)
    },

    selectedComponent () {
      const selected = this.tab || 'posts'
      return this.availableSections.find(section => {
        const keys = [section.key].flat()
        return keys.includes(selected)
      })
    }
  },

  methods: {
    async reload () {
      const CacheWindow = 10 * 60 * 1000
      const now = new Date().getTime() - CacheWindow
      this.loadingPage = true
      const force = this.lastFetch < now
      if (this.isCurrentUser) {
        this.user = this.currentUser
      } else {
        this.user = await this.$store.dispatch(UserActions.Fetch, {
          username: this.username,
          force: true
        })
      }
      this.loadingPage = false
      if (force) {
        this.lastFetch = new Date().getTime()
      }
      this.edittingMode = this.isInfluencer

      if ((this.$route.query.utm_source === 'twitter' || this.$route.query.utm_source === 'scatbook') && this.user.scatProfile) {
        let catCookies = cookies.get('categories-prefs')
        cookies.set('categories-prefs', catCookies ? catCookies.concat(',scat') : 'scat')
        catCookies = cookies.get('categories-prefs')
        const categories = []
        const tags = this.tags.filter(tag => catCookies.split(',').includes(tag.name)).map(tag => ({
          ...tag,
          text: this.$t(`tags.${tag.name}`)
        }))
        tags.forEach(tag => categories.push(tag))
        this.$store.dispatch(PreferenceActions.UpdateTagPrefs, true)
        this.$store.dispatch(PreferenceActions.UpdateCategory, categories)
      }
    }
  },
  activated () {
    this.reload()
  },
  async beforeMount() {
    if (!this.authenticated && config.project === 'loverfans') {
      const { data } = await UserApi.find(this.username)
      data.tags.forEach((tag) => {
        if (tag.name === 'scat') {
          this.$router.push('home');
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .user-profile {
  color: white !important;
  position: relative;
  height: 262px;
  padding: 20px;

  .profile-actions {
    position: absolute;
    text-align: right;
    top: 22px;
    right: 22px;
  }

  .user-description {
    align-self: flex-end;
  }
}

.row .star-info {
  flex-wrap: nowrap;
  white-space: nowrap;
}

</style>
