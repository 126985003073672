<template>
  <div class="w-80 pl-1">
    <RecommendedTitle @reload="reload"/>
    <RecommendedSkeleton v-if="loading"/>
    <div v-if="recommended.length > 0">
      <div v-for="(item) in currentPageItems" :key="item.id">
        <RecommendedCardUser class="mx-1 mobile:mx-2 my-2" :user="item"/>
      </div>
      <div class="block mt-4 flex justify-between items-center">
        <div class="w-5 h-5">
          <Icon icon="arrow-left" size="4"
                v-if="limitInit"
                @click="prevPage"
                class=" cursor-pointer fill-gray-500 hover:fill-primary"/>
        </div>
        <div class="flex justify-center items-center">
          <div class="p-0 m-0 w-4" v-for="index in pageCount" :key="index">
            <div class="rounded-full w-1 h-1 bg-gray-500 cursor-pointer"
                 :class="currentPageClass(index)"
                 @click="goToPage(index)"/>
          </div>
        </div>
        <Icon icon="publicar" size="4" v-if="limitNext" class="cursor-pointer fill-primary"
              @click="navigateDiscover"/>
        <Icon icon="arrow-right" size="4" v-else @click="nextPage"
              class="cursor-pointer fill-gray-500 hover:fill-primary"/>
      </div>
    </div>
  </div>
</template>

<script>

import {storeToRefs} from "pinia";
import {computed, getCurrentInstance, ref} from "vue";
import {useRecommendedStore} from '@/store/recommended'
import Icon from "@/design/Icon.vue";
import RecommendedCardUser from "@/components/widgets/recommended/RecommendedCardUser.vue";
import RecommendedSkeleton from "@/components/widgets/recommended/RecommendedSkeleton.vue";
import RecommendedTitle from "@/components/widgets/recommended/RecommendedTitle.vue";

export default {
  name: "RecommendedCarousel",
  components: {RecommendedTitle, RecommendedSkeleton, RecommendedCardUser, Icon},
  setup(props, {emit}) {
    const {recommended, loading} = storeToRefs(useRecommendedStore())
    const {$router} = getCurrentInstance().proxy

    const itemsPerPage = ref(4)
    const currentPage = ref(0);

    const paginatedItems = computed(() => {
      const pages = [];
      for (let i = 0; i < recommended.value.length; i += itemsPerPage.value) {
        pages.push(recommended.value.slice(i, i + itemsPerPage.value));
      }
      return pages;
    })

    const currentPageItems = computed(() => paginatedItems.value[currentPage.value] || []);

    const pageCount = computed(() => {
      return Math.ceil(recommended.value.length / itemsPerPage.value);
    });

    const currentPageClass = (index) => {
      return {
        'w-2': index === currentPage.value + 1,
        'h-2': index === currentPage.value + 1,
        'bg-primary-500': index === currentPage.value + 1
      }
    }

    const limitNext = computed(() => {
      return paginatedItems.value.length - 1 === currentPage.value;
    });

    const limitInit = computed(() => {
      return currentPage.value !== 0;
    });

    const nextPage = () => {
      currentPage.value = (currentPage.value + 1) % pageCount.value;
    };

    const prevPage = () => {
      currentPage.value = (currentPage.value - 1 + pageCount.value) % pageCount.value;
    };

    const goToPage = (index) => {
      currentPage.value = index - 1;
    };

    const reload = async () => {
      currentPage.value = 0
      //await useRecommendedStore().reload()
    }
    const navigateDiscover = () => {
      $router.push('/stars/discover')
    }
    return {
      props,
      emit,
      recommended,
      loading,
      itemsPerPage,
      currentPage,
      pageCount,
      paginatedItems,
      currentPageItems,
      currentPageClass,
      limitNext,
      limitInit,
      nextPage,
      prevPage,
      goToPage,
      reload,
      navigateDiscover
    }
  }
}


</script>



