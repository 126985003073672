<template>
  <div class="relative">
    <div class="absolute flex items-center bottom-0 w-full ">
      <div
        :class="cClass"
        class="shadow-inner inset-0 w-full h-14 bg-gradient-to-b from-transparent to-secondary-900"></div>
    </div>
    <div class="h-28 w-full rounded-t-md bg-cover bg-white"
         :class="cClass"
         :style="{ backgroundImage: 'url(' + src + ')' }"
    >
      <UserAvatar :user="user" size="md" class="absolute top-3 left-2"/>
      <div class="absolute top-2 right-2">
        <slot name="options"/>
      </div>
      <div class="absolute top-16 left-16">
        <slot name="action"/>
      </div>
      <div class="absolute top-14 left-24 pl-2">
        <UserNameLink display-name
                      width-display-name="md"
                      width-display-username="sm"
                      color-display-name="white"
                      color-user-name="white"
                      trusted-display-name
                      :user="user"/>
      </div>
    </div>
  </div>
</template>

<script>

import UserAvatar from "@/2_0/components/user/UserAvatar.vue";
import UserNameLink from "@/2_0/components/user/UserNameLink.vue";
import {computed} from "vue";

export default {
  name: "UserCardBanner",
  components: {UserAvatar, UserNameLink},
  props: {
    user: {},
    cClass: {type: String, default: ""},
  },
  setup(props, {slot}) {
    const itemsDropdownActive = [{
      label: 'Ver perfil',
    }, {
      label: 'Compartir perfil',
    },
      {
        label: 'Añadir nota',
      },
      {
        label: 'Bloquear perfil',
      }]
    const itemsDropdownBlocked = [{
      label: 'Ver perfil',
    }, {
      label: 'Compartir perfil',
    },
      /*  {
          label: 'Añadir nota',
        },*/
    ]

    const src = computed(() => {
      if (props.user && props.user.avatarS3Route) {
        return props.user.avatarS3Route
      }
      return '/assets/images/banner-mobile.svg'
    })

    return {props, src}
  }
}

</script>
