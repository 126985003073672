<template>
  <div :class="`rounded-${rounded} shimmer h-${h} w-${w} `"></div>
</template>
<script lang="ts">
export default {
  name: 'SpinnerSkeleton',
  props: {
    w: {type: String, default: 'full'},
    h: {type: String, default: '12'},
    rounded: {type: 'lg' | 'xl' | 'full', default: 'lg'},
  }
}
</script>

<style scoped>

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.shimmer {
  background: linear-gradient(to right, #f3f3f3 8%, #e1e1e1 18%, #ececec 33%);
  background-size: 1000px 104px;
  animation: shimmer 4s infinite linear;
}
body.dark .shimmer {
  background: linear-gradient(to right, #4f4f4f 8%, #656565 18%, #575757 33%);
}

</style>
