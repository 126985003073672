import {useApplicationStore} from "@/store/application_2";

export const useAmount = () => {
    let {vat, exes} = useApplicationStore().masterData.billing
    const taxesAndFees = (price, vat) => {
        if (!price || price <= 0) return 0;
        const expensesAmount = Math.ceil(price * exes / 100);
        const vatAmount = Math.ceil((price + expensesAmount) * vat / 100)
        const taxes = expensesAmount + vatAmount
        return taxes
    }

    return {
        taxesAndFees,
        vatTotalPrice: (offer) => {
            const {masterData} = useApplicationStore()
            const {vat, exes} = masterData.billing;
            const expenses = (offer.finalPrice * exes) / 100;
            //to do
            const isSpecialOfferActive = false
            const discountMultiplier = isSpecialOfferActive ? 1 /*(100 - $config.public.specialOffer.discount) / 100*/ : 1;
            const baseAmount = offer.finalPrice + expenses;
            const vatAmount = Math.ceil(baseAmount * vat * discountMultiplier / 100);
            return 'currentUser' // Math.ceil(vatAmount + expenses);
        },
        subsTaxesAndFees: (price = 0) => {
            if (vat === 0) {
                vat = 20.2
            }
            return taxesAndFees(price, vat)
        },
        buyTaxesAndFees: (price = 0) => {
            return taxesAndFees(price, vat)
        }
    }
}
