module.exports = {
  alerts: {
    verifyEmail: {
      text: 'It is mandatory verify your email address to have full access.',
      action: 'Verify your email'
    },
    pendingToStar: {text: 'We are processing request to become an STAR. We will notify you when it is completed.'},
    requiredPayPlume: {
      text: 'To be able to make your transfers from June 1st, you must sign up for PayPlume.',
      action: 'You can create an account in PayPlume'
    },
    product_payment_required: {
      text: 'An auction finished and you have been the winner! Go to the product\'s page to make the payment and fill the delivery details.',
    },
    product_delivery_required: {
      text: 'One of the products you have acquired doesn\'t have delivery details.'
    }
  }

}
