<template>

  <img :src="`/assets/icons/${icon}.svg`" alt="" class="fill-red-500" @click="onClick"/>
  <!--    <span class="text-black" v-html="svgContent"/>-->

</template>

<script>
import {computed} from 'vue';

export default {
  name: "Icon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '',
    },
    size: {
      type: [String, Number],
      default: 6,
    }
  },

  setup(props, {emit, attrs}) {

    const colorClass = computed(() => (props.color ? `fill-${props.color}` : ''));
    const additionalClasses = computed(() => attrs.class || '');
    const addClasses = computed(() => [attrs.class, additionalClasses.value, colorClass.value].join(' '));

    const onClick = () => {
      emit('click')
    }
    /*const iconSvg = computed(() => {
      try {

        const svg = instance.proxy.$icons[props.icon];

        const svg2 = instance.proxy.$getSvgContent(props.icon)
        console.log('SVG with icon', svg, svg2);

        //const svg="<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 16 16\"><g transform=\"translate(-1556 -3644)\"><path id=\"twitter\" d=\"M16,3.539a6.839,6.839,0,0,1-1.89.518,3.262,3.262,0,0,0,1.443-1.813,6.555,6.555,0,0,1-2.08.794A3.28,3.28,0,0,0,7.8,5.281a3.377,3.377,0,0,0,.076.748A9.284,9.284,0,0,1,1.114,2.6,3.285,3.285,0,0,0,2.122,6.982a3.24,3.24,0,0,1-1.482-.4v.036A3.3,3.3,0,0,0,3.268,9.837a3.274,3.274,0,0,1-.86.108,2.9,2.9,0,0,1-.621-.056,3.311,3.311,0,0,0,3.065,2.285,6.59,6.59,0,0,1-4.067,1.4A6.144,6.144,0,0,1,0,13.528,9.234,9.234,0,0,0,5.032,15a9.272,9.272,0,0,0,9.336-9.334c0-.145-.005-.285-.012-.424A6.544,6.544,0,0,0,16,3.539Z\" transform=\"translate(1556 3643.5)\" fill=\"#03a9f4\"/></g></svg>";

        const classes = `h-${props.size} w-${props.size}`;
        return svg.replace('<svg', `<svg class="${classes} ${addClasses.value}"`);
      } catch (er) {

        console.error(er.stack, er);
        return '';
      }
    });*/

    return {
      props,
      colorClass,
      additionalClasses,
      addClasses,
      onClick
    };
  },
};
</script>
