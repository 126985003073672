import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store, {SessionActions} from './store'
import api from '@/api/Api'
import FormValidations from './plugins/form_validations'
import EventBus from './plugins/events'
import PWA from './plugins/pwa'
import Websockets from './plugins/websockets'
import vuetify from './plugins/vuetify'
import Timeago from './plugins/timeago'
import I18nPlugin from './plugins/i18n'
import Currency from './plugins/currency'
import CustomComponents from './plugins/custom_components'
import Croppa from 'vue-croppa'
import VueResource from 'vue-resource'
import VueResizeText from 'vue-resize-text'
import VueScrollTo from 'vue-scrollto'
import VueMeta from 'vue-meta'
import jspdf from 'jspdf'
import VueHtml2Canvas from 'vue-html2canvas'
/*import IconsPlugin from "@/plugins/assets_icons_plugin";*/

import {createPinia, PiniaVuePlugin} from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import 'regenerator-runtime/runtime';

import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@/assets/styles/tailwind.css'

import gtm from "./plugins/gtm/gtm";

require('./polly.js')

const isOnIframe = () => {
  try {
    return window.self !== window.top
  } catch (ex) {
    return true
  }
}

if (isOnIframe()) {
  window.top.location.href = window.location.href
} else {
  // TODO Move this to its own filter plugin
  Vue.filter('striphtml', function (value = '') {
    return value.replace(/<\/?[^>]+>/gi, '')
  })

  Vue.config.productionTip = false

  Vue.use(CustomComponents)
  Vue.use(VueResource)
  Vue.use(VueScrollTo)
  Vue.use(FormValidations)
  Vue.use(Websockets)
  Vue.use(EventBus)
  Vue.use(Currency)
  Vue.use(I18nPlugin)
  Vue.use(PWA)
  Vue.use(VueMeta)
  Vue.use(Timeago)
  Vue.use(Croppa)
  Vue.use(VueHtml2Canvas)
  Vue.use(jspdf)
  Vue.use(gtm)
  Vue.use(VueResizeText)
/*  Vue.use(IconsPlugin)*/
  Vue.use(PiniaVuePlugin)

  const pinia = createPinia();
  pinia.use(piniaPluginPersistedstate);

  api.intercepResponse(undefined, function (error) {
    const response = error.response
    return new Promise(function (resolve, reject) {
      if (
        response &&
        response.status === 401 &&
        response.config &&
        !response.config.__isRetryRequest
      ) {
        store.dispatch(SessionActions.Logout)
      }
      reject(error)
    })
  })

  new Vue({
    router,
    store,
    pinia,
    vuetify,
    i18n: I18nPlugin.i18n,
    render: h => h(App),
    mounted() {
      document.addEventListener('contextmenu', e => {
        e.preventDefault()
      })
    }
  }).$mount('#app')

  const loadedTime = new Date().getTime()
  const threshold = 12 * 60 * 60 * 1000
  setInterval(() => {
    const current = new Date().getTime()
    const diff = current - loadedTime
    if (diff > threshold) window.location.reload(true)
  }, 10 * 60 * 1000)
}
