<template>
  <section>
    <p class="mb-2 text-md font-bold">{{ $t('withdraws.withdraw_data.title') }}</p>
    <p>{{ $t('withdraws.withdraw_data.description') }}</p>
    <v-tabs
      background-color="transparent"
      class="border-bottom"
      v-model="tab"
      show-arrows>
      <v-tab
        v-for="(item,index) in tabs"
        :key="`A-${index}`">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(item,index) in tabsData"
        :key="`B-${index}`">
        <v-form v-model="validForm" ref="form">
          <div class="mt-2">
            <v-alert dense type="info" color="orange lighten-2" v-if="isFirstPerson">
              <span>{{ $t('withdraws.withdraw_data.alert') }}
                <router-link
                  class="pointer no-decoration ml-1"
                  :to="{ name: 'settings', query: { tab: 'personal' } }"
                >
                  {{ $t('settings.tabs.personal') }}
                    </router-link>
              </span>
            </v-alert>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">

              </v-col>
              <v-col cols="12" sm="6" class="pr-1">
                <v-select
                  outlined
                  :label="$t('models.influencer_request.id_document_type')"
                  v-model="item.documentType"
                  :items="documentTypes"
                  :disabled="disabledIdentifictionDoc"
                  :rules="[$vRequired]">
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" class="pl-1">
                <v-text-field
                  outlined
                  :label="$t('models.influencer_request.id_document_number')"
                  v-model="item.documentNumber"
                  :disabled="disabledIdentifictionDoc"
                  :rules="[$vRequired]"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pr-1">
                <CountrySelect
                  outlined
                  :label="$t('models.influencer_request.id_document_country')"
                  v-model="item.documentCountry"
                  :disabled="disabledIdentifictionDoc"
                  :rules="[$vRequired]"/>
              </v-col>
              <v-col cols="12" sm="6" class="pl-1">
                <v-text-field
                  outlined
                  :label="$t('models.influencer_request.id_document_expiration')"
                  v-model="item.documentExpiration"
                  :disabled="disabledIdentifictionDoc"
                  :rules="rulesDocumentExpirations"/>
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-3">
              <h3>{{ $t('withdraws.withdraw_data.personal_data') }}</h3>
            </v-row>
            <v-text-field
              outlined
              :label="$t('models.user.real_name')"
              type="text"
              v-model="item.fullName"
              :disabled="disabledIdentifictionDoc"
              :rules="[$vRequired]"></v-text-field>
            <div class="mb-8">
              <date-picker-input
                v-model="item.birthdate"
                v-if="!isBusiness"
                :rules="[$vRequired]"
                :disabled="disabledIdentifictionDoc"
                :label="$t('models.user.birthdate')"/>
            </div>
            <CountrySelect
              outlined
              :label="$t('models.user.country')"
              :placeholder="$t('models.user.select_option')"
              v-model="item.country"
              :disabled="disabledIdentifictionDoc"
              :rules="[$vRequired]"/>
            <v-text-field
              outlined
              :label="$t('models.user.province')"
              type="text"
              v-model="item.province"
              :disabled="disabledIdentifictionDoc"
              :rules="[$vRequired]"></v-text-field>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  :label="$t('models.user.city')"
                  type="text"
                  v-model="item.city"
                  :disabled="disabledIdentifictionDoc"
                  :rules="[$vRequired]"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  :label="$t('models.user.postal_code')"
                  type="text"
                  v-model="item.postalCode"
                  :disabled="disabledIdentifictionDoc"
                  :rules="[$vRequired]"></v-text-field>
              </v-col>
            </v-row>
            <v-text-field
              outlined
              :label="$t('models.user.address')"
              type="text"
              v-model="item.address"
              :disabled="disabledIdentifictionDoc"
              :rules="[$vRequired]"></v-text-field>

            <WithdrawDocumentation
              v-if="isThirdPerson || isBusiness"
              :label="isBusiness?'CIF/VAT':'DNI/CIF/VAT'"
              @fileUploaded="fileDniDoc">

            </WithdrawDocumentation>
            <WithdrawDocumentation
              v-if="isThirdPerson"
              :label="$t('models.user.payment_person.doc_completed')"
              @fileUploaded="fileAuthDoc"
              :downloadFile="getDocPayment"/>
          </div>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
    <v-row justify="end" class="mt-5">
      <v-col cols="auto">
        <c-btn
          text color="secondary"
          @click="cancel">
          {{ $t('actions.cancel') }}
        </c-btn>
      </v-col>
      <v-col cols="auto">
        <c-btn @click="onSubmit" :disabled="!validForm">
          {{ $t('actions.save') }}
        </c-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { AppActions, WithdrawActions } from '@/store'
import validatable from '@/components/mixins/validatable'
import WithdrawDocumentation from '@/components/withdraws/forms/WithdrawDocumentation.vue'
import project from '@/project'

export default {
  name: 'WithdrawDataForm',
  components: { WithdrawDocumentation },
  mixins: [validatable('form')],
  props: {
    dark: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
      project,
      validForm: false,
      tab: 0,
      fileDni: null,
      fileAuth: null,
      tabs: [{
        name: this.$t('withdraws.withdraw_data.tab1'),
        value: 'first_person'
      }, {
        name: this.$t('withdraws.withdraw_data.tab2'),
        value: 'business'
      }, {
        name: this.$t('withdraws.withdraw_data.tab3'),
        value: 'third_person'
      }],
      tabsData: [
        {
          business: 'first_person',
          fullName: '',
          birthdate: '',
          country: '',
          province: '',
          city: '',
          address: '',
          postalCode: '',
          documentType: '',
          documentNumber: '',
          documentCountry: '',
          documentExpiration: '',
          identificationPath: null
        },
        {
          business: 'business',
          fullName: '',
          country: '',
          province: '',
          city: '',
          address: '',
          postalCode: '',
          documentType: '',
          documentNumber: '',
          documentCountry: '',
          documentExpiration: '',
          identificationPath: null
        },
        {
          fullName: '',
          business: 'third_person',
          birthdate: '',
          country: '',
          province: '',
          city: '',
          address: '',
          postalCode: '',
          documentType: '',
          documentNumber: '',
          documentCountry: '',
          documentExpiration: '',
          identificationPath: null,
          withdrawDataPath: null
        }]
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    ...mapState('withdraws', ['withdrawData']),
    countries () {
      return this.$t('countries_v2')
    },
    userFullName () {
      const name = this.currentUser.name || ''
      const surname = this.currentUser.surname || ''
      return `${name} ${surname}`.trim()
    },
    selectedTab () {
      return this.tabs[this.tab].value
    },
    selectedDataTab () {
      return this.tabsData[this.tab]
    },
    isThirdPerson () {
      return this.selectedTab === 'third_person'
    },
    isBusiness () {
      return this.selectedTab === 'business'
    },
    isFirstPerson () {
      return this.selectedTab === 'first_person'
    },
    rulesDocumentExpirations () {
      return this.isBusiness ? [] : [this.$vRequired]
    },
    getDocPayment: function () {
      const { project } = this.project
      const lang = this.$vuetify.lang.current
      if (project === 'loverfans') {
        return lang !== 'es' ? '/downloads/Autorización Loverfans.pages.pdf' : '/downloads/autorizacion_pagos_tercero_lf_es.docx'
      } else {
        return lang !== 'es' ? '/downloads/Autorización Darkfans.pages.pdf' : '/downloads/autorizacion_pagos_tercero_df_es.docx'
      }
    },
    disabledIdentifictionDoc () {
      return this.isFirstPerson
    },
    documentTypes () {
      if (this.isBusiness) {
        return [{
          text: this.$t('models.influencer_request.id_document_types.cif'),
          value: 'cif'
        }]
      } else {
        return [
          {
            text: this.$t('models.influencer_request.id_document_types.passport'),
            value: 'passport'
          },
          {
            text: this.$t('models.influencer_request.id_document_types.id_document'),
            value: 'id_document'
          },
          {
            text: this.$t('models.influencer_request.id_document_types.driver_license'),
            value: 'driver_license'
          }
        ]
      }
    }
  },
  watch: {
    tab () {
      this.$refs.form.map(f => f.reset())
    }
  },
  methods: {
    fileAuthDoc (file) {
      this.selectedDataTab.withdrawDataPath = file.location
      this.fileAuth = file
    },
    fileDniDoc (file) {
      this.selectedDataTab.identificationPath = file.location
      this.fileDni = file
    },
    onSubmit () {
      const index = this.tab
      const config = this.tabsData[index]
      this.$store.dispatch(WithdrawActions.UpdateData, config).then(() => {
        this.$emit('submitted')
        this.$store.dispatch(AppActions.SetNotification, { type: 'success' })
      }).catch(() => {
        this.$store.dispatch(AppActions.SetNotification, {
          type: 'error'
        })
      })
      this.$store.dispatch(AppActions.ShowNotification, true)
    },
    cancel () {
      this.$emit('cancel')
    },
    fillDataTabs (index) {
      const oldData = this.tabsData[index]
      Object.keys(oldData).forEach(key => {
        oldData[key] = this.withdrawData[key]
      })
      this.tabsData[index] = oldData
    },
    fillPersonalData () {
      const personalData = this.tabsData[0]
      Object.keys(personalData).forEach(key => {
        personalData[key] = this.currentUser[key] ? this.currentUser[key] : personalData[key]
        personalData[key] = this.currentUser.promotion[key] ? this.currentUser.promotion[key] : personalData[key]
      })
      personalData.fullName = this.userFullName
    },
    resetDataForm () {
      const index = this.tabs.findIndex(t => t.value === this.withdrawData.business)
      if (index !== -1) {
        this.tab = index
        this.fillDataTabs(index)
      }
      this.fillPersonalData()
    }
  },
  beforeMount () {
    this.resetDataForm()
  }
}
</script>
<style lang="scss" scoped>
.radio-group {
  display: flex;
  align-items: flex-start;
  align-content: center;

}
</style>
