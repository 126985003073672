<template>
  <div class="pb-2">
    <p class="py-3 text-sm font-bold">{{ $t('subscription.title', {username: username}) }}</p>
    <div class="flex mobile:flex-col mobile:justify-start desktop:items-center  desktop:gap-8 text-xs text-secondary-500 font-medium">
      <div class="flex py-1 items-center gap-2">
        <Icon icon="check" size="4" color="red-500" />
        <p>{{ $t('subscription.info.fans') }}</p>
      </div>
      <div class="flex  py-1 items-center gap-2">
        <Icon icon="check" size="4" class="fill-success"/>
        <p>{{ $t('subscription.info.chat') }}</p>
      </div>
    </div>
    <div class="flex items-center gap-2  py-1 text-xs text-secondary-500 font-medium">
      <Icon icon="check" size="4"  class="fill-success"/>
      <p >{{ $t('subscription.info.cancel') }}</p>
    </div>
  </div>
</template>

<script >
import Icon from "@/design/Icon.vue";

export default {
  name: 'SubsModalInfo',
  components:{Icon},
  props:{
    username:{type:String,required: true},
  }
}

</script>
