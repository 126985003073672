<template>
  <div>
    <Dialog
      :size="isMobile?'fullscreen':'md'"
      icon-close-white
      :model-value="modelValue"
      @update:model-value="emit('update:modelValue', $event)"
      no-title
    >
      <template #activator="{ on, attrs }">
        <slot name="activator" v-bind="{ on, attrs }"></slot>
      </template>

      <template #header>
        <BannerMini :user="user" :fullScreen="isMobile"/>
      </template>
      <template #default="{isActive}">
        <div ref="contentForm" class="px-4 mobile:h-[80dvh] overflow-y-auto">
          <SubsModalInfo :username="user.username"/>
          <div v-if="loading"
               class="w-full h-60 flex items-center justify-center">
            <SpinnerBase/>
          </div>
                    <div v-else-if="existError">
                      <PaymentModalError
                        :buyer="currentUser.username"
                        :seller="user.username"
                        type="subscription"
                        :codeError="codeError"
                        @retry="retry"
                      />
                    </div>
                    <div v-show="!loading">
                      <SubsModalForm
                        :user="user"
                        :offer="offer"
                        v-model:loading="loading"
                        @selected-offer="selectedOffer"
                        @create-iframe="createIframePayment"
                        @error="error"
                        @close="close(isActive)"
                      />
                    </div>
        </div>
      </template>
    </Dialog>
    <PaymentModalIframe v-model="iframeShow" fullscreen name="Subscription" :paymentId="paymentId"
                        :iframe-url="iframeUrl"/>
  </div>

</template>

<script>
import PaymentModalIframe from "@/2_0/components/payment/PaymentModalIframe.vue";
import Dialog from "@/design/Dialog.vue";
import {ref, watch} from "vue";
import {useApplicationStore} from "@/store/application_2";
import profile from "@/store/profile";
import BannerMini from "@/2_0/components/banner/BannerMini.vue";
import SpinnerBase from "@/design/spinner/SpinnerBase.vue";
import SubsModalInfo from "@/2_0/components/subs/modal/SubsModalInfo.vue";
import Btn from "@/design/btn/Btn.vue";
import PaymentModalError from "@/2_0/components/payment/PaymentModalError.vue";
import SubsModalForm from "@/2_0/components/subs/modal/SubsModalForm.vue";

export default {
  name: "SubsModal",
  components: {SubsModalForm, PaymentModalError, Btn, SubsModalInfo, SpinnerBase, BannerMini, Dialog, PaymentModalIframe},
  props: {
    user: {},
    modelValue: {type: Boolean},
    offer: {type: Object}
  },
  setup(props, {emit}) {
    const {currentUser} = profile.state;
    const {isMobile} = useApplicationStore()
    const loading = ref(false)
    const codeError = ref()
    const existError = ref(false)
    const iframeShow = ref(false)
    const paymentId = ref(0)
    const iframeUrl = ref('')
    const contentForm = ref(null)

    watch(() => props.modelValue, () => {
      retry()
    })

    const error = ({code, error}) => {
      codeError.value = code
      existError.value = error
    }

    const retry = () => {
      loading.value = false
      codeError.value = null
      existError.value = false
    }

    const close = (isActive) => {
      isActive.value = false
    }

    const createIframePayment = (dataIframe) => {
      paymentId.value = dataIframe.paymentId
      iframeUrl.value = dataIframe.iframeUrl
      iframeShow.value = !iframeShow.value
    }

    const selectedOffer = () => {
      contentForm.value.scrollTop = contentForm.value.scrollHeight;
    }

    return {
      props,
      emit,
      currentUser,
      isMobile,
      loading,
      codeError,
      existError,
      iframeShow,
      paymentId,
      iframeUrl,
      contentForm,
      error,
      retry,
      close,
      createIframePayment,
      selectedOffer
    }
  }
}


</script>

<style scoped lang="scss">

</style>
