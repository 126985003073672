import cookies from '@/cookies'
import config from '@/project'
import ProfileApi from '@/api/ProfileApi'

let darkTheme = cookies.get('darkTheme') === 'true'

if (!config.enable_themes) {
  darkTheme = config.theme === 'dark'
}

const actionsTypes = {
  SetTags: 'set_tags',
  SetInitTags: 'set_init_tags',
  UpdateCookies: 'update_cookies',
  UpdateDarkTheme: 'update_dark_theme',
  UpdateRecommended: 'update_recommended',
  UpdateTagPrefs: 'update_tag_prefs',
  SetTagPrefs: 'set_tag_prefs',
  UpdateAge: 'update_age_prefs',
  UpdateRace: 'update_race_prefs',
  UpdateGender: 'update_gender_prefs',
  UpdateCategory: 'update_category_prefs'
}

const tagPrefs = cookies.get('tag-prefs-selected')
const genderCookies = cookies.get('gender-prefs') || ''
const categoriesCookies = cookies.get('categories-prefs') || ''
const ageCookies = cookies.get('age-prefs') || ''
const raceCookies = cookies.get('race-prefs') || ''

const state = () => ({
  darkTheme: darkTheme,
  showCookies: !cookies.get('allow-cookies-v2'),
  recommended: cookies.get('timeline-recommended') !== 'false',
  tagPrefs: false,
  // avoiding empty values
  genderTags: [],
  categoryTags: [],
  ageTags: [],
  raceTags: []
})

function setState(genderTags, categoryTags, ageTags, raceTags, tags) {
  state.genderTags = genderTags.map(name => tags.find(t => t.name === name))
  state.categoryTags = categoryTags.map(name => tags.find(t => t.name === name))
  state.ageTags = ageTags.map(name => tags.find(t => t.name === name))
  state.raceTags = raceTags.map(name => tags.find(t => t.name === name))
}

const mutations = {
  reset: (s) => {
    s = state()
  },

  async set_init_tags(state, data) {
    const genderTags = []
    const categoryTags = []
    const ageTags = []
    const raceTags = []
    if (data.profile.tagPreferences && data.profile.tagPreferences.length > 0) {
      data.profile.tagPreferences.filter(t => t.section === 'gender').forEach(tag => genderTags.push(tag.name))
      data.profile.tagPreferences.filter(t => t.section === 'category').forEach(tag => categoryTags.push(tag.name))
      data.profile.tagPreferences.filter(t => t.section === 'age').forEach(tag => ageTags.push(tag.name))
      data.profile.tagPreferences.filter(t => t.section === 'race').forEach(tag => raceTags.push(tag.name))
      cookies.set(`tagPrefs-${data.profile.id}`, true)
      state.tagPrefs = true
      cookies.set('tag-prefs-selected', true)
    }
    state.tagPrefs = cookies.get(`tagPrefs-${data.profile.id}`)
    setState(genderTags, categoryTags, ageTags, raceTags, data.tags)
  },

  async set_tags(state, tags) {
    const genderTags = genderCookies.split(',').filter((t) => !!t.trim())
    const categoryTags = categoriesCookies.split(',').filter((t) => !!t.trim())
    const ageTags = ageCookies.split(',').filter((t) => !!t.trim())
    const raceTags = raceCookies.split(',').filter((t) => !!t.trim())
    setState(genderTags, categoryTags, ageTags, raceTags, tags)
  },

  set_cookies(state, show) {
    state.showCookies = !show
  },

  set_dark_theme(state, theme) {
    state.darkTheme = theme
    cookies.set('darkTheme', theme)
  },

  set_recommended: (state, recommended) => {
    state.recommended = recommended
    cookies.set('timeline-recommended', recommended)
  },

  set_tag_prefs: (state, selected) => {
    state.tagPrefs = selected
    cookies.set('tag-prefs-selected', selected)
  },

  set_gender_tags: (state, tags) => {
    state.genderTags = tags
    cookies.set('gender-prefs', tags.map(t => t.name).join(','))
  },

  set_category_tags: (state, tags) => {
    state.categoryTags = tags
    cookies.set('categories-prefs', tags.map(t => t.name).join(','))
  },

  set_age_tags: (state, tags) => {
    state.ageTags = tags
    cookies.set('age-prefs', tags.map(t => t.name).join(','))
  },

  set_race_tags: (state, tags) => {
    state.raceTags = tags
    cookies.set('race-prefs', tags.map(t => t.name).join(','))
  }
}

const getters = {
  selected_tags_ids: (state, getters, rootState) => {
    const ageTags = state.ageTags
    const categoryTags = state.categoryTags
    const raceTags = state.raceTags
    const genderTags = state.genderTags
    return {
      ageIds: ageTags.map(t => t.id),
      categoryIds: categoryTags.map(t => t.id),
      genderIds: genderTags.map(t => t.id),
      raceIds: raceTags.map(t => t.id)
    }
  }
}

const actions = {
  [actionsTypes.SetTags]: async (context, tags) => {
    context.commit('set_tags', tags)
    await context.dispatch('sync_tag_preferences')
  },
  [actionsTypes.SetInitTags]: async (context, {tags, profile}) => {
    context.commit('set_init_tags', {tags, profile})
    await context.dispatch('sync_tag_preferences')
  },

  [actionsTypes.UpdateCookies]: (context, show) => {
    context.commit('set_cookies', show)
  },

  [actionsTypes.UpdateDarkTheme]: (context, theme) => {
    if (theme) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
    context.commit('set_dark_theme', theme)
  },

  [actionsTypes.UpdateRecommended]: (context, recommended) => {
    context.commit('set_recommended', recommended)
  },

  [actionsTypes.SetTagPrefs]: (context, tags) => {
    if (!(tags || []).length) return
    context.commit('set_gender_tags', tags.filter((t) => t.section === 'gender'))
    context.commit('set_age_tags', tags.filter((t) => t.section === 'age'))
    context.commit('set_category_tags', tags.filter((t) => t.section === 'category'))
    context.commit('set_race_tags', tags.filter((t) => t.section === 'race'))
  },

  [actionsTypes.UpdateTagPrefs]: (context, pref) => {
    context.commit('set_tag_prefs', pref)
  },

  [actionsTypes.UpdateGender]: async (context, tags) => {
    if (tags.length) {
      context.commit('set_tag_prefs', true)
    }
    context.commit('set_gender_tags', tags)
    await context.dispatch('sync_tag_preferences')
  },

  [actionsTypes.UpdateCategory]: async (context, tags) => {
    if (tags.length) {
      context.commit('set_tag_prefs', true)
    }
    context.commit('set_category_tags', tags)
    await context.dispatch('sync_tag_preferences')
  },

  [actionsTypes.UpdateAge]: async (context, tags) => {
    if (tags.length) {
      context.commit('set_tag_prefs', true)
    }
    context.commit('set_age_tags', tags)
    await context.dispatch('sync_tag_preferences')
  },

  [actionsTypes.UpdateRace]: async (context, tags) => {
    if (tags.length) {
      context.commit('set_tag_prefs', true)
    }
    context.commit('set_race_tags', tags)
    await context.dispatch('sync_tag_preferences')
  },

  sync_tag_preferences: async (context) => {
    const {genderTags, categoryTags, ageTags, raceTags} = context.state
    const tags = [...genderTags, ...categoryTags, ...ageTags, ...raceTags]
    if (context.rootState.profile.currentUser.authToken) {
      await ProfileApi.updatePreferences(tags.map(t => t.name))
    }
  }
}

export const Actions = actionsTypes

export default {
  namespaceName: 'preferences',
  namespaced: true,
  state: state(),
  getters,
  mutations,
  actions
}
