<template>
  <div class="container-audio">
    <img
      :src="getIconAudio"
      class="img-responsive col pr-0 img"
      :class="classImg"
      alt=""
    />
    <img
      :src="getIconWaves"
      class="img-responsive col pl-0 img"
      :class="classImg"
      alt=""
    />
  </div>
</template>
<script>
import {theme} from '@/configFactory'

export default {
  name: "VAudio",
  props: {
    primary: Boolean
  },
  computed: {
    classImg() {
      return {
        "primary-img": this.primary,
        "secondary-img": !this.primary
      };
    },
    getIconAudio() {
      return require(`${theme}/chat/icono-altavoz.svg`);
    },
    getIconWaves() {
      return require(`${theme}/icono-ondas.svg`);
    }
  }
};
</script>
<style lang="scss" scoped>
.primary-img {
  max-width: 80px;
  max-height: 100px;
  min-height: 100px;

  &.img {
    width: 100%;
    height: 100%;
  }
}

.secondary-img {
  max-width: 100px !important;
  max-height: 100px !important;
  min-height: 100px;

  &.img {
    width: 60%;
    height: 100%;
  }
}

.img-responsive {
  /* height: auto; */
  border-radius: 4px;
  /* min-width: 40px  */
  /*operahack*/
}

.container-audio {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  flex-direction: row;
  //border: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
