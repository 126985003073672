<template>
  <div>
    <div class=" flex flex-col md:flex-row ">
      <div class="w-full mobile:w-auto mobile:flex-none ">
        <div class="flex flex-wrap mobile:flex-col" :class="{'justify-center':offers.length===1}">
          <div v-for="(item,index) in offers" :key="index" class="h-26 w-1/2 mobile:w-full p-1">
            <div class="h-full w-full  border-[1px] rounded-lg p-4 cursor-pointer hover:border-success"
                 :class="selectedOfferClass(item)"
                 @click="selectedOffer(item)"
            >
              <p class="text-xs my-1">
              <span class="font-bold text-md ">
               <span v-if="activeCampaign" :class="{'mr-2':activeCampaign}"
                     class="text-strike text-secondary-400">{{ $currency(25) }}</span>{{
                  $currency(item.finalPrice)
                }} </span>/{{ $t('date.month') }}
              </p>
              <div v-if="item.months===1">
                <p class="text-xs ">{{ $t('subscription.month_by') }}</p>
                <p class="text-2xs">
                  <b v-if="item.discount">{{ $t('subscription.discount', {discount: item.discount}) }} </b>
<!--                  <i18n-t keypath="subscription.unique">
                    <template #amount>
                      <b class="text-secondary-500">{{ $currency(item.finalPrice * item.months) }}*</b>
                    </template>
                  </i18n-t>-->
                </p>
              </div>
              <div v-else>
                <p class="text-xs" v-html="$t('subscription.months_by',{months: item.months})">
                </p>
                <p class="text-2xs ">
<!--                  <i18n-t keypath="subscription.unique">
                    <template #amount>
                      <b class="text-secondary-500">{{ $currency(item.uniquePayment) }}*</b>
                    </template>
                  </i18n-t>-->
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-1 ">

      </div>
      <div class="w-full mobile:w-auto mobile:flex-none  mobile:h-[20vh]"></div>

    </div>
    <div class="mobile:fixed mobile:bottom-0 mobile:left-0 mobile:px-4  bg-secondary-100">
      <div class="w-full mobile:w-auto mobile:flex-none ">
        <div class="flex flex-col items-center gap-4">
          <div class="flex items-center justify-start gap-1 my-1 ">
            <FormCheckbox v-model="check" hide-details class="mr-4"/>
            <p class="pl-1 text-2xs text-start font-medium text-secondary-500">
<!--              <i18n-t keypath="subscription.conditions">
                <template #price>
            <span v-if="activeCampaign">{{
                $currency(totalPrice) /*$currency(newPrice(SubscriptionUtils.totalPrice(subscriptionOffer)))*/
              }}</span>
                  <span v-else>{{
                      $currency(totalPrice) /*$currency(SubscriptionUtils.totalPrice(subscriptionOffer))*/
                    }}</span>
                </template>
                <template #vat_price>{{ $currency(vatPrice) }}</template>
                <template #terms_link>
                  <Links link to="/conditions" target="_blank" class="font-bold tar"
                         :text="i18n.t('application.terms')"/>
                </template>
                <template #claim_link>
                  <Links external target="_blank" class="font-bold" to="https://marketbilling.net"
                         text="marketbilling.net"/>
                </template>
                <template #email_link>
                  <Links email class="font-bold" :to="$config.public.locales.contact_email"/>
                </template>
              </i18n-t>-->
            </p>
          </div>
          <Btn actions :disabled="!check" class="mb-4 w-full">
            {{ $t('actions.next') }}
          </Btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script >

import {GTM_EVENTS} from "@/domain/gtm-event-types";
import profile from "@/store/profile";
import {computed, getCurrentInstance, onMounted, ref, watch} from "vue";
import {useAmount} from "@/composables/useAmount";
import FormCheckbox from "@/design/form/FormCheckbox.vue";
import Links from "@/design/Links.vue";
import Btn from "@/design/btn/Btn.vue";

export default {
  name: "SubsModalForm",
  components: {},
  props: {
    user: {type: Object,},
    offer: {},
    loading: {type: Boolean}
  },
  setup(props, {emit}) {

    const {currentUser} = profile.state;
    const check = ref(false)
    const selectOffer = ref(props.offer)

    const offers = computed(() => {
      const subscriptionOffers = props.user.subscriptionOffers || [];
      const bestOffer = props.user.subscriptionBestOffer || null;

      return subscriptionOffers.length ? subscriptionOffers : [bestOffer];
    });

    const vatPrice = computed(() => {
      return useAmount().subsTaxesAndFees(selectOffer.value.uniquePayment)
    })

    const totalPrice = computed(() => {
      return selectOffer.value.uniquePayment
    })
    const activeCampaign = computed(() => {
      return false
    })
    watch(check, (newValue) => {
      if (newValue) subscriptions()
    })

    const selectedOfferClass = (item) => {
      const isSelected = item.months === selectOffer.value.months
      return {
        'border-success': isSelected
      }
    }

    const selectedOffer = (item) => {
      selectOffer.value = item
      emit('selectedOffer')
    }

    const beforeSubsLoggerGtm = () => {
      const logBefore = {
        seller: props.user.username,
        buyer: currentUser.value.username,
        type: 'subscription',
      }
      console.log('*** Payment letter request ***', logBefore)
      //addGtmEvent(GTM_EVENTS.SUBSCRIPTION_STEP2)
    }

    const afterSubsLogger = (url) => {
      const logAfter = {
        seller: props.user.username,
        buyer: currentUser.value.username,
        type: 'subscription',
        url: url
      }
      console.log('*** Created Payment Letter ***', logAfter)
    }

    const subscriptions = async () => {
      if (!check.value) return

      beforeSubsLoggerGtm()
      const query = {
        influencer: props.user.username,
        //origin: useRedirection().origin(),
        name: currentUser.value.displayName,
        affiliateSource: null,
        affiliate: useCookie('af').value || null,
        type: selectOffer.value.type,
      }

      emit('update:loading', true)

      const {data, error} = await usePayments().activeSubscriptions(query)

      if (!error) {
        afterSubsLogger(data.url)
        emit('create-iframe', {paymentId: data.paymentId, iframeUrl: data.url})
        emit('close')
      } else {
        emit('error', {code: data, error: true})
      }
      emit('update:loading', false)
    }


    const reset = () => {
      check.value = false
      emit('update:loading', false)
      emit('error', {code: '', error: false})
    }

    onMounted(() => {
      reset()
    })

    return {
      props,
      emit,
      currentUser,
      check,
      selectOffer,
      offers,
      vatPrice,
      totalPrice,
      activeCampaign,
      selectedOfferClass,
      selectedOffer,
      beforeSubsLoggerGtm,
      reset,
    }
  }
}

</script>

