<template>
  <section>
    <div
      class="col-12 border-bottom py-3"
      style="text-align: center;display: flex;"
    >
      <icon-button type="previous" class="icons" :size="22" @handler="backToMenu"/>
      <span class="chat-buttons selected" style="margin: auto">
        {{ $t('messages.settings') }}
      </span>
    </div>
    <div class="col-12 setting-title first" :class="{dark: darkTheme}" @click="showSettings(!settings)" v-if="!isFollower">
      <img src="/chat/icono-mensaje-settings.svg" alt=""/><span class="center-title">{{ $t('application.chat_setting.setting.title') }}</span>
    </div>
    <div class="col-12 setting-content" style="background-color: transparent" v-if="settings">
      <ChatSettings @back="settings = !settings"/>
    </div>
    <div class="col-12 setting-title" :class="{dark: darkTheme}" @click="showSettings(false, !autoMessages)" v-if="!isFollower">
      <img src="/chat/icono-message-automatic.svg" alt=""/><span class="center-title">{{ $t('application.chat_setting.schedule.title') }} </span>
    </div>
    <div class="col-12 setting-content" style="background-color: transparent" v-if="autoMessages">
      <AutoMessageSettings />
    </div>
    <div class="col-12 setting-title" :class="{dark: darkTheme}" @click="showSettings(false, false, !showGeneralSetting)">
      <img src="/chat/icono-message-automatic.svg" alt=""/><span class="center-title">{{ $t('application.chat_setting.general.title') }} </span>
    </div>
    <div class="col-12 setting-content" style="background-color: transparent" v-if="showGeneralSetting">
      <GeneralSettings />
    </div>
    <!-- TODO: BOVEDA Y LISTAS -->
    <div class="col-12 setting-title" :class="{dark: darkTheme}" @click="showSettings(false, false, false, !listUser)" v-if="!isFollower">
      <img src="/chat/icono-almacen.svg" alt=""/><span class="center-title"> {{ $t('application.chat_setting.userList.title') }}</span>
    </div>
    <div class="col-12 setting-content" style="background-color: transparent" v-if="listUser">
      <ListUser></ListUser>
    </div>
    <div
      class="col-12 setting-title"
      :class="{ dark: darkTheme }"
      @click="showSettings(false, false, false, false, !albums)"
      v-if="!isFollower"
    >
      <img src="/chat/ico-multi.svg" alt="" /><span
        class="center-title"
      >
        {{ $t('application.chat_setting.albums.title') }}</span
      >
    </div>
    <div class="col-12 setting-content" v-if="albums" >
      <Albums setting="list"></Albums>
    </div>
    <div
      class="col-12 setting-title"
      :class="{ dark: darkTheme }"
      @click="showSettings(false, false, false, false, false, !vault)"
      v-if="!isFollower"
    >
      <img src="/chat/icono-almacen.svg" alt="" /><span
        class="center-title"
      >
        {{ $t('application.chat_setting.vault.title') }}</span
      >
    </div>
    <div class="col-12 setting-content" v-if="vault">
      <Vault setting="vault" onDelete uploadFile ></Vault>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { ChatActions } from '@/store'
import dialog from '@/components/mixins/dialog'
import ChatPreferencesForm from '@/components/chat/components/ChatPreferencesForm'
import messages from '../locales/all.json'
import IconButton from '@/components/chat/component/IconButton'
import ListUser from '@/components/chat/components/listUser/ListUser'
import Vault from '@/components/chat/components/vault/Vault'
import Albums from '@/components/chat/components/albums/Albums'
import ChatSettings from '@/components/chat/components/MessageSettings/ChatSettings'
import GeneralSettings from '@/components/chat/components/MessageSettings/GeneralSettings'
import AutoMessageSettings from '@/components/chat/components/MessageSettings/AutoMessages/AutoMessageSettings'

export default {
  name: 'ChatMenu',
  mixins: [dialog],
  components: {
    AutoMessageSettings,
    ChatSettings,
    ChatPreferencesForm,
    IconButton,
    ListUser,
    Vault,
    Albums,
    GeneralSettings
  },
  i18n: { messages },
  data () {
    return {
      general: false,
      settings: false,
      autoMessages: false,
      vault: false,
      listUser: false,
      albums: false
    }
  },
  computed: {
    ...mapState('chats', ['showGeneralSetting']),
    ...mapState('profile', ['isFollower']),
    darkTheme(){
      return this.$vuetify.theme.dark
    },
  },

  methods: {
    showBroadcast () {
      this.$store.dispatch(ChatActions.SelectBroadcast)
    },
    showSettings (settings = false, autoMessage = false, general = false, listUser = false, albums = false, vault = false) {
      this.general = general
      this.$store.dispatch(ChatActions.ShowGeneralSetting, general)
      this.settings = settings
      this.autoMessages = autoMessage
      this.vault = vault
      this.listUser = listUser
      this.albums = albums
    },
    backToMenu () {
      this.$store.dispatch(ChatActions.ShowGeneralSetting, false)
      this.$emit('back')
    }
  }
}
</script>
<style lang="scss">
.setting-title {
  margin-top: 10px;
  background-color: #e8e8e8;
  color: #333333;
  &.first {
    margin-top: 0px;
  }
  &.dark {
    background-color: #878787;
    color: #333333;
  }
}
.setting-title:hover {
  cursor: pointer;
}
.center-title {
  vertical-align: super;
  margin-left: 5px;
}

.setting-content {
  padding-top: 5px !important;
  background-color: transparent;
}

.chat-buttons {
  text-align: center;
  font-size: 14px;
  font-family: Quicksand, sans-serif;
  background-color: transparent;
  width: 50%;
  &.selected {
    font-weight: bold;
  }
}
</style>
