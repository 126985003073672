<template>
  <button
    class="relative box-border"
    :class="buttonClasses"
    :disabled="disabled || loading"
    @click.stop="handleClick"
  >
    <span v-if="disabled" class="absolute top-0 left-0 h-full w-full rounded-full bg-current opacity-55"></span>
    <span v-if="isLoading" class="px-6 w-full flex items-center justify-center">
      <SpinnerLoading/>
      {{ textLoading ? textLoading : $t('actions.loading') }}
    </span>
    <span v-else>
      <slot></slot>
    </span>
  </button>
</template>

<script>

import {computed, ref} from "vue";
import SpinnerLoading from "@/design/spinner/SpinnerLoading.vue";

export default {
  components: {SpinnerLoading},
  props: {
    disabled: {type: Boolean},
    actions: {type: Boolean},
    ghost: {type: Boolean},
    icon: {type: Boolean},
    iconText: {type: Boolean},
    actionsGhost: {type: Boolean},
    loading: {type: Boolean},
    more: {type: Boolean},
    outlined: {type: Boolean},
    warning: {type: Boolean},
    warningGhost: {type: Boolean},
    textLoading: {type: String},
  },
  setup(props, {emit}) {

    const innerLoading = ref(false)

    const isLoading = computed(() => {
      if (props.loading === undefined) return innerLoading.value
      return props.loading
    })

    const buttonClasses = computed(() => {
      const baseClasses = `text-sm font-bold rounded-full focus:outline-none focus:shadow-outline`;

      const typeClasses = {
        primary: 'py-2 px-4 bg-primary-500 hover:bg-primary-600 active:bg-primary-600 text-secondary-100 uppercase',
        actions: 'py-2 px-4  bg-success hover:bg-green-500 active:bg-green-700 text-secondary-100 uppercase',
        ghost: 'py-2 px-4 hover:bg-primary-100 active:bg-primary-100 text-primary-500 font-bold',
        actionsGhost: 'py-2 px-4 hover:bg-success-100 active:bg-success-100 text-success',
        icon: 'hover:bg-primary-100 hover:fill-primary-500 active:bg-primary-100 hover:text-primary-500 active:text-primary-500',
        iconText: 'border border-primary-300 bg-primary-100 py-1 px-6 fill-primary-500 text-primary-500 hover:bg-primary-500 hover:fill-secondary-100 hover:text-secondary-100',
        more: 'flex items-center justify-center py-1 px-4 border border-primary-300 bg-primary-100 fill-primary-500 text-primary-500',
        outlined: 'py-1.5 px-3.5 border-[1px] border-primary-300 text-primary-500 !border-solid uppercase hover:bg-primary-100',
        warning: 'py-2 px-4 bg-btn-warning-active hover:bg-btn-warning-hover active:bg-btn-warning-active text-secondary-100 uppercase',
        warningGhost: 'py-2 px-4 hover:bg-btn-warning-base active:bg-btn-warning-base text-btn-warning-active',
      };

      const disabledClasses = 'cursor-not-allowed';
      const typeClassKey = Object.keys(typeClasses).find(key => props[key]) || 'primary';
      const typeClass = typeClasses[typeClassKey];

      if (props.disabled || props.loading) {
        return `${baseClasses}  ${typeClass} ${disabledClasses}`;
      } else {
        return `${baseClasses} ${typeClass}`;
      }
    })

    const handleClick = (event) => {
      if (!props.disabled && !props.loading) {
        emit('click', event);
      }
    }
    return {props, emit, innerLoading, isLoading, buttonClasses, handleClick}
  }
}


</script>

<style scoped>
button {
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}
</style>
