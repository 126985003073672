import { defineStore } from "pinia";

export const useDataCacheStore = defineStore('cache', {
    state: () => ({
        cacheData: { }
    }),
        actions: {
            setCache(key, value, persist = false) {
            this.cacheData[key] = value;
            /*if (persist) {
            localStorage.setItem(key, JSON.stringify(value));
            }*/
        },
        getCache(key) {
            if (this.cacheData[key]) {
                return this.cacheData[key];
            }
            // Si no está en el store, buscar en localStorage
           /* const storedValue = localStorage.getItem(key);
            return storedValue ? JSON.parse(storedValue) : null;*/
        },
        removeCache(key) {
            delete this.cacheData[key];
            /*localStorage.removeItem(key);*/
        },

        clearCache() {
            this.cacheData = {};
            /*localStorage.clear();*/
        },
    }
});
