import { render, staticRenderFns } from "./Inicio.vue?vue&type=template&id=d3470d08&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml"
import script from "./Inicio.vue?vue&type=script&lang=js"
export * from "./Inicio.vue?vue&type=script&lang=js"
import style0 from "./Inicio.vue?vue&type=style&index=0&id=d3470d08&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3470d08",
  null
  
)

export default component.exports