<template>
  <div class="container">
    <c-btn
        v-if="!noBack"
        @click="$router.back()">
      {{ $t('actions.back') }}
    </c-btn>
    <div ref="content" class="my-5 mx-2">
      <component :is="getContractLang" class="mt-5 ">
      </component>
    </div>
    <v-form v-model="validForm">
      <v-row>
        <v-col :cols="isMobile?12:6">
          <div class="campo-label">{{ $t('models.user.name') }}</div>
          <v-text-field
              :disabled="currentUser.personalDataApproved"
              class="white-background"
              dense
              outlined
              rounded
              v-model="name"
              :rules="[$vRequired]"
              @focus="showWarning"
          ></v-text-field>
        </v-col>
        <v-col :cols="isMobile?12:6">
          <div class="campo-label">{{ $t('models.user.surname') }}</div>
          <v-text-field
              class="white-background"
              @focus="showWarning"
              dense
              outlined
              rounded
              v-model="surname"
              :rules="[$vRequired]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="isMobile?12:6">
          <div class="campo-label">{{ $t('models.user.dni') }}</div>
          <v-text-field
              class="white-background"
              dense
              outlined
              rounded
              @focus="showWarning"
              v-model="dni"
              :rules="[$vRequired]"
          ></v-text-field>
        </v-col>
        <v-col :cols="isMobile?12:6">
          <div class="campo-label">{{ $t('models.user.address') }}</div>
          <v-text-field
              class="white-background"
              dense
              outlined
              rounded
              v-model="address"
              @focus="showWarning"
              :rules="[$vRequired]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="isMobile?12:6" @click="showWarning">
          <div class="campo-label">{{ $t('models.user.birthdate') }}</div>
          <date-picker-input
              v-model="birthdate"
              outlined
              noLabel
              rounded
              disabled
              :rules="[$vRequired]"
              label=""
          />
        </v-col>
        <v-col :cols="isMobile?12:6" @click="showWarning">
          <div class="campo-label">{{ $t('models.complaint.date') }}</div>
          <date-picker-input
              v-model="date"
              outlined
              noLabel
              rounded
              disabled/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox :rules="[$vRequired]" v-model="acceptContract" :label="$t('application.contract.check')"/>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col v-if="!isMobile" :cols="6"/>
      <v-col :cols="isMobile?12:6" class="textarea-container">
        <v-textarea
            id="textarea"
            class="white-background custom-textarea"
            dense
            :rows="2"
            disabled
            :value="`${name}`"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p v-html="rightsReserved"/>
      </v-col>
    </v-row>
    <v-row class="mt-4 ">
      <v-col>
        <c-btn v-if="!noBack" @click="$router.back()">
          {{ $t('actions.back') }}
        </c-btn>
      </v-col>
      <v-spacer v-if="!isMobile"></v-spacer>
      <v-col>
        <c-btn
            :disabled="!validForm"
            :loading="loadingSign"
            @click="showWarnSing=!showWarnSing"
            style="padding: 0 50px"
            color="#41B849">
          {{ $t('actions.sign') }}
        </c-btn>
      </v-col>
    </v-row>
    <warning
        :title="$t('actions.sign')"
        :textActions="$t('actions.sign')"
        @accept="generateReport"
        v-model="showWarnSing"
        :loading="loadingSing"
        closable>
      {{ $t('application.contract.warningSing') }}
    </warning>
    <warning
        :title="$t('actions.edit')"
        :textActions="$t('actions.edit')"
        @accept="redirectPersonalData"
        v-model="showWarningEdit"
        closable>
      {{ textEditPersonalData }}
    </warning>

    <div ref="pdfPrint" v-show="showHtml2pdf">
      <component :is="getContractLangPdf" class="mt-5 ">
      </component>
      <p>{{ `${this.$t('models.user.name')}: ${this.name} ${this.surname}` }}</p>
      <p>{{ `${this.$t('models.user.dni')}: ${this.dni}` }}</p>
      <p>{{ `${this.$t('models.user.address')}: ${this.address}` }}</p>
      <p>{{ `${this.$t('models.user.birthdate')}: ${this.$date(this.birthdate)}` }}</p>
      <p>{{ `${this.$t('models.complaint.date')}: ${this.$date(this.date)}` }}</p>
      <br>
      <div id="signature" ref="signature2" style="font-size: 20px;margin-left: 40px;">
        {{
          name
        }}
      </div>
      <pre>
        ____________________________
     </pre>
      <pre>
        {{
          $t('actions.signed')
        }}
     </pre>
      <p v-html="rightsReserved"></p>
    </div>
    <!--    <c-btn @click="generateReport"> pruebaaaa</c-btn>-->
    <!--    <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="false"
          :manual-pagination="false"
          :paginate-elements-by-height="1100"
          :pdf-quality="2"
          pdf-format="a4"
          pdf-content-width="90%"
          pdf-orientation="portrait"
          :htmlToPdfOptions="htmlToPdfOptions"
          @hasDownloaded="hasDownloaded($event)"
          @beforeDownload="beforeDownload($event)"
          ref="html2Pdf"
        >
          <section slot="pdf-content" v-show="showHtml2pdf">
                <component :is="getContractLangPdf" class="mt-5 ">
                    </component>
            <p>{{ `${this.$t('models.user.name')}: ${this.name} ${this.surname}` }}</p>
            <p>{{ `${this.$t('models.user.dni')}: ${this.dni}` }}</p>
            <p>{{ `${this.$t('models.user.address')}: ${this.address}` }}</p>
            <p>{{ `${this.$t('models.user.birthdate')}: ${this.$date(this.birthdate)}` }}</p>
            <p>{{ `${this.$t('models.complaint.date')}: ${this.$date(this.date)}` }}</p>
            <v-row>
              <v-col :cols="12" class="textarea-container">
                <p class="custom-sign">{{ name }}</p>
              </v-col>
            </v-row>
            <div class="line"></div>
            <v-row>
              <v-col :cols="12" class="textarea-container">
                <p class="sign-text"> {{ $t('actions.sign') }}</p>
              </v-col>
            </v-row>
            <p v-html="rightsReserved"></p>
          </section>
        </vue-html2pdf>-->
  </div>
</template>

<script>
import Spacer from '@/views/landing/catchment/components/Spacer.vue'
import Es from '@/components/signContract/locales/Es.vue'
import En from '@/components/signContract/locales/En.vue'
import cookies from '@/cookies'
import {mapState} from 'vuex'
import UserApi from '@/api/UserApi'
import S3Api from '@/api/S3Api'
import FileManager from '@/components/mixins/file_manager'

import En_PDF from '@/components/signContract/locales/En_PDF.vue'
import Es_PDF from '@/components/signContract/locales/Es_PDF.vue'

import pdfMake from 'pdfmake'
import pdfFonts from '@/plugins/vfs_fonts'
import htmlToPdfmake from 'html-to-pdfmake'

export default {
  mixins: [FileManager],
  components: {
    Es,
    En,
    En_PDF,
    Es_PDF,
    Spacer,
  },
  props: {
    noBack: Boolean,
    noRedirect: Boolean
  },
  data() {
    return {
      /* htmlToPdfOptions: {
        margin: [15, 10, 15, 10],
        filename: `Contract.pdf`,
        pagebreak: {mode: ['avoid-all']},
        enableLinks: true,
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        html2canvas: {
          scale: 2,
          useCORS: true,
          scrollX: 0,
          scrollY: 0,
        },
      }, */
      name: '',
      surname: '',
      address: '',
      dni: '',
      birthdate: '',
      date: new Date(),
      signedDocumet: false,
      file: null,
      showWarnSing: false,
      acceptContract: false,
      validForm: false,
      loadingSign: false,
      downloadFile: '',
      showWarningEdit: false,
      showHtml2pdf: false,
      loadingSing: false
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('profile', ['currentUser', 'isFollower']),
    ...mapState('session', ['authenticated']),
    getContractLang() {
      return this.lang === 'es' ? Es : En
    },
    getContractLangPdf() {
      return this.lang === 'es' ? Es_PDF : En_PDF
    },
    lang() {
      return cookies.get('locale')
    },
    rightsReserved() {
      const en = '<p><b>Last updated: 08th June 2023</b></p><p><b>© 2023. ARTEMISA3000 TECH SOLUTIONS, S.L. All Rights Reserved.</b></p>'
      const es = '<p><b>Última actualización: 8 de junio de 2023</b></p><p><b>© 2023. ARTEMISA3000 TECH SOLUTIONS, S.L. Todos los derechos reservados.</b></p>'
      return this.lang === 'es' ? es : en
    },
    textEditPersonalData() {
      return this.$i18n.locale === 'es' ? 'Los datos del contrato se editan en datos personales' : 'The data in the contract are edited into personal data'
    }
  },
  async mounted() {
    if (!this.authenticated) {
      this.$router.push('/home')
    }
    this.name = this.currentUser.name
    this.surname = this.currentUser.surname
    this.address = this.currentUser.address
    this.dni = this.currentUser.documentNumber
    this.birthdate = this.currentUser.birthdate
  },
  methods: {
    showWarning() {
      this.showWarningEdit = !this.showWarningEdit
    },

    async generateReport() {
      this.loadingSing=true
      this.showHtml2pdf = true

      const pdfPrint = this.$refs.pdfPrint
      const html = htmlToPdfmake(pdfPrint.innerHTML)

      const signature = html.findIndex((element) => element.id === 'signature');
      html[signature].fontSize = 20
      if (html[signature].text.replace(/\s/g, '').match(new RegExp('^([A-zÀ-ÿ0-9!@#$&()\\-`.+,/\\"])*$', 'g'))) {
        html[signature].style = {font: 'Sacramento'}
      }

      const documentDefinition = {
        content: html,
        defaultStyle: {
          font: 'Roboto'
        }
      }
      pdfMake.fonts = {
        Roboto: {
          normal: 'Roboto-Regular.ttf',
          bold: 'Roboto-Medium.ttf',
          italics: 'Roboto-Italic.ttf',
          bolditalics: 'Roboto-MediumItalic.ttf'
        },
        Sacramento: {
          normal: 'Sacramento.ttf',
          bold: 'Sacramento.ttf',
          cursive: 'Sacramento.ttf',
          bolditalics: 'Sacramento.ttf'
        }
      }
      pdfMake.vfs = pdfFonts.pdfMake.vfs

      const pdfDocGenerator = pdfMake.createPdf(documentDefinition)
      pdfDocGenerator.getBlob(async (blob) => {
        await this.directUpload(blob)
        await this.signDocument()
        pdfMake.createPdf(documentDefinition).open()
      })
      this.showHtml2pdf = false
    },
    /* async beforeDownload({html2pdf, options, pdfContent}) {
      this.showWarnSing = false
      this.loadingSign = true
      const confirmacion = window.confirm('¿Estás seguro de que deseas   el documento?');
      if (!confirmacion) return
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        }
      }).outputPdf()
      //await this.directUpload(pdfContent)
      // await this.signDocument()
    },
    async hasDownloaded(pdfContent) {
     this.showWarnSing = false
       this.loadingSign = true
       await this.directUpload(pdfContent)
       await this.signDocument()
    }, */

    async directUpload(blob) {
      this.file = null
      const file = await this.wrappedFile(
          new File([blob], `${new Date().getTime()}.pdf`, {type: 'application/pdf'})
      )
      const {
        data,
        error
      } = await S3Api.directUpload(file)
      if (!error) {
        file.location = data.filename
        this.file = file
      }
    },
    async signDocument() {
      this.loadingSing=false
      this.showWarnSing = false
      this.loadingSign = true
      const query = {
        documentPath: this.file.location,
        name: this.name,
        surname: this.surname,
        address: this.address,
        documentNumber: this.dni,
        birthdate: this.birthdate
      }
      const {
        error
      } = await UserApi.signDocument(query)
      if (!error) {
        this.loadingSign = false
        this.$emit('signedDocument')
        if (!this.noRedirect) {
          this.redirectPersonalData()
        }
      }
    },
    redirectPersonalData() {
      console.log('pasooo por aqui ')
      this.$router.push('/settings?tab=personal')
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');

pre {
  font-size: 24px;
}

.textarea-container {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  /*padding: 50px;*/
}

.campo-label {
  text-align: left;
  font-size: 16px;
  padding-left: 10px;
  font-family: Quicksand, sans-serif;
}

.custom-sign {
  padding: 40px 0 0 450px;
  font-family: 'Sacramento', cursive;
  font-size: 30px;
  text-align: center;
  opacity: .8;
}

.sign-text {
  font-family: 'Sacramento', cursive;
  padding: 0 0 40px 450px;
}

.line {
  margin: 0 0 0 450px;
  width: 200px;
  border: 0.5px solid;
}

.custom-textarea textarea {
  font-family: 'Sacramento', cursive;
  font-size: 30px;
  text-align: center;
}
</style>
