<template>
  <VDialog v-model="innerValue" :fullscreen="isFullscreen" :persistent="persistent">
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }"></slot>
    </template>
    <div v-if="innerValue" class="fixed inset-0 flex items-center justify-center z-50">
      <div class="bg-white transform transition-all "
           :class="sizeClasses"
      >
        <div v-if="!noHeader" class="flex items-center " :class="{'p-2 border-b rounded-t-lg z-50':!noTitle}">
          <div class="flex-1 ">
            <slot name="header" v-bind="isActive">
              <span
                v-if="!noTitle"
                class="modal-title p-1 sm:text-sm text-md font-semibold "
              >
                {{ title }}
              </span>
            </slot>
          </div>
          <Icon icon="close_modal"
                :class="iconClose"
                class="p-1 right-2 cursor-pointer hover:fill-primary  active:fill-primary focus:outline-none"
                @click="clickClose"
          />
        </div>
        <div class="modal-body max-h-full relative p-4">
          <slot v-bind="isActive"/>
        </div>
        <div class="modal-footer p-2 border-t" v-if="$slots.footer">
          <slot name="footer" v-bind="isActive"/>
        </div>
      </div>
    </div>
  </VDialog>
</template>

<script lang="ts">

import Icon from "@/design/Icon.vue";
import {computed, ref, watch} from "vue";

export default {
  name: "Dialog",
  components: {Icon},
  props: {
    modelValue: {type: Boolean},
    title: {
      type: String,
      default: ''
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['fullscreen', 'auto', 'xs', 'sm', 'md', 'lg', 'xl'].includes(value)
    },
    noHeader: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: true
    },
    iconCloseWhite: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit, slot, attrs}) {
    const innerValue = ref(props.modelValue)

    const isActive = {isActive: innerValue}

    watch(() => props.modelValue, (newValue) => {
      innerValue.value = newValue
    })

    watch(innerValue, (newValue) => {
      if (!newValue) {
        close()
      }
      emit('update:modelValue', newValue)
    })

    const clickClose = () => {
      close()
      emit('click:close')
    }

    const close = () => {
      innerValue.value = false
      emit('update:modelValue', false)
    }

    const sizeClasses = computed(() => {
      return {
        'fullscreen': 'modal-size-fullScreen ',
        'xs': 'modal-size-xs rounded-lg',
        'sm': 'modal-size-sm rounded-lg',
        'md': 'modal-size-md rounded-lg',
        'lg': 'modal-size-lg rounded-lg',
        'xl': 'modal-size-xl rounded-lg',
        'auto': 'modal-auto rounded-lg',
      }[props.size] || 'modal-size-md rounded-lg';
    })

    const iconClose = computed(() => {
      return {
        'absolute top-2 z-50': props.noTitle,
        'fill-secondary-500': !props.iconCloseWhite,
        'fill-secondary-100': props.iconCloseWhite
      }
    })

    const isFullscreen = computed(() => {
      return props.size === 'fullscreen'
    })

    return {props, attrs, slot, emit, isActive, isFullscreen, iconClose, sizeClasses, close, clickClose, innerValue}
  }
}

</script>

<style scoped lang="scss">
.modal-overlay-enter-active,
.modal-overlay-leave-active {
  transition: opacity 0.3s ease;
}

.modal-overlay-enter-from,
.modal-overlay-leave-to {
  opacity: 0;
}

.modal-content-enter-active,
.modal-content-leave-active {
  transition: transform 0.3s ease;
}

.modal-content-enter-from,
.modal-content-leave-to {
  transform: translateY(100%);
}

.modal-size-fullScreen {
  width: 100%;
  height: 100%;
}

.modal-size-xs {
  width: 342px;
  max-height: 650px;
}

.modal-size-sm {
  width: 480px;
  max-height: 650px;
}

.modal-size-md {
  width: 640px;
  max-height: 650px;
}

.modal-size-lg {
  width: 800px;
  max-height: 650px;
}

.modal-size-xl {
  width: 960px;
  max-height: 650px;
}

</style>
