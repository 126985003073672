import { render, staticRenderFns } from "./MoveFundsForm.vue?vue&type=template&id=363b866b&scoped=true"
import script from "./MoveFundsForm.vue?vue&type=script&lang=js"
export * from "./MoveFundsForm.vue?vue&type=script&lang=js"
import style0 from "./MoveFundsForm.vue?vue&type=style&index=0&id=363b866b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "363b866b",
  null
  
)

export default component.exports