<template>
  <section>
    <WalletBalance :wallet="currentWallet" />
    <WalletBalance v-if="isInfluencer" :wallet="otherWallet" />
  </section>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import WalletBalance from './WalletBalance.vue'
import PayplumeInfo from "@/components/wallet/PayplumeInfo.vue";

export default {
  name: 'WalletInfo',
  components: {PayplumeInfo, WalletBalance },
  computed: {
    ...mapGetters('profile', ['currentWallet']),
    ...mapState('profile', ['currentUser', 'isInfluencer', 'eurWallet', 'usdWallet']),
    otherWallet () {
      return this.currentWallet.currency === 'USD' ? this.eurWallet : this.usdWallet
    }
  }
}
</script>
