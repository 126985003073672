<template>
  <span
       :class="[isTypeLabel ? 'cursor-pointer flex gap-2 items-center' : '', isTypeLabel ? classIconLabel : classIcon]">
    <Icon :icon="icon" :size="sizeIcon"/>
    <p v-if="isTypeLabel" :class="classLabel">{{ label }}</p>
  </span>
</template>

<script>
import Icon from "@/design/Icon.vue";
import {computed} from "vue";

export default {
  name: 'BtnIconCircle',
  components: {Icon},
  props: {
    actions: {type: Boolean},
    info: {type: Boolean},
    warning: {type: Boolean},
    icon: {type: String, required: true},
    size: {type: 'xs' | 'sm', default: 'sm'},
    label: {type: String},
  },
  setup(props, {emit}) {
    const isTypeLabel = computed(() => props.label?.length)

    const classIcon = computed(() => {
      const typeClasses = {
        action: `fill-btn-actions-active cursor-pointer rounded-full border-[1px] flex items-center justify-center border-btn-actions-border bg-btn-actions-base hover:bg-btn-actions-hover active:bg-btn-actions-active active:fill-secondary-100`,
        noAction: `fill-btn-primary-active cursor-pointer rounded-full border-[1px] flex items-center justify-center border-btn-primary-border bg-btn-primary-base hover:bg-btn-primary-hover active:bg-btn-primary-active active:fill-secondary-100`,
        info: `bg-btn-follow-base border-btn-follow-border fill-btn-follow-active cursor-pointer rounded-full border-[1px] flex items-center justify-center hover:bg-btn-follow-border active:fill-secondary-100`,
        warning: `fill-btn-warning-active cursor-pointer rounded-full border-[1px] flex items-center justify-center border-btn-warning-border bg-btn-warning-base hover:bg-btn-warning-hover active:bg-btn-warning-active active:fill-secondary-100`,
      }

      const size = {'xs': 'p-2', 'sm': 'p-[14px]'}[props.size]

      const actionClass = props.actions ? typeClasses.action : props.warning ? typeClasses.warning : props.info ? typeClasses.info : typeClasses.noAction

      return [size, actionClass]

    })

    const classIconLabel = computed(() => {
      const action = `fill-btn-actions-active text-btn-actions-active  p-3 cursor-pointer rounded-full border-[1px] flex items-center justify-center border-btn-actions-border bg-btn-actions-base hover:bg-btn-actions-hover active:bg-btn-actions-active active:text-secondary-100 active:fill-secondary-100`
      const noAction = ` fill-primary-500 pr-3 cursor-pointer rounded-full border border-primary-300 bg-primary-100 hover:bg-primary-500 hover:fill-secondary-100 `
      const actionLabelClass = props.actions ? action : noAction
      return [actionLabelClass]
    })

    const classLabel = computed(() => {
      return [
        props.actions ? `font-bold active:text-secondary-100` : `text-primary-500 font-bold active:text-secondary-100`
      ]
    })

    const sizeIcon = computed(() => {
      const size = {
        'xs': 4,
        'sm': 5
      }
      return size[props.size]
    })

    return {props, emit, isTypeLabel, classIcon, classIconLabel, classLabel, sizeIcon}
  }
}


</script>
