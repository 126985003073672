<template>
  <div class="my-auto items-center cursor-pointer" :class="displayModeStyles" v-if="noAction" @click="$emit('click')">
    <div v-if="displayName" class="overflow-hidden flex items-center justify-start">
      <div :class="`text-${widthDisplayName} font-bold text-${colorDisplayName} truncate`">
        {{ displayNameView }}
      </div>
      <Icon icon="trusted" :size="widthIcon" v-if="user.trustedProfile && trustedDisplayName"/>
    </div>
    <div v-if="usernameView" :class="`text-${widthDisplayUsername} flex items-center text-${colorUserName}`">
      @{{ usernameView }}
      <Icon icon="trusted" :size="widthIcon" v-if="user.trustedProfile && !trustedDisplayName"/>
    </div>
  </div>
  <router-link :to="redirectTo" v-else>
    <div class="my-auto items-center cursor-pointer" :class="displayModeStyles">
      <div v-if="displayName" class="overflow-hidden flex items-center justify-start">
        <div :class="`text-${widthDisplayName} font-bold text-${colorDisplayName} truncate`">
          {{ displayNameView }}
        </div>
        <Icon icon="trusted" :size="widthIcon" v-if="user.trustedProfile && trustedDisplayName"/>
      </div>
      <div v-if="usernameView" :class="`text-${widthDisplayUsername} flex items-center text-${colorUserName}`">
        @{{ usernameView }}
        <Icon icon="trusted" :size="widthIcon" v-if="user.trustedProfile && !trustedDisplayName"/>
      </div>
    </div>
  </router-link>
</template>

<script>
import {computed, getCurrentInstance} from "vue";
import Utils from '@/utils'
import Icon from "@/design/Icon.vue";

export default {
  name: 'UserNameLink',
  components: {Icon},
  props: {
    noAction: {type: Boolean},
    targetBlank: {type: Boolean, default: true},
    trustedDisplayName: {type: Boolean},
    userName: {type: Boolean, default: true},
    displayName: {type: Boolean, default: 'secondary-500'},
    colorDisplayName: {type: String, default: 'primary-500'},
    colorUserName: {type: String},
    widthIcon: {type: Number},
    widthDisplayName: {type: 'sm' | 'md' | 'lg' | 'xl', default: 'sm'},
    widthDisplayUsername: {type: 'xs' | 'sm' | 'md' | 'lg' | 'xl', default: 'xs'},
    user: {},
    displayMode: {type: 'grid' | 'flex', default: 'grid'},
    longUserName: {type: Number, default: 25},
    to: {type: String},
  },
  setup(props, {emit}) {
    const {$i18n} = getCurrentInstance().proxy;
    const displayModeStyles = computed(() => {
      const grid = props.displayMode === 'grid'
      return {
        'grid': grid,
        'flex': !grid
      }
    })
    const displayNameView = computed(() => props.user.username ? Utils.truncateText(props.user.displayName, props.longUserName) : $i18n.t('users.guest'))
    const usernameView = computed(() => props.user.username ? Utils.truncateText(props.user.username, 16) : $i18n.t('users.guest'))
    const redirectTo = computed(() => props.to || `/${props.user.username}`)

    return {props, emit, displayModeStyles, displayNameView, usernameView, redirectTo}
  }
}


</script>

<style scoped>

</style>
