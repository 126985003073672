import store, {SessionActions, AppActions, PreferenceActions, CheckoutActions} from '@/store'
import cookies from '@/cookies'
import {useSessionStore} from "@/store/session_2";

export default async (to) => {
  // Check oa token to set the session
  if (to.query.oa) {
    await store.dispatch(SessionActions.OAuth, to.query.oa)
    delete to.query.oa
    return {redirect: {path: to.path, query: to.query, replace: true}}
  }

  // Check if session is setup
  if (!store.state.session.isSetup) {
    const {data} = await store.dispatch(AppActions.FetchMasterData)
    await store.dispatch(SessionActions.ResetSession)
    await store.dispatch(CheckoutActions.LoadToken)
    await store.dispatch(PreferenceActions.SetTags, data.tags)
  }

  // check if this route can be shown being authenticated or unauthenticated
  const authenticated = store.state.session.authenticated
  const authenticatedRoute = to.matched.some(m => m.meta.authenticated === true)
  const unauthenticatedRoute = to.matched.some(m => m.meta.authenticated === false)
  if (authenticatedRoute && !authenticated) {
    return {redirect: '/login'}
  } else if (unauthenticatedRoute && authenticated) {
    return {redirect: '/home'}
  }
}
