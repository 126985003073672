<template>
  <Dialog :persistent="false" size="xs" no-header :model-value="modelValue">
    <div @click="close"
         class=" fixed inset-0 flex items-center justify-center bg-secondary-900/80 bg-opacity-75 ">
      <div class="bg-white rounded shadow-lg">
        <Shared :post="post" :user="user" :type="type" @close="close"/>
      </div>
    </div>
  </Dialog>
</template>

<script>

import Dialog from "@/design/Dialog.vue";
import Shared from "@/components/icons/Shared.vue";

export default {
  name: "SharedModal",
  components: {Dialog, Shared},
  props: {
    modelValue: {type: Boolean},
    post: {},
    user: {},
    type: {type: 'post' | 'user', default: 'post'},
  },
  setup(props, {emit, attrs}) {
    const close = () => {
      emit('update:modelValue', false)
    }
    return {props, emit, attrs, close}
  }
}

</script>
