import {defineStore} from "pinia";
import {useSessionStore} from "@/store/session_2";
import {useApplicationStore} from "@/store/application_2";
import session from "@/store/session";
import profile from "@/store/profile";

export const usePreferencesStore = defineStore('preferences', {
  state: () => ({}),
  actions: {
    getTags(tagNames) {
      const {masterData: {tags}} = useApplicationStore();
      const {currentUser: {tagPreferences}} = session.state
      return tags.filter(tag => tagPreferences.map(t => t.id));
    },
    queryAllTags() {
      const tagPreferences = profile.state.currentUser?.tagPreferences || [];
      return {
        'ageIds[]': tagPreferences.filter(tag => tag.section === 'age').map(t => t.id),
        'categoryIds[]': tagPreferences.filter(tag => tag.section === 'category').map(t => t.id),
        'genderIds[]': tagPreferences.filter(tag => tag.section === 'gender').map(t => t.id),
      }
    },

    async save(tagNames) {
      const {$i18n} = useNuxtApp();
      const query = {
        tagNames: tagNames
      }
      const {error} = await useApi().post('/tag_preferences', query)
      if (!error.value) {
        Toast.success($i18n.t('preferences.success'))
      } else {
        Toast.error($i18n.t('preferences.error'))
      }
      return {error: error.value}
    }

  }
});
