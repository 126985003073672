<template>
  <Dialog :size="isMobile?'fullscreen':'md'" v-model="show" @close="close">
    <template #default>
      <iframe :src="iframeUrl"
              sandbox="allow-forms allow-scripts allow-modals allow-popups allow-same-origin allow-top-navigation"
              style="width: 100%; height: 500px; border: none;"
              @load="onIframeLoad"
              @error="onIframeError"
      />
    </template>
  </Dialog>
</template>

<script>

import Dialog from "@/design/Dialog.vue";
import {ref, watch} from "vue";
import {useApplicationStore} from "@/store/application_2";

export default {
  name: "PaymentModalIframe",
  components: {Dialog},
  props: {
    modelValue: {type: Boolean},
    iframeUrl: {type: String},
    name: {type: String},
    paymentId: {type: Number}
  },
  setup(props, {emit}) {
    const show = ref(props.modelValue)
    const {isMobile} = useApplicationStore()

    watch(() => props.modelValue, (newValue) => {
      show.value = newValue
    })

    const onIframeLoad = async () => {
      emit('load')
    };

    const close = () => {
      emit('updated:modelValue')
    }

    const onIframeError = () => {
      // $logger.error(`Error load Iframe ${props.name}`)
      emit('error')
    }

    return {props, emit, isMobile, show, onIframeLoad, close, onIframeError}
  }
}


</script>

<style scoped lang="scss">

</style>
