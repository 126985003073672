<template>
  <div style="margin: auto">
    <router-link :to="{ name: 'home' }" class="" v-if="isLinkable">
      <v-img
        contain
        :src="getSrc"
        width="154"
        height="24"
      ></v-img>
    </router-link>
    <v-img v-else
           contain
           width="154"
           height="24"
           :src="getSrc"
    ></v-img>
  </div>
</template>
<script>
import project from '@/project'
import { mapState } from 'vuex'

export default {
  name: 'Logo',
  props: {
    isLinkable: Boolean
  },
  data () {
    return {
      project
    }
  },
  computed: {
    ...mapState('application', ['isMobile', 'isLargeScreen']),
    isDark () {
      return this.$vuetify.theme.dark
    },
    getSrc () {
      const project = this.project.project.toLowerCase()
      if (project === 'loverfans') {
        return this.isDark ? '/images/logo_horizontal_white_color.svg' : '/images/logo_horizontal.svg'
      }
      if (project === 'scatbook') {
        return '/images/logo-scatbook.svg'
      }
      return '/images/logo_horizontal.svg'
    }
  }
}
</script>
<style lang="scss" scoped>

.img {
  width: 154px;
  height: 24px;
}

.img :hover {
  cursor: pointer;
}
</style>
