<template>
  <c-img v-if="isImage"
         :contain="contain"
         class="resource"
         :class="{ rounded: rounded }"
         :aspect-ratio="aspectRatio"
         :height="height"
         :max-height="maxHeight"
         :no-resize="noResize"
         :src="imageUrl"
         @mouseover="emitHover(true)"
         @mouseleave="emitHover(false)">
    <slot></slot>
  </c-img>
  <div v-else
       class="resource-wrapper"
       :class="{ rounded: rounded }"
       :width="maxWidth || width"
       :style="videoStyle"
       @mouseover="emitHover(true)"
       @mouseleave="emitHover(false)">
    <VideoPlayer
      ref="video"
      :challenge="challenge"
      :autoload="autoload"
      :rounded="rounded"
      :contain="contain"
      :loop="loop"
      :autoplay="autoplay"
      :controls="!nocontrols"
      :muted="muted"
      :cardView="cardView"
      controlslist="nodownload"
      :height="videoHeight"
      :max-height="maxHeight"
      :width="videoWidth"
      :poster="resource.cover"
      :sources="sources"
      :resource="resource"
      :defineHeight="defineHeight"
      @videoEnded="endVideo()"
      @showVideo="$emit('showVideo')">
    </VideoPlayer>
    <slot class="resource-content"></slot>
  </div>
</template>

<script>
import Utils from '@/utils'
import VideoPlayer from '@/components/custom/VideoPlayer'
import { mapState } from 'vuex'

export default {
  name: 'Resource',
  components: { VideoPlayer },
  props: {
    resource: {
      type: Object,
      default: () => ({
        type: 'image',
        url: null
      })
    },
    contain: Boolean,
    fakeVideo: Boolean,
    aspectRatio: {
      type: [String, Number],
      default: 1
    },
    width: [String, Number],
    height: [String, Number],
    maxWidth: [String, Number],
    maxHeight: [String, Number],
    rounded: Boolean,
    hover: Boolean,
    autoplay: Boolean,
    nocontrols: Boolean,
    muted: Boolean,
    defineHeight: [Number, String],
    clip: Boolean,
    noResize: Boolean,
    loop: {
      type: Boolean,
      default: true
    },
    preview: {
      type: Boolean,
      default: false
    },
    autoload: {
      type: Boolean,
      default: false
    },
    challenge: {
      type: Boolean,
      default: false
    },
    cardView: Boolean
  },
  data () {
    return {
      landscape: false,
    }
  },

  computed: {
    ...mapState('profile', ['currentUser', 'isFollower']),
    metadata () {
      return this.resource.metadata || {}
    },
    isGifCover () {
      const url = this.resource.url.split('?')[0]
      return url.endsWith('.gif')
    },
    imageUrl () {
      if (this.fakeVideo) {
        return this.isGifCover ? this.resource.url : this.resource.cover
      }
      return this.hover && this.resource.hover
        ? this.resource.hover
        : this.preview && !this.resource.teaser
          ? this.resource.preview
          : this.resource.url
    },
    videoStyle () {
      const style = {}
      if (this.height) {
        style.height = `${this.height}px`
      }
      if (this.maxHeight) {
        style['max-height'] = `${this.maxHeight}px;`
      }
      style['border-radius'] = 'unset !important'
      return style
    },
    isImage () {
      if (this.fakeVideo) {
        return true
      }
      if (this.resource.type) {
        return this.resource.type === 'image'
      } else {
        return Utils.isImage(this.resource.url.split('?')[0])
      }
    },
    isVideo () {
      if (this.fakeVideo) {
        return false
      }
      if (this.resource.type) {
        return this.resource.type === 'video'
      } else {
        return Utils.isVideo(this.resource.url.split('?')[0])
      }
    },

    videoHeight () {
      if (this.height) {
        return this.height
      }
      return this.metadata.clip && this.metadata.clip.height
    },
    videoWidth () {
      return this.metadata.clip && this.metadata.clip.width
    },
    sources () {
      const sourcesUrls = [this.resource.url, this.resource.alternative]
        .filter(url => !!url)
      return this.clip && this.resource.clip ? [this.resource.clip] : sourcesUrls
    }
  },
  watch: {
    autoplay (value) {
      if (this.$refs.video) {
        if (value) {
          this.$refs.video.play()
        } else {
          this.$refs.video.pause()
        }
      }
    }
  },
  methods: {
    emitHover (value) {
      this.$emit('update:hover', value)
    },
    endVideo () {
      this.$emit('videoEnded')
    }
  }
}
</script>
<style lang="scss" scoped>
.resource-wrapper {
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;

  .resource {
    margin: 0 auto;
    display: inherit;
  }

  .resource-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
