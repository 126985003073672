var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('WarningDialog',{attrs:{"show":_vm.showWarning,"textWarning":_vm.text,"textButtonOK":_vm.textButtonOk,"loading":_vm.loadingButton},on:{"handlerButtonCancel":_vm.close,"handlerButtonOK":_vm.setPreferences}}),_c('div',{staticClass:"row mt-2 notification",class:_vm.cssClass},[_c('div',{staticClass:"col"},[(_vm.isTip)?_c('span',[_c('icon-button',{attrs:{"type":"notif-tip","disabled":"","disabledDarkTheme":""}}),_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'new_chat.notifications.tip.' +
                (_vm.isSameUser ? 'user' : 'influencer'),
              { username: _vm.selectedChat.receiver.username, amount: _vm.amount }
            )
          )}})],1):_vm._e(),(_vm.isPurchase)?_c('span',[_c('icon-button',{attrs:{"type":"notif-unblock","disabled":"","disabledDarkTheme":""}}),_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'new_chat.notifications.purchase.' +
                (_vm.isSameUser ? 'user' : 'influencer'),
              { username: _vm.selectedChat.receiver.username, amount: _vm.amount }
            )
          )}})],1):_vm._e(),(_vm.isNewFan)?_c('span',[_c('icon-button',{attrs:{"type":"notif-fan","disabled":"","disabledDarkTheme":""}}),_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'new_chat.notifications.fan.' +
                (_vm.isSameUser ? 'user' : 'influencer'),
              { username: _vm.selectedChat.receiver.username }
            )
          )}})],1):_vm._e(),(_vm.isFree)?_c('span',[_c('icon-button',{attrs:{"type":"notif-free","disabled":"","disabledDarkTheme":""}}),_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'new_chat.notifications.free.' +
                (_vm.isSameUser ? 'user' : 'influencer')
            )
          )}})],1):_vm._e(),(_vm.isPayment)?_c('span',[_c('icon-button',{attrs:{"type":"notif-payment","disabled":"","disabledDarkTheme":""}}),_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'new_chat.notifications.payment.' +
                (_vm.isSameUser ? 'user' : 'influencer'),
              { amount: _vm.amount }
            )
          )}})],1):_vm._e(),(_vm.isLostCall)?_c('span',[_c('icon-button',{attrs:{"type":"lost-call","disabled":"","disabledDarkTheme":""}}),_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t('new_chat.notifications.lost_call.called_me', {
              user: _vm.username
            })
          )}})],1):_vm._e(),(_vm.isRejectedCall)?_c('span',[_c('icon-button',{attrs:{"type":"rejected-call","disabled":"","disabledDarkTheme":""}}),_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t('new_chat.notifications.lost_call.called', {
              user: _vm.username
            })
          )}})],1):_vm._e(),(_vm.isCall)?_c('span',[_c('icon-button',{attrs:{"type":"in-call","disabled":"","disabledDarkTheme":""}}),_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$tc('new_chat.notifications.call', _vm.timeInCall, {
              user: _vm.username,
              time: _vm.timeInCall
            })
          )}})],1):_vm._e(),(_vm.isTranslated)?_c('span',{staticClass:"canClick",on:{"click":_vm.switchTranslate}},[_c('icon-button',{attrs:{"type":"notif-info","disabledDarkTheme":""}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('new_chat.notifications.translated'))}})],1):_vm._e(),(_vm.isSubscriptionMessage)?_c('span',[_c('icon-button',{staticClass:"subscriptionOfferMessage",attrs:{"type":"notif-info","disabledDarkTheme":""}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.decodeMessage(_vm.notification.message))}}),(_vm.showSubscriptionButton)?_c('PaymentButton',{staticClass:"ml-auto",attrs:{"available":_vm.user.subscribable,"color":_vm.subscribeButtonColor,"show-init":_vm.showPaymentButton,"small":"","subscription":"","resource":_vm.user}}):_vm._e()],1):_vm._e(),(_vm.isNotTranslated)?_c('span',{staticClass:"canClick",on:{"click":_vm.switchTranslate}},[_c('icon-button',{attrs:{"type":"notif-info","disabled":"","disabledDarkTheme":""}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('new_chat.notifications.not_translated'))}})],1):_vm._e()]),_c('icon-button',{staticStyle:{"margin-left":"auto"},attrs:{"size":14,"type":"close","disabledDarkTheme":""},on:{"handler":_vm.deleteNotif}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }