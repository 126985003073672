<template>
  <a v-if="link" @click="Utils.redirectTo(to)" :target="target" :class="[CClass,ui]">
    <slot>{{ text || to }}</slot>
  </a>
  <a v-else-if="email" :class="[CClass,ui]" :target="target" :href="`mailto:${to}`">
    <slot>{{ text || to }}</slot>
  </a>
  <a v-else-if="external" :class="[CClass,ui]" :target="target" @click="externalWeb">
    <slot>{{ text || to }}</slot>
  </a>
</template>

<script>
export default {
  name: 'Links',
  props: {
    to: {type: String},
    text: {type: String},
    link: {type: Boolean},
    email: {type: Boolean},
    external: {type: Boolean},
    CClass: {type: String},
    target: {type: String},
  },
  setup(props) {
    const ui = 'cursor-pointer fonts-bold text-primary'
    const externalWeb = () => {
      window.open(props.to)
    }
    return {props, ui, externalWeb}
  }
}

</script>
