<template>
  <v-form ref="form" v-model="validForm">
    <p class="mb-2 text-md font-bold">{{ titleForm }}</p>
    <v-row items="center" justify="center" v-if="isPayplumeConnected">
      <v-col cols="auto">
        {{ $t('withdraws.withdraw_methods.payplume.form.link_text', {payplume_address: address}) }}
      </v-col>
    </v-row>
    <v-row items="center" justify="center" v-if="!dirty && payplume.error">
      <v-col cols="auto">
        <span class="primary--text">{{ payplumeErrorMessage }}</span>
      </v-col>
    </v-row>
    <v-row items="center" justify="center">
      <v-col cols="auto">
        <c-btn color="secondary" @click.stop="connectPayplume">
          {{ actionText }}
        </c-btn>
      </v-col>
      <v-col cols="auto" v-if="isPayplumeConnected">
        <c-btn @click.stop="disconnectPayplume">
          {{ $t('withdraws.withdraw_methods.payplume.form.unlink_action') }}
        </c-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {ProfileActions} from '@/store'
import WithdrawApi from "@/api/WithdrawApi";
import {mapState} from 'vuex'
import validatable from "@/components/mixins/validatable";

export default {
  mixins: [validatable('form')],
  props: {
    selectedMethod: {
      type: String
    },
    payplume: {
      type: Object
    }
  },
  data() {
    return { dirty: false}
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    titleForm() {
      return this.$t(`withdraws.withdraw_methods.types.${this.selectedMethod}`)
    },
    address() {
      return this.currentUser.withdrawConfig.payplume.address
    },
    isPayplumeConnected() {
      return this.currentUser.withdrawConfig && this.currentUser.withdrawConfig.payplume.configured
    },
    actionText() {
      return this.isPayplumeConnected ? this.$t('withdraws.withdraw_methods.payplume.form.relink_action') : this.$t('withdraws.withdraw_methods.payplume.form.link_action')
    },
    methodAttrs() {
      return {
        type: 'payplume',
        accountId: this.address,
        withdrawMethodApproved: true
      }
    },
    payplumeErrorMessage() {
      return this.$t(`withdraws.withdraw_methods.payplume.errors.${this.payplume.error}`)
    }
  },
  methods: {
    async connectPayplume() {
      const {data} = await WithdrawApi.startLinkPayplume(window.location.href)
      window.location.href = data.redirectTo
    },
    async disconnectPayplume() {
      this.dirty = true
      await WithdrawApi.unlinkPayplume()
      this.$store.dispatch(ProfileActions.Fetch)
    }
  },
  watch: {
    methodAttrs: {
      immediate: true,
      handler() {
        this.$emit('methodAttrs', this.methodAttrs)
      }
    },
    isPayplumeConnected: {
      immediate: true,
      handler(newValue) {
        this.$emit('validForm', newValue)
      }
    }
  }
}
</script>
