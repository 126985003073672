import { render, staticRenderFns } from "./TitleCollapse.vue?vue&type=template&id=46a5ec20&scoped=true"
import script from "./TitleCollapse.vue?vue&type=script&lang=js"
export * from "./TitleCollapse.vue?vue&type=script&lang=js"
import style0 from "./TitleCollapse.vue?vue&type=style&index=0&id=46a5ec20&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46a5ec20",
  null
  
)

export default component.exports