<template>
  <div>
    <div v-if="!horizontal">
      <RecommendedCarousel/>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import {UserActions} from '@/store'
import UserCard from '@/components/users/UserCard'
import Slider from '@/components/widgets/Slider'
import config from '@/project'
import RecommendedCarousel from "@/components/widgets/recommended/RecommendedCarousel.vue";
import {useRecommendedStore} from "@/store/recommended";

export default {
  name: 'InfluencerRecommendations',
  components: {RecommendedCarousel, UserCard, Slider},
  props: {
    empty: Boolean,
    horizontal: {type: Boolean, default: false}
  },
  data() {
    return {
      users: [],
      loading: false
    }
  },
  computed: {
    ...mapState('preferences', ['darkTheme']),
    titleText() {
      return this.empty
        ? this.$t('users.discovery.empty_title')
        : this.$t('users.discovery.title')
    },
    descriptionText() {
      return this.empty
        ? this.$t('users.discovery.empty_description')
        : this.$t('users.discovery.description')
    },
    showMoreColor() {
      return this.darkTheme ? 'secondary' : 'primary'
    }
  },

  // TODO Move search to mixin
  methods: {
    searchQuery() {
      return {
        role: 'influencer',
        //order: 'online:desc,featured:desc,postCount:desc',
        order: 'promotedProfile:desc,premiumModel:desc,trustedProfile:desc,featured:desc,activeStatus:asc',
        ...this.$store.getters['preferences/selected_tags_ids'],
        following: false,
        subscribed: false,
        includeOnline: true,
        includeSelf: false,
        includeTags: true,
        // DARKFANS
        darkfans: config.project === 'darkfans',
        perPage: 5
      }
    },

    async fetch(withTags = true) {
      const query = this.searchQuery()
      if (!withTags) {
        delete query.ageIds
        delete query.categoryIds
        delete query.genderIds
      }
      const {data, error} = await this.$store.dispatch(UserActions.Search, query)
      if (!error) {
        this.users = data.data
        return data.data
      }
      return []
    },

    async load() {
      this.loading = true
      const data = await this.fetch()
      if (!data.length) {
        await this.fetch(false)
      }
      this.loading = false
    }
  },
  created() {
    //this.load()
    const recommendedStore = useRecommendedStore()
    recommendedStore.list()
  }
}
</script>
<style lang="scss" scoped>
.user-row {
  padding: 2px;
  border-radius: 5px;
}

.discovery-card {
  width: 400px;
  text-align: center;
  margin: 16px auto;
}

.dark {
  background-color: rgba(0, 0, 0, 0.51) !important;

  .dark-title {
    color: #df3856 !important;
  }

  .dark-content {
    background-color: black !important;
    color: #df3856 !important;
  }
}
</style>
