<template>
  <v-row align="center">
    <v-col :cols="this.isMobile ? 12 : 4">
      {{ withdrawData.fullName }}
    </v-col>
    <v-col :cols="this.isMobile ? 12 : 2">
      {{ $t(`withdraws.withdraw_methods.types.${method.type}`) }}
    </v-col>
    <v-col :cols="this.isMobile ? 4 : 2">
      {{ method.currency }}
    </v-col>
    <v-col :cols="this.isMobile ? 4 : 2">
      {{ `${method.accountId.substring(0, 13)}...` }}
    </v-col>
    <v-col :cols="this.isMobile ? 4 : 2">
      <v-btn icon
             color="red"
             @click="deleteMethod">
        <v-icon>delete_forever</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import {mapState} from 'vuex'
import {WithdrawActions} from '@/store'

export default {
  name: 'WithdrawMethods',
  props: {
    method: Object
  },
  computed: {
    ...mapState('withdraws', ['withdrawData', 'withdrawMethods']),
    ...mapState('applications', ['isMobile']),
  },
  methods: {
    deleteMethod() {
      this.$store.dispatch(WithdrawActions.DeleteMethod, this.method.id)
    }
  }
}
</script>
