import {metaInfoProfile} from "@/store/users";
import {defineStore} from "pinia";
import {useDataCacheStore} from "@/store/dataCache";
import {usePreferencesStore} from "@/store/preferences_2";
import {useSessionStore} from "@/store/session_2";
import {useApplicationStore} from "@/store/application_2";
import Api from "@/api/Api";
import session from "@/store/session";
import profile from "@/store/profile";

const url = {
  list: '/users/recommended',
  remove: '/users/removeSuggested'
}

export const useRecommendedStore = defineStore('recommended', {
  state: () => ({
    recommended: [],
    recommendedSearch: [],
    query: {
      role: 'influencer',
      following: false,
      subscribed: false,
      includeOnline: true,
      includeSelf: false,
      includeTags: true,
      darkfans: false,
      perPage: 12,
      page: 0
    },
    loadingSearch: false,
    loading: false,
    error: null,
    existReload: true,
    totalCount: 0,
    totalLoad: 1
  }),
  actions: {
    async list() {
      const dataCache = useDataCacheStore()
      const preferencesStore = usePreferencesStore()
      const recommendedCache = dataCache.getCache('recommended')
      this.loading = true;
      if (recommendedCache) {
        this.recommended = recommendedCache
        this.loading = false
      }
      const pref = preferencesStore.queryAllTags()
      const currentUser = profile.state.currentUser;
      const query = {...this.query, ...pref}
      const {error, data} = await Api.get(url.list, query)
      if (!error) {
        const usersRecommended = data.data.map(item => metaInfoProfile(item, currentUser))
        this.checkReload(data.metadata.totalCount)

        this.recommended = usersRecommended
        dataCache.setCache('recommended', usersRecommended)
      } else {
        this.error = error;
      }
      this.loading = false
    },

    async reload() {
      this.recommended = []
      this.query.page = this.query.page + 1
      await this.list();
    },

    checkReload(totalCount) {
      const lastPage = Math.ceil(totalCount / this.query.perPage) - 1
      this.existReload = lastPage !== this.query.page
    },

    async remove(username) {
      const {error} = await Api.post(url.remove, {username: username})
      if (!error.value) {
        this.reload()
        useApplicationStore().onToast({
          text: `Se ha eliminado a ${username} de las cuentas sugeridas `,
          type: 'ok'
        })
      } else {
        useApplicationStore().onToast({
          text: `Error eliminando a  ${username} de las cuentas sugeridas `,
          type: 'ko'
        })
      }
    },
    async search() {
      const session = useSessionStore()
      const dataCache = useDataCacheStore()
      const recommendedSearchCache = dataCache.getCache('recommendedSearch')
      this.loadingSearch = true;
      if (recommendedSearchCache) {
        this.recommendedSearch = recommendedSearchCache
        this.loadingSearch = false;
      }

      const tags = {
        'ageIds[]': session.currentUser.tagPreferences.filter(tag => tag.section === 'age').map(t => t.id),
        'categoryIds[]': session.currentUser.tagPreferences.filter(tag => tag.section === 'category').map(t => t.id),
        'genderIds[]': session.currentUser.tagPreferences.filter(tag => tag.section === 'gender').map(t => t.id),
      }
      const query = {
        includeTags: true,
        order: 'promotedProfile:desc,premiumModel:desc,trustedProfile:desc,featured:desc,activeStatus:asc',
        ...tags,
        darkfans: false,
        limit: 18,
        nextCursorToken: ''
      }

      const {data, error} = await Api.get('/users', query)
      if (!error.value && data.value) {
        this.recommendedSearch = data.value.data || []
        dataCache.setCache('recommendedSearch', data.value.data)
      } else {
        this.error = error.value;
      }
      this.loadingSearch = false;
    },
  }
});
