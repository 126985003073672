//import type {LoverfansWebsocket} from "~/domain/websocket";
import { defineStore } from "pinia";
import Api from "@/api/Api";

export const useSessionStore = defineStore('session', {
    state: () => ({
        currentUser: {} ,
        unreadMessagesCount: 0,
        isInfluencer: false,
        isFollower: false,
        isCompliance: false,
        isAccountManager: false,
        isContentManager: false,
        isAdmin: false,
        isController: false,
        isAgent: false,
        authenticated: false
    }),
    actions: {
        async auth() {
            return await Api.post('/account/auth')
        },

        async oauth(oaToken) {
            const {data, error} = await Api.post('/account/oauth', {token: oaToken})
            if (!error.value) {
                this.setSession(data.value , true);
            }
        },

        setSession(user, cookie = false) {
            this.$state = metaInfoProfile(user);
            this.authenticated = true
            const maxAge = 7 * 24 * 3600 * 1000

            if (cookie) {
                const token = useCookie('AuthToken', {
                    maxAge: maxAge,
                    sameSite: 'strict'
                })
                token.value = user.authToken
            }
        },
        async profile(forceFetch=false) {
            const token = useCookie('AuthToken');
            if (!token.value) return {error: true}
            if (this.authenticated && !forceFetch) return {data: this.currentUser}

            const {data, error} = await Api.get('/profile')
            this.authenticated = !error.value
            if (this.authenticated) {
                this.setSession(data.value );

            } else {
                token.value = '';
            }
            return {data: data.value, error: !this.authenticated}
        },

        async getUnreadMessages() {
            const {data, error} = await Api.get('/conversations/unread')
            this.$state = {
                ...this.$state,
                unreadMessagesCount: data?.value?.countUnreadMessages || 0
            }
            return {data: data.value, error: error.value}
        },

        async signup(params) {
            const {getReferral } = useTracking()
            params.referral = getReferral()
            params.utm = {
                utm_source: useCookie('utm_source'),
                utm_medium: useCookie('utm_medium'),
                utm_content: useCookie('utm_content'),
                utm_term: useCookie('utm_term'),
                utm_campaign: useCookie('utm_campaign'),
            };
            console.log('PARAMS: ', params)
            const {data, error} = await Api.post('/account', params)
            if (!error.value) {
                this.setSession(data.value , true);
            }
            return {data: data.value, error: error?.value?.data}
        },

        async completeSignup(username, displayName, email, password,) {
            const {data, error} = await Api.put('/account', {
                username,
                displayName,
                password,
                email,
                acceptedTerms: true,
                isAdult: true
            })
            if (!error.value) {
                this.setSession(data.value , true);
            }
            return {data: data.value, error: error.value?.data}
        },

        async login(login, password) {
            const {data, error} = await Api.post('/account/login', {login, password})
            if (!error.value) {
                this.setSession(data.value , true);
            }

            return {data: data.value, error: error.value}
        },

        async checkUsername(username) {
            const {data, error} = await Api.get(`/users/check_name/${username}`)
            // 'success: false' indica username NO disponible
            return data.value
        },


        async logout() {
            this.currentUser = {};
            this.authenticated = false;
            const token = useCookie('AuthToken');
            token.value = null;
            //(useNuxtApp().$ws as LoverfansWebsocket).disconnect()
            // Esto solo sirve para loggear el logout en el server
            // no podemos penalizar la UX así que sin await
            Api.delete('/account/logout')
            navigateTo('/login')
        },
        async requestPassword(email) {
            const {error} = await Api.post('/account/password_request', {email})
            return {error: error.value}
        },
        async resetPassword(token, password) {
            const {error} = await Api.put('/account/password_reset', {token, password})
            return {error: error.value}
        },
        async changePassword(currentPassword, password) {
            const {error} = await Api.put('/account/passwords', {currentPassword, password})
            return {error: error.value}
        },
        async validate(token) {
            const {data, error} = await Api.post('/account/validate', {token})
            return {data: data.value, error: error.value}
        },
        async editInfo(profile) {

            const {error} = await Api.put('profile/account_data', profile)
            if (!error.value) {
                Toast.success(`Perfil editado correctamente`)
            } else {
                Toast.error(`Error al editar perfil`)
            }
        },
        requestValidation() {
            const {error} = Api.post('/account/email_validation')
            if (!error.value) {
                Toast.success(`Email de verificacón enviado correctamente`)
            } else {
                Toast.error(`Error al enviar el email de verificacón`)
            }
        },
    }
})
const metaInfoProfile = (user) => {
    return {
        currentUser: user,
        isInfluencer: user && user.role === 'influencer',
        isFollower: user && user.role === 'follower',
        isCompliance: user && user.role === 'compliance',
        isAccountManager: user && user.role === 'account_manager',
        isContentManager: user && user.role === 'content_manager',
        isAdmin: user && user.role === 'admin',
        isController: user && user.role === 'controller',
        isAgent: user && user.isAgent
    };
};


