import { defineStore } from "pinia";

const OneHour = 60 * 60 * 1000
const isCacheValid = (timestamp) => {
    const limitTimestamp = new Date().getTime() - OneHour
    return timestamp !== null && timestamp > limitTimestamp
}

export const useApplicationStore = defineStore('application', {
    state: () => ({
        masterData: { billing: {}, tags: [], categories: [] }, // declaring here to appear in vue tools
        masterDataTimestamp: null,
        isSmallScreen: false,
        isMediumScreen: false,
        isLargeScreen: false,
        isSmallScreenOrGreater: false,
        isMediumScreenOrGreater: false,
        screenWidth: 0,
        screenHeight: 0,
        load: false,
        publish: false,
        publishType: 'post',
        lastFetchTime: null,
        toast: {
            timeout: 3000,
            show: false,
            text: '',
            type: 'success'
        },
        /////// device /////////
        isMobile: false,
        isMobileOrTablet: true,
        isTablet: false,
        isDesktop: false,
        isIos: true,
        isAndroid: false,
        isWindows: false,
        isMacOS: true,
        isApple: true,
        isDesktopOrTablet: false,
        isSafari: true,
        isFirefox: false,
        isEdge: false,
        isChrome: false,
        isSamsung: false,
        isCrawler: false
    }),
    actions: {
        async syncMasterData() {
            // We are checking first only per normal sessions of 1 hour. On SSR we will need to use Nitro storage
            if (isCacheValid(this.masterDataTimestamp)) return { data: this.masterData }
            return this.fetchMasterData()
        },
        async fetchMasterData() {
            const { data, error } = await useApi().get('/environment/master_data')
            if (!error.value) {
                this.masterData = data.value
                this.masterDataTimestamp = new Date().getTime()
            } else {
                this.masterDataTimestamp = null
            }
            return { data: data.value, error: error.value }
        },

        screenSize(dimensions) {
            this.screenWidth = dimensions.width;
            this.screenHeight = dimensions.height;
            this.isLargeScreen = dimensions.width >= 1264;
            this.isMediumScreen = dimensions.width >= 960 && dimensions.width < 1264;
            this.isSmallScreen = dimensions.width >= 768 && dimensions.width < 960;
            //this.isMobile = dimensions.width < 763;
            this.isMediumScreenOrGreater = dimensions.width >= 960;
            this.isSmallScreenOrGreater = dimensions.width >= 768;
        },

        onLoad() {
            this.load = true
        },
        offLoad() {
            this.load = false
        },
        onToast({ text = '', type = 'ok', timeout = 30000 }) {
            this.toast.show = true
            this.toast.text = text
            this.toast.type = type
            this.toast.timeout = timeout
        },
        offToast() {
            this.toast.show = false
        },
        togglePublish() {
            this.publish = !this.publish
        },
        detectDevice() {
            const device = useDevice()

            this.isMobile = device.isMobile
            this.isTablet = device.isTablet
            this.isDesktop = device.isDesktop
            this.isIos = device.isIos
            this.isAndroid = device.isAndroid
            this.isWindows = device.isWindows
            this.isMacOS = device.isMacOS
            this.isApple = device.isApple
            this.isSafari = device.isSafari
            this.isFirefox = device.isFirefox
            this.isEdge = device.isEdge
            this.isChrome = device.isChrome
            this.isSamsung = device.isSamsung
            this.isCrawler = device.isCrawler

            this.isMobileOrTablet = device.isMobile || device.isTablet
            this.isDesktopOrTablet = device.isDesktop || device.isTablet
        }
    }
})
