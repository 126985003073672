function configFactory(envOverrides = {}) {
  const defaultConfig = {
    baseUrl: process.env.VUE_APP_BASE_URL,
    api: process.env.VUE_APP_API_URL,
    websocket: process.env.VUE_APP_WEBSOCKET_URL,
    theme: process.env.VUE_APP_FANS_PLATFORM,
  };

  const getConfig = () => {
    return {
      ...defaultConfig,
      ...envOverrides,
    };
  };

  const theme = defaultConfig.theme

  return {
    getConfig,
    theme
  };
}

module.exports = configFactory;
