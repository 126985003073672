<template>
  <svg class="animate-spin h-4 mr-2 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
      viewBox="0 0 24 24">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-90" fill="currentColor"
      d="M4 12a8 8 0 018-8v4l-3.5 3.5L4 12zm0 0a8 8 0 008 8v-4l-3.5-3.5L4 12z"></path>
  </svg>
</template>

<script>
export default {
  name: 'SpinnerLoading',
}
</script>
