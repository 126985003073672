<template>
  <div class="bg-secondary-100 p-[1px]  h-32 " :class="{'rounded-lg':!fullScreen}">
    <div class="h-20  relative bg-cover w-full" :class="{'rounded-t-lg':!fullScreen}"
         :style="{ backgroundImage: 'url(' + src + ')' }">
      <UserAvatar v-if="isUser" :user="user" size="lg" no-actions class="absolute top-6 left-2"/>
      <img v-if="isPost" :src="postSrc" alt=""
           class="absolute top-10 left-2 border-2 border-secondary-100 bg-cover rounded-xl w-24 h-24 object-cover">
      <div class="absolute flex items-center  gap-1 top-24 left-32  ">
        <p class="font-bold text-primary">{{ displayName }}</p>
        <Icon icon="trusted" size="3" v-if="user.trustedProfile"/>
      </div>
    </div>
  </div>

</template>

<script>
import {computed} from "vue";
import Icon from "@/design/Icon.vue";
import UserAvatar from "@/2_0/components/user/UserAvatar.vue";
import Utils from "@/utils";

export default {
  name: "BannerMini",
  components: {UserAvatar, Icon},
  props: {
    user: {},
    post: {},
    type: {type: 'post' | 'user', default: 'user'},
    fullScreen: {type: Boolean},
  },
  setup(props, {emit}) {
    const isPost = computed(() => props.type === 'post')
    const isUser = computed(() => props.type === 'user')

    const src = computed(() => {
      if (props.user && props.user.bannerS3Route) {
        return props.user.bannerS3Route
      }
      return '/assets/images/banner.svg'
    })
    const displayName = computed(() => {
      return Utils.truncateText(props.user.displayName)
    })

    const postSrc = computed(() => props.post.preview.cover)

    return {props, emit, isPost, isUser, src,displayName, postSrc}
  }
}

</script>
