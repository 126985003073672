<template>
  <v-alert v-if="showInfo" type="error" colored-border border="left"
           elevation="1">
    <p>{{  $t('withdraws.bank_transfer_info.text') }} </p>
    <p>
      <i18n path="withdraws.bank_transfer_info.link">
        <template #link>
          <a href="https://payplume.com" target="_blank">
            PayPlume
          </a>
        </template>
      </i18n>
    </p>
  </v-alert>
</template>


<script>
import { mapState } from 'vuex'

const USACountry = ['US', 'USA']

export default {
  name: 'PayplumeInfo',
  computed: {

  }
}
</script>
